import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
    Breadcrumb, Divider, Row, Col, Typography, Form, Select, Input, Spin, Button, notification, Modal,
    Upload, message
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
    cardLayout,
    inputPath,
    inputTitulo,
    inputMensagem,
    botaoSalvar,
    inputSelectUsuario,
    inputSelectCidades,
    inputSelectTipo,
    inputFuncao,
    inputAddImagem,
    inputNome,
    inputSelecioneUsuario
} from './GridStyle';
import Icon, { MessageOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FaUnlockAlt } from 'react-icons/fa';
import axios from 'axios';
import {
    urlUsersStatusAtivos, urlContratoAtivos, urlFiles, urlContratoUsuarios,
    urlProcessosJuridicos, urlMensagem, urlMensagemUpload, urlCargo, urlBuscaEstados,
    urlModulo
} from '../../../../services/request/urls';
import { getToken } from '../../../../services/token';
import { removeAcento } from '../../../../utils/RemoveAcentos';


//EDITOR DE HTML ESSES IMPORTS
// https://jpuri.github.io/react-draft-wysiwyg/#/docs
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateGlobal } from '../../../../interfaces/Global';
import { useSelector } from 'react-redux';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const { Title, Paragraph } = Typography;

// const UnlokProcessoJuridico = (props: any) => <Icon {...props} component={FaUnlockAlt} ></Icon>

interface interfaceUsuarios {
    id: number,
    nome: string,
    cargo: {
        id: number,
        nome: string
    }
};

interface interfaceDadosEstados {
    id: number,
    sigla: string,
    regiao: {
        id: number,
        sigla: string,
        nome: string
    },
    regiao_id: number,
    nome: string,
    populacao: number
};

interface interfaceDadosCidades {
    id: number,
    ibge_id_antigo: number,
    nome: string,
    microrregiao: {
        id: number,
        mesorregiao: {
            id: number,
            estado: {
                id: number,
                sigla: string,
                regiao_id: number,
                nome: string,
                populacao: number
            },
            estado_id: number,
            nome: string
        },
        mesorregiao_id: number,
        nome: string
    },
    microrregiao_id: number,
    latitude: string,
    longitude: string,
    populacao: number
};

const AdicionarMensagens: React.FC = () => {

    const refForm = useRef<any>(null);

    const propsState = useSelector((state: stateGlobal) => state.global);
    const refAllUsersAtivos = useRef<any>(null);

    const refBotaoSeleContrato = useRef<any>(null);
    const refBotaoSeleUsuario = useRef<any>(null);
    const refBotaoSeleCargos = useRef<any>(null);
    const refBotaoSeleEstados = useRef<any>(null);
    const refFormSelectUsuario = useRef<any>(null);
    const refFormSelectContrato = useRef<any>(null);
    const refFormSelectCargos = useRef<any>(null);
    const refFormSelectEstados = useRef<any>(null);

    const refidUsuarioLogado = useRef<any>(null);

    const refImagemCarregada = useRef<any>(null);

    const history = useHistory();

    const signal = axios.CancelToken.source();

    //Lista de estados antes da cidades
    const [dadosUsuariosList, setDadosUsuriosList] = useState<Array<interfaceUsuarios>>([]);
    const [dadosContratosList, setDadosContratosList] = useState<any>([]);
    const [loadingUsuariosSelected, setLoadingUsuariosSelected] = useState(false);

    const [dadosTelaRedirecionamentoList, setDadosTelaRedirecionamentoList] = useState<any>([]);

    const [dadosCargosList, setDadosCargosList] = useState<Array<any>>([]);
    const [dadosEstadosList, setDadosEstadosList] = useState<Array<any>>([]);

    const [loadingPage, setLoadingPage] = useState(false);
    const [isSalvarOrEditar, setIsSalvarOrEditar] = useState(false);
    const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());
    const [valuSelecionadoTipo, setValuSelecionadoTipo] = useState<any>(null);
    const [defaultEditorState, setdefaultEditorState] = useState<any>(undefined);

    const [loadingCampoUpload, setLoadingCampoUpload] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<any>(null);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
    }, []);

    //setando dados das cidades no selected
    const buscaUsuariosESetSelected = useCallback(() => {

        const buscaEstados = async () => {
            try {
                let result = await axios.get(urlUsersStatusAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                refAllUsersAtivos.current = result.data
                setDadosUsuriosList(result.data);
                // setLoadingUsuariosSelected(false);

                return true;
            } catch (error) {
                setLoadingUsuariosSelected(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar Estados!',
                });

                return false;
            }
        }

        return buscaEstados();

    }, []);

    //setando dados das cidades no selected
    const buscaUsuariosDoContrato = useCallback((id) => {

        const buscaEstados = async () => {
            try {
                let result = await axios.get(urlContratoUsuarios + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosUsuriosList(result.data);
                // setLoadingUsuariosSelected(false);

                return true;
            } catch (error) {
                setLoadingUsuariosSelected(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar Usuario do contrato!',
                });

                return false;
            }
        }

        return buscaEstados();

    }, []);

    //setando dados das cidades no selected
    const buscaContratosESetSelected = useCallback(() => {
        // setLoadingUsuariosSelected(true);
        const buscaEstados = async () => {
            try {
                let result = await axios.get(urlContratoAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosContratosList(result.data);

                return true;
                // setLoadingUsuariosSelected(false);
            } catch (error) {
                setLoadingUsuariosSelected(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar Estados!',
                });
                return false
            }
        }

        return buscaEstados();

    }, []);


    const buscaModulosByTelaRedirecionamentoESetSelected = useCallback(() => {

        const requestAsync = async () => {
            try {
                let result = await axios.get(urlModulo,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosTelaRedirecionamentoList(result.data);

                return true;

            } catch (error) {

                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar Estados!',
                });
                return false
            }
        }

        return requestAsync();

    }, []);

    //setando dados das cidades no selected
    const buscaCargosESetSelected = useCallback(() => {
        // setLoadingUsuariosSelected(true);
        const requisicao = async () => {
            try {
                let result = await axios.get(urlCargo,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosCargosList(result.data);

                return true;

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Cargos!',
                    });
                }
                return false
            }
        }

        return requisicao();

    }, []);

    //setando dados das cidades no selected
    const buscaEstadosESetSelected = useCallback(() => {
        // setLoadingFormAdd(true);
        const requisicao = async () => {
            try {
                let resultCidade = await axios.get(urlBuscaEstados, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });
                setDadosEstadosList(resultCidade.data);
                // setLoadingFormAdd(false);
            } catch (error) {
                // setLoadingFormAdd(false);

                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Estados!',
                    });
                }
            }
        }

        return requisicao();

    }, []);

    const comonentDidMount = useEffect((): void => {
        setIsSalvarOrEditar(true);

        const buscaDadosIniciais = async () => {
            try {
                setLoadingUsuariosSelected(true);

                let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:token');
                let localUserData: any = null;
                if (typeof dadosLocalStorage == 'string') {
                    localUserData = JSON.parse(dadosLocalStorage);
                    // return localUserData.user.nome
                }

                let dadosLocalStorageMensagem: string | null = localStorage.getItem('@GovFacil:Mensagem');
                let localUserDataMensagem: any = null;
                if (typeof dadosLocalStorageMensagem == 'string') {
                    localUserDataMensagem = JSON.parse(dadosLocalStorageMensagem);
                    // return localUserData.user.nome
                }

                if (localUserDataMensagem) {
                    //caso for editar ativar o loading
                    setLoadingPage(true);
                }

                buscaUsuariosESetSelected();
                buscaContratosESetSelected();
                buscaModulosByTelaRedirecionamentoESetSelected();
                buscaEstadosESetSelected();
                buscaCargosESetSelected();


                if (localUserDataMensagem) {

                    if (localUserData) {
                        refidUsuarioLogado.current = localUserData?.user?.id
                    }

                    setIsSalvarOrEditar(false);

                    refForm.current.setFieldsValue(
                        {
                            titulo: localUserDataMensagem?.titulo ? localUserDataMensagem.titulo : undefined,
                            // url: localUserDataMensagem?.url ? localUserDataMensagem.url : undefined,
                            nome: localUserDataMensagem?.nome ? localUserDataMensagem.nome : undefined,
                            funcao: localUserDataMensagem?.funcao ? localUserDataMensagem?.funcao : undefined,
                            conteudo_push: localUserDataMensagem?.conteudo_push ? localUserDataMensagem?.conteudo_push : undefined,
                            tela_redirecionamento: localUserDataMensagem?.tela_redirecionamento ? localUserDataMensagem?.tela_redirecionamento : undefined,
                            // mensagem: localUserDataMensagem?.mensagem ? localUserDataMensagem?.mensagem : undefined,
                        }
                    );

                    if (localUserDataMensagem?.imagem && localUserDataMensagem.imagem !== null && localUserDataMensagem.imagem !== '') {
                        setImageUrl(urlFiles + localUserDataMensagem.imagem)
                    }

                    if (localUserDataMensagem?.mensagem && localUserDataMensagem.mensagem !== null && localUserDataMensagem.mensagem !== '') {
                        // setEditorState(localUserDataMensagem.mensagem)

                        const contentBlocks = htmlToDraft(localUserDataMensagem.mensagem)

                        const sampleEditorContent = ContentState.createFromBlockArray(contentBlocks.contentBlocks, contentBlocks.entityMap);
                        const editorState = EditorState.createWithContent(sampleEditorContent);

                        setEditorState(editorState)
                        // setdefaultEditorState(sampleEditorContent)
                    }



                } else if (localUserData) {
                    refForm.current.setFieldsValue(
                        {
                            nome: localUserData?.user?.nome ? localUserData.user.nome : undefined,
                            funcao: localUserData?.user?.cargo?.nome ? localUserData?.user?.cargo?.nome : undefined,
                        }
                    );

                    refidUsuarioLogado.current = localUserData?.user?.id
                }

                setLoadingUsuariosSelected(false);
                setLoadingPage(false);

            } catch (error) {
                setLoadingUsuariosSelected(false);
                setLoadingPage(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar dados!',
                });
            }
        }

        buscaDadosIniciais();

    }, []);

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:Mensagem');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:Mensagem');
        }

    }, []);

    const onChangeUsuariosSelect = useCallback((e: number) => {
        refForm.current.setFieldsValue(
            {
                usuarios: e,
            })
    }, []);

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/mensagens");
    }, [history]);

    const onSelectTipo = useCallback((val): void => {

        setValuSelecionadoTipo(val)
        refForm.current.setFieldsValue(
            {
                usuarios: undefined,
                contratos: undefined,
                cargos: undefined,
                estados: undefined,
            })

    }, []);

    const onChangeContratoDoUsuario = useCallback((val: number | string): void => {

        refForm.current.setFieldsValue(
            {
                usuarios: undefined,
            })

        if (val === 'Todos') {
            setDadosUsuriosList(refAllUsersAtivos.current)
        } else {
            buscaUsuariosDoContrato(val)
        }

    }, []);

    const getBase64 = useCallback((img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }, []);

    const beforeUpload = useCallback((file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }, []);

    const onchangeEditor = useCallback((editorState): void => {

        setEditorState(editorState)

        // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }, []);

    const onPreview = useCallback((file) => {
        // console.log(file)

        const preview = async () => {
            let src = file.url;
            if (!src) {
                src = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                });
            }
            const image = new Image();
            image.src = src;
            const imgWindow: any = window.open(src);

            imgWindow.document.write(image.outerHTML);

        }

        preview()
    }, []);

    const handleChange = useCallback((info) => {

        refImagemCarregada.current = info

        getBase64(info.file.originFileObj, (imageUrl: any) => {
            setLoadingCampoUpload(false)
            setImageUrl(imageUrl)
        });
    }, []);

    const onClickBotaoEditar = useCallback((): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:Mensagem');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }
        const editarPJ = async (values: any) => {
            setLoadingPage(true)
            try {

                if (refImagemCarregada.current !== null) {

                    const formData = new FormData();

                    formData.append("imagem", refImagemCarregada.current.file.originFileObj);

                    let resultImagem = await axios.post(urlMensagemUpload, formData, {
                        headers: {
                            'Authorization': 'Bearer ' + getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    values.imagem = resultImagem.data.imagem
                }

                let resultMensagem = await axios.put(urlMensagem, values, {
                    headers: {
                        'Authorization': 'Bearer ' + getToken()
                    }
                });

                setLoadingPage(false);
                notification.success({
                    message: 'Sucesso',
                    description: "Mensagem atualizada com sucesso!",
                });
                history.push("/mensagens");
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });
            }
        }

        refForm.current.validateFields()
            .then((values: any) => {
                if (localUserData?.id) {
                    values.id = localUserData.id
                }

                values.usuarioId = refidUsuarioLogado.current;

                values.mensagem = draftToHtml(convertToRaw(editorState.getCurrentContent()));

                // editar
                editarPJ(values);
            })
            .catch((errorInfo: any) => {
                // console.log(errorInfo)
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [imageUrl, editorState]);

    const onClickBotaoSalvar = useCallback((): void => {
        const salvarPJ = async (values: any) => {

            setLoadingPage(true)
            try {

                if (refImagemCarregada.current !== null) {

                    const formData = new FormData();

                    formData.append("imagem", refImagemCarregada.current.file.originFileObj);

                    let resultImagem = await axios.post(urlMensagemUpload, formData, {
                        headers: {
                            'Authorization': 'Bearer ' + getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    values.imagem = resultImagem.data.imagem
                }

                let resultMensagem = await axios.post(urlMensagem, values, {
                    headers: {
                        'Authorization': 'Bearer ' + getToken()
                    }
                });

                setLoadingPage(false);
                notification.success({
                    message: 'Sucesso',
                    description: "Mensagem enviada com sucesso!",
                });
                history.push("/mensagens");
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });
            }
        }

        refForm.current.validateFields()
            .then((values: any) => {

                // if (!values.path) values.path = "";
                // console.log(values)

                values.usuarioId = refidUsuarioLogado.current;

                values.mensagem = draftToHtml(convertToRaw(editorState.getCurrentContent()));

                if (values.selecioneTipo === 'usuario') {

                    let obri: any = []

                    values.usuarios.forEach((element: number) => {
                        obri.push({ id: element })
                    });

                    values.usuarios = obri;

                } else if (values.selecioneTipo === 'contrato') {
                    let obri: any = []
                    values.contratos.forEach((element: number) => {
                        obri.push({ id: element })
                    });

                    values.contratos = obri;
                } else if (values.selecioneTipo === 'cargos') {
                    let obri: any = []
                    values.cargos.forEach((element: number) => {
                        obri.push({ id: element })
                    });

                    values.cargos = obri;
                } else if (values.selecioneTipo === 'estados') {
                    let obri: any = []
                    values.estados.forEach((element: number) => {
                        obri.push({ id: element })
                    });

                    values.estados = obri;
                }

                // salvar
                salvarPJ(values);
            })
            .catch((errorInfo: any) => {
                console.log(errorInfo)
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [imageUrl, editorState]);

    const uploadButton = useCallback(() => {
        return (
            <div>
                {loadingCampoUpload ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        )
    }, [loadingCampoUpload]);


    const onClickAddTodosSeleContrato = useCallback(() => {

        let arrayIds: any = [];
        dadosContratosList.forEach((val: any) => {
            arrayIds.push(val.id);
        });
        refForm.current.setFieldsValue(
            { contratos: arrayIds }
        );

        refBotaoSeleContrato.current?.blur();
        refFormSelectContrato.current?.blur();

    }, [dadosContratosList]);

    const onClickAddTodosSeleUsuario = useCallback(() => {

        let arrayIds: any = [];
        dadosUsuariosList.forEach((val: any) => {
            arrayIds.push(val.id);
        });
        refForm.current.setFieldsValue(
            { usuarios: arrayIds }
        );

        refBotaoSeleUsuario.current?.blur();
        refFormSelectUsuario.current?.blur();

    }, [dadosUsuariosList]);

    const onClickAddTodosSeleCargos = useCallback(() => {

        let arrayIds: any = [];
        dadosCargosList.forEach((val: any) => {
            arrayIds.push(val.id);
        });
        refForm.current.setFieldsValue(
            { cargos: arrayIds }
        );

        refBotaoSeleCargos.current?.blur();
        refFormSelectCargos.current?.blur();

    }, [dadosCargosList]);

    const onClickAddTodosSeleEstados = useCallback(() => {

        let arrayIds: any = [];
        dadosEstadosList.forEach((val: any) => {
            arrayIds.push(val.id);
        });
        refForm.current.setFieldsValue(
            { estados: arrayIds }
        );

        refBotaoSeleEstados.current?.blur();
        refFormSelectEstados.current?.blur();

    }, [dadosEstadosList]);

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><MessageOutlined style={{ fontSize: 35, marginRight: 10 }} />{isSalvarOrEditar ? "Adicionar" : "Editar"} Mensagem</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Mensagens</a></Breadcrumb.Item>
                        <Breadcrumb.Item>{isSalvarOrEditar ? "Adicionar" : "Editar"} Mensagem</Breadcrumb.Item>
                        {/* <Breadcrumb.Item>Adicionar Mensagem</Breadcrumb.Item> */}
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <Spin spinning={loadingPage}>
                <Form
                    ref={refForm}
                    name="formSendMensagens"
                    layout="vertical"
                    onFinishFailed={() => { }}
                >
                    {isSalvarOrEditar ?
                        <Row>
                            <Col {...inputSelectTipo}>
                                <Form.Item
                                    name="selecioneTipo"
                                    label="Selecione o tipo"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor selecione o tipo!",
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        loading={loadingUsuariosSelected}
                                        notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                        placeholder="Selecione..."
                                        onChange={onSelectTipo}
                                        // disabled={disabledSelectedEstado}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                    >
                                        <Select.Option
                                            value={'todos'}
                                            key={'Todos'}
                                        >
                                            Todos
                                        </Select.Option>
                                        <Select.Option
                                            value={'contrato'}
                                            key={'Contrato'}
                                        >
                                            Contrato
                                        </Select.Option>
                                        <Select.Option
                                            value={'usuario'}
                                            key={'Usuario'}
                                        >
                                            Usuário
                                        </Select.Option>
                                        <Select.Option
                                            value={'cargos'}
                                            key={'Cargos'}
                                        >
                                            Cargos
                                        </Select.Option>
                                        <Select.Option
                                            value={'estados'}
                                            key={'Estados'}
                                        >
                                            Estados
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {
                                valuSelecionadoTipo == null || valuSelecionadoTipo == 'todos' ? <></> :
                                    valuSelecionadoTipo == 'contrato' ?
                                        <>
                                            <Col {...inputSelectUsuario}>
                                                <Form.Item
                                                    name="contratos"
                                                    label="Selecione um contrato"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Por favor selecione um contrato!",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        ref={refFormSelectContrato}
                                                        loading={loadingUsuariosSelected}
                                                        notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                                        optionFilterProp="children"
                                                        placeholder="Selecione..."
                                                        mode="multiple"
                                                        filterOption={(input: any, option: any) => {
                                                            let textDigit = removeAcento(input)
                                                            let listCidade = removeAcento(option?.children);
                                                            return listCidade.indexOf(textDigit) >= 0
                                                        }}
                                                        dropdownRender={menu => (
                                                            <div>

                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Button
                                                                        ref={refBotaoSeleContrato}
                                                                        block
                                                                        onClick={onClickAddTodosSeleContrato}
                                                                        type="dashed"
                                                                    >Todos</Button>
                                                                </div>
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                {menu}
                                                            </div>
                                                        )}
                                                    >
                                                        {
                                                            dadosContratosList.map((item: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        value={item.id}
                                                                        key={item.id}
                                                                    >
                                                                        {
                                                                            item?.poder?.nome ?
                                                                                item.nome + ' - ' + item?.poder?.nome
                                                                                :
                                                                                item.nome
                                                                        }
                                                                    </Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </>
                                        :
                                        valuSelecionadoTipo == 'usuario' ?
                                            <>
                                                <Col {...inputSelectUsuario}>
                                                    <Form.Item
                                                        name="contratoDoUsuario"
                                                        label="Selecione um contrato"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: "Por favor selecione um contrato!",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            // ref={refFormSelectContrato}
                                                            loading={loadingUsuariosSelected}
                                                            notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                                            optionFilterProp="children"
                                                            placeholder="Selecione..."
                                                            // mode="multiple"
                                                            onChange={onChangeContratoDoUsuario}
                                                            filterOption={(input: any, option: any) => {
                                                                let textDigit = removeAcento(input)
                                                                let listCidade = removeAcento(option?.children);
                                                                return listCidade.indexOf(textDigit) >= 0
                                                            }}
                                                        >
                                                            <Select.Option
                                                                value={'Todos'}
                                                                key={'toodos'}
                                                            >
                                                                {'Todos'}
                                                            </Select.Option>
                                                            {
                                                                dadosContratosList.map((item: any) => {
                                                                    return (
                                                                        <Select.Option
                                                                            value={item.id}
                                                                            key={item.id}
                                                                        >
                                                                            {
                                                                                item?.poder?.nome ?
                                                                                    item.nome + ' - ' + item?.poder?.nome
                                                                                    :
                                                                                    item.nome
                                                                            }
                                                                        </Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col {...inputSelecioneUsuario}>
                                                    <Form.Item
                                                        name="usuarios"
                                                        label="Selecione um usuário"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Por favor selecione um usuário!",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            ref={refFormSelectUsuario}
                                                            loading={loadingUsuariosSelected}
                                                            notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                                            optionFilterProp="children"
                                                            placeholder="Selecione..."
                                                            mode="multiple"
                                                            filterOption={(input: any, option: any) => {
                                                                let textDigit = removeAcento(input)
                                                                let listCidade = removeAcento(option?.children);
                                                                return listCidade.indexOf(textDigit) >= 0
                                                            }}
                                                            dropdownRender={menu => (
                                                                <div>

                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Button
                                                                            ref={refBotaoSeleUsuario}
                                                                            block
                                                                            onClick={onClickAddTodosSeleUsuario}
                                                                            type="dashed"
                                                                        >Todos</Button>
                                                                    </div>
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    {menu}
                                                                </div>
                                                            )}
                                                        >
                                                            {
                                                                dadosUsuariosList.map((item) => {
                                                                    return (
                                                                        <Select.Option
                                                                            value={item.id}
                                                                            key={item.id}
                                                                        >
                                                                            {item.nome + ' - ' + item?.cargo?.nome}
                                                                        </Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </>
                                            : valuSelecionadoTipo == 'cargos' ?
                                                <>
                                                    <Col {...inputSelectUsuario}>
                                                        <Form.Item
                                                            name="cargos"
                                                            label="Selecione um cargo"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Por favor selecione um cargo!",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                ref={refFormSelectCargos}
                                                                // loading={loadingUsuariosSelected}
                                                                // notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                                                optionFilterProp="children"
                                                                placeholder="Selecione..."
                                                                mode="multiple"
                                                                filterOption={(input: any, option: any) => {
                                                                    let textDigit = removeAcento(input)
                                                                    let listCidade = removeAcento(option?.children);
                                                                    return listCidade.indexOf(textDigit) >= 0
                                                                }}
                                                                dropdownRender={menu => (
                                                                    <div>

                                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                            <Button
                                                                                ref={refBotaoSeleCargos}
                                                                                block
                                                                                onClick={onClickAddTodosSeleCargos}
                                                                                type="dashed"
                                                                            >Todos</Button>
                                                                        </div>
                                                                        <Divider style={{ margin: '4px 0' }} />
                                                                        {menu}
                                                                    </div>
                                                                )}
                                                            >
                                                                {
                                                                    dadosCargosList.map((item: any) => {
                                                                        return (
                                                                            <Select.Option
                                                                                value={item.id}
                                                                                key={item.id}
                                                                            >
                                                                                {item.nome}
                                                                            </Select.Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                                : valuSelecionadoTipo == 'estados' ?
                                                    <>
                                                        <Col {...inputSelectUsuario}>
                                                            <Form.Item
                                                                name="estados"
                                                                label="Selecione um estado"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Por favor selecione um estado!",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    ref={refFormSelectEstados}
                                                                    // loading={loadingUsuariosSelected}
                                                                    // notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                                                    optionFilterProp="children"
                                                                    placeholder="Selecione..."
                                                                    mode="multiple"
                                                                    filterOption={(input: any, option: any) => {
                                                                        let textDigit = removeAcento(input)
                                                                        let listCidade = removeAcento(option?.children);
                                                                        return listCidade.indexOf(textDigit) >= 0
                                                                    }}
                                                                    dropdownRender={menu => (
                                                                        <div>

                                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                                <Button
                                                                                    ref={refBotaoSeleEstados}
                                                                                    block
                                                                                    onClick={onClickAddTodosSeleEstados}
                                                                                    type="dashed"
                                                                                >Todos</Button>
                                                                            </div>
                                                                            <Divider style={{ margin: '4px 0' }} />
                                                                            {menu}
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {
                                                                        dadosEstadosList.map((item: any) => {
                                                                            return (
                                                                                <Select.Option
                                                                                    value={item.id}
                                                                                    key={item.id}
                                                                                >
                                                                                    {item.nome}
                                                                                </Select.Option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                    : <></>
                            }
                        </Row>
                        :
                        <></>
                    }
                    <Row>
                        <Col {...inputTitulo}>
                            <Form.Item
                                name="titulo"
                                label="Titulo da mensagem"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor preencha o titulo!',
                                    },
                                ]}
                            >
                                <Input placeholder="Titulo da mensagem" />
                            </Form.Item>
                        </Col>
                        {/* <Col {...inputSelectUsuario}>
                            <Form.Item
                                name="url"
                                label="Url"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Por favor preencha a url!",
                                    },
                                    {
                                        type: "url",
                                        message:
                                            "Por favor preencha uma url válida!",
                                    },
                                ]}
                            >
                                <Input
                                    // addonBefore="https://"
                                    placeholder="ex: https://govfacil.com.br"
                                />
                            </Form.Item>
                        </Col> */}
                        <Col {...inputSelectUsuario}>
                            <Form.Item
                                name="conteudo_push"
                                label="Conteúdo PUSH"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Este campo é obrigatório!",
                                    }
                                ]}
                            >
                                <Input
                                    // addonBefore="https://"
                                    placeholder="Conteúdo"
                                />
                            </Form.Item>
                        </Col>
                        <Col {...inputSelectUsuario}>
                            <Form.Item
                                name="tela_redirecionamento"
                                label="Tela Redirecionamento"
                                rules={[
                                    {
                                        required: false,
                                        message: "Por favor selecione!",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    loading={loadingUsuariosSelected}
                                    notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                    optionFilterProp="children"
                                    placeholder="Selecione..."
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dadosTelaRedirecionamentoList.map((item: any) => {
                                            return (
                                                <Select.Option
                                                    value={item.path}
                                                    key={item.path}
                                                >
                                                    {
                                                        item.nome
                                                    }
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...inputMensagem}>
                            <Form.Item
                                name="mensagem"
                                label="Mensagem"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor digite a senha!',
                                    },
                                ]}
                            >
                                {/* <Input.TextArea style={{ maxHeight: 250, height: 150 }} /> */}
                                {/* <Editor
                                    editorState={editorState}
                                    defaultEditorState={defaultEditorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    editorStyle={{
                                        maxHeight: 250,
                                        border: '1px solid #e4e4e4',
                                        padding: 5,
                                        paddingBottom: 10,
                                        borderRadius: 2
                                    }}
                                    onEditorStateChange={onchangeEditor}
                                    toolbar={{
                                        options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'history', 'emoji'],
                                        inline: {
                                            options: ['bold', 'italic'],
                                        },
                                        blockType: {
                                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                        },
                                        colorPicker: {
                                            colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                                'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                                'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                                'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                                'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                                'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                        },
                                        list: {
                                            options: ['unordered', 'ordered'],
                                        },
                                        textAlign: {
                                            options: ['justify'],
                                            link: 'justify'
                                        },
                                    }}
                                // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']
                                /> */}
                                <Editor
                                    localization={{
                                        translations: {
                                            // Generic
                                            'generic.add': 'Adicionar',
                                            'generic.cancel': 'Cancelar',

                                            // Color Picker
                                            'components.controls.colorpicker.colorpicker': 'Color Picker',
                                            'components.controls.colorpicker.text': 'Texto',
                                            'components.controls.colorpicker.background': 'Destacar',

                                            // Emoji
                                            'components.controls.emoji.emoji': 'Emoji',

                                            // FontFamily
                                            'components.controls.fontfamily.fontfamily': 'Fonte',

                                            // FontSize
                                            'components.controls.fontsize.fontsize': 'Tamanho da fonte',

                                            // History
                                            'components.controls.history.history': 'Histórico',
                                            'components.controls.history.undo': 'Desfazer',
                                            'components.controls.history.redo': 'Refazer',

                                            // Inline
                                            'components.controls.inline.bold': 'Negrito',
                                            'components.controls.inline.italic': 'Italico',

                                            // Link
                                            'components.controls.link.linkTitle': 'Título',
                                            'components.controls.link.linkTarget': 'Link',
                                            'components.controls.link.linkTargetOption': 'Abrir link em nova janela',
                                            'components.controls.link.link': 'Link',
                                            'components.controls.link.unlink': 'Remover Link',

                                            // List
                                            'components.controls.list.list': 'Lista',
                                            'components.controls.list.unordered': 'Não Ordenada',
                                            'components.controls.list.ordered': 'Ordenada',

                                            // TextAlign
                                            'components.controls.textalign.textalign': 'Alinhar Texto',
                                            'components.controls.textalign.left': 'Esquerda',
                                            'components.controls.textalign.center': 'Centro',
                                            'components.controls.textalign.right': 'Direita',
                                            'components.controls.textalign.justify': 'Justificar',
                                        }
                                    }}
                                    editorState={editorState}
                                    defaultEditorState={
                                        defaultEditorState
                                    }
                                    toolbarStyle={{
                                        backgroundColor: propsState.theme === "dark" ? "#212121" : "#FFFFFF",
                                        borderColor: propsState.theme === "dark" ? "#333333" : "#dddddd",
                                        color: '#254da8'
                                    }}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    editorStyle={{
                                        maxHeight: 250,
                                        border: `1px solid ${propsState.theme === "dark" ? "#333333" : "#dddddd"}`,
                                        padding: 5,
                                        paddingBottom: 10,
                                        borderRadius: 2,
                                    }}
                                    onEditorStateChange={onchangeEditor}
                                    toolbar={{
                                        options: [
                                            "inline",
                                            "fontSize",
                                            "fontFamily",
                                            "list",
                                            "textAlign",
                                            "colorPicker",
                                            "history",
                                            "emoji",
                                            "link"
                                        ],
                                        inline: {
                                            options: ["bold", "italic"],
                                        },
                                        blockType: {
                                            options: [
                                                "Normal",
                                                "H1",
                                                "H2",
                                                "H3",
                                                "H4",
                                                "H5",
                                                "H6",
                                                "Blockquote",
                                                "Code",
                                            ],
                                        },
                                        fontFamily: {
                                            options: [
                                                "Montserrat-Bold",
                                                "Montserrat-SemiBold",
                                                "Montserrat-Medium",
                                                "Montserrat-Regular",
                                            ],
                                        },
                                        colorPicker: {
                                            colors: [
                                                "rgb(97,189,109)",
                                                "rgb(26,188,156)",
                                                "rgb(84,172,210)",
                                                "rgb(44,130,201)",
                                                "rgb(147,101,184)",
                                                "rgb(71,85,119)",
                                                "rgb(204,204,204)",
                                                "rgb(65,168,95)",
                                                "rgb(0,168,133)",
                                                "rgb(61,142,185)",
                                                "rgb(41,105,176)",
                                                "rgb(85,57,130)",
                                                "rgb(40,50,78)",
                                                "rgb(0,0,0)",
                                                "rgb(247,218,100)",
                                                "rgb(251,160,38)",
                                                "rgb(235,107,86)",
                                                "rgb(226,80,65)",
                                                "rgb(163,143,132)",
                                                "rgb(239,239,239)",
                                                "rgb(255,255,255)",
                                                "rgb(250,197,28)",
                                                "rgb(243,121,52)",
                                                "rgb(209,72,65)",
                                                "rgb(184,49,47)",
                                                "rgb(124,112,107)",
                                                "rgb(209,213,216)",
                                            ],
                                        },
                                        list: {
                                            options: [
                                                "unordered",
                                                "ordered",
                                            ],
                                        },
                                        textAlign: {
                                            options: [
                                                "left",
                                                "center",
                                                "right",
                                                "justify",
                                            ],
                                            left: "left",
                                            center: "center",
                                            right: "right",
                                            link: "justify",
                                        },
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...inputAddImagem}>
                            <Paragraph>Adicionar imagem</Paragraph>
                            <Upload
                                // name="file"
                                listType="picture-card"
                                // className="avatar-uploader"
                                style={{
                                    width: 128,
                                    height: 128
                                }}
                                showUploadList={false}
                                // customRequest={()}
                                action="/upload.do"
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            // onPreview={() => {}}
                            // onRemove={(file) => {console.log(file); return (true)}}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton()}
                            </Upload>
                        </Col>
                        <Col {...inputNome}>
                            <Form.Item
                                name="nome"
                                label="Nome"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite o nome!',
                                    },
                                ]}
                            >
                                <Input placeholder="Nome" />
                            </Form.Item>
                        </Col>
                        <Col {...inputFuncao}>
                            <Form.Item
                                name="funcao"
                                label="Função"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite a função!',
                                    },
                                ]}
                            >
                                <Input placeholder="Funcao" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Spin>
            <Row>
                <Col {...botaoSalvar}>
                    <Button style={{ marginRight: 10 }} loading={loadingPage} onClick={onClickVoltarConfigOfSystem} >
                        Cancelar
                    </Button>
                    {isSalvarOrEditar ?
                        <Button type="primary" loading={loadingPage} onClick={onClickBotaoSalvar} >
                            Salvar
                        </Button>
                        :
                        <Button type="primary" loading={loadingPage} onClick={onClickBotaoEditar} >
                            Atualizar
                        </Button>
                    }
                </Col>
            </Row>
        </>
    );
}

export default AdicionarMensagens;
