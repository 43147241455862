import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Table, Input, Button, Cascader, Select, Spin, Space, Row, Col, notification, Typography, Tag, Empty } from 'antd';
import Highlighter from 'react-highlight-words';
import {
    selectedNomeOrgaoAdd,
    selectedEstadosInAddAssociacao,
    cardLayout
} from './GridStyle';
import { SearchOutlined, MobileOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
// import { useForm } from 'antd/lib/form/Form';
import { getToken } from './../../services/token';
import {
    urlAssocRegional, urlAssocRegionalCidades, urlAssocEstadualCidades, urlsessionCodesSms,
    urlAssocFederalCidades
} from '../../services/request/urls';
import axios from 'axios';
import { FaSearch, FaSitemap } from 'react-icons/fa';
import { mask, unMask } from '../../utils/MascaraDeCampos';
import { convertDataUSParaBR, convertHora } from './../../services/Conversores';

const { Title, Text } = Typography;

interface interfaceCidadeEstados {
    id: number,
    uuid: string,
    ibge_id_antigo: number,
    nome: string,
    latitude: string,
    longitude: string,
    populacao: number,
    microrregiaoId: number,
    estadoId: number,
    estadoNome: string
};

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

const ListagemCodigosSms: React.FC = () => {

    const formRef = useRef<any>(null);

    const refVarsetInterval = useRef<any>(null);
    // formRef = React.createRef();

    const history = useHistory();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<any>([]);
    // const [varsetInterval, setVarsetInterval] = useState<any>(null);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedView, setHasSelectedView] = useState(false);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();

    const selectVendedor = useCallback((e): void => {
        console.log(e);
    }, []);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!");

        clearInterval(refVarsetInterval.current);

    }, []);

    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                        text
                    ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsModulo = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                return (
                    record[dataIndex]?.nome ? record[dataIndex]?.nome.toString().toLowerCase().includes(value.toLowerCase()) : ''
                );
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                        text
                    ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    const getColumnSearchPropsCelular = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                return (
                    record[dataIndex]?.celular ? record[dataIndex]?.celular.toString().toLowerCase().includes(value.toLowerCase()) : ''
                );
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                        text
                    ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    const getColumnSearchPropsData = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let texto: any = ''
                if (record[dataIndex]) { texto = convertDataUSParaBR(record[dataIndex]) }

                return (
                    texto ? texto.toString().toLowerCase().includes(value.toLowerCase()) : ''
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                        text
                    ),
        })
    }, [searchText, searchedColumn]);

    // fim do search da tabela de listagem

    //formulario receber dados
    const onFinish = useCallback(({ Nome, Senha }): void => {

    }, []);

    //caso der aviso de erro no formulario
    const onFinishFailed = useCallback((errorInfo): void => {
        // console.log('Failed:', errorInfo);
    }, []);

    const start = useCallback((): void => {

    }, []);
    //abrir navegar em add contrato
    const clickBotaoEditarAssoc = useCallback((): void => {

        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {

                let objSelecionado: any = {}
                dadosTabelaState.forEach((dados: any): void => {
                    if (dados.uuid == selectedRowKeys[0]) {
                        objSelecionado = dados
                    }
                })

                try {

                    switch (objSelecionado.tipo) {
                        case "Regional":
                            let resultRegional = await axios.get(urlAssocRegionalCidades + "/" + objSelecionado.id,
                                {
                                    headers: { 'Authorization': 'Bearer ' + getToken() }
                                });

                            let estadosIDRegional: any = []
                            resultRegional.data.forEach((vende: interfaceCidadeEstados) => {
                                if (estadosIDRegional.length === 0) {
                                    estadosIDRegional.push(vende.estadoId)
                                } else {
                                    let indice = estadosIDRegional.indexOf(vende.estadoId);
                                    if (indice === -1) {
                                        estadosIDRegional.push(vende.estadoId)
                                    }
                                }
                            });

                            objSelecionado.cidades = resultRegional.data;
                            objSelecionado.estados = estadosIDRegional;

                            break;
                        case "Estadual":

                            let resultEstadual = await axios.get(urlAssocEstadualCidades + "/" + objSelecionado.id,
                                {
                                    headers: { 'Authorization': 'Bearer ' + getToken() }
                                });

                            let estadosIDEstadual: any = []
                            resultEstadual.data.forEach((vende: interfaceCidadeEstados) => {
                                if (estadosIDEstadual.length === 0) {
                                    estadosIDEstadual.push(vende.estadoId)
                                } else {
                                    let indice = estadosIDEstadual.indexOf(vende.estadoId);
                                    if (indice === -1) {
                                        estadosIDEstadual.push(vende.estadoId)
                                    }
                                }
                            });

                            objSelecionado.cidades = resultEstadual.data;
                            objSelecionado.estados = estadosIDEstadual;

                            break;
                        case "Federal":

                            let resultFederal = await axios.get(urlAssocFederalCidades + "/" + objSelecionado.id,
                                {
                                    headers: { 'Authorization': 'Bearer ' + getToken() }
                                });

                            let estadosIDFederal: any = []
                            resultFederal.data.forEach((vende: interfaceCidadeEstados) => {
                                if (estadosIDFederal.length === 0) {
                                    estadosIDFederal.push(vende.estadoId)
                                } else {
                                    let indice = estadosIDFederal.indexOf(vende.estadoId);
                                    if (indice === -1) {
                                        estadosIDFederal.push(vende.estadoId)
                                    }
                                }
                            });

                            objSelecionado.cidades = resultFederal.data;
                            objSelecionado.estados = estadosIDFederal;

                            break;

                        default:
                            break;
                    }

                    localStorage.setItem('@GovFacil:associacao', JSON.stringify(objSelecionado));

                    console.log(objSelecionado)

                    history.push('/associacao/adicionar');
                } catch (error) {
                    let msgErro: any = (error as Error);
                    notification.error({
                        message: 'Erro',
                        description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    const clickSubmitModalAddAssociacao = useCallback((): void => {
        // formRef.current.submit((err: any, values: any) => {
        //     console.log(values);
        // })
    }, []);

    //abrir modal
    const clickBotaoAddAssociacao = useCallback((): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:associacao');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:associacao');
        }

        history.push("/associacao/adicionar");
    }, []);

    // validatorConfmSenha = (rule, value, callback) => {
    //     try {
    //         let password = this.formRef.current.getFieldValue("senha");
    //         if (password != undefined && value === password) {
    //         } else {
    //             throw new Error('Something wrong!');
    //         }

    //     } catch (error) {
    //         callback(error)
    //     }

    // }

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.uuid]);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(true);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(true);
            setHasSelectedEdit(true);
        }
    }, [selectedRowKeys]);

    const filterCascade = useCallback((inputValue: any, path: any): any => {
        return path.some((option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }, []);

    const buscaCodigosSmsSetInterval = useCallback((): void => {

        const buscaDeDadosContratos = async () => {
            try {
                setLoadingTable(true);
                let result = await axios.get(urlsessionCodesSms,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                // let criarObjVazio = [
                //     {
                //         uuid: "c2010478-6dfc-4191-afd3-f494chinese71",
                //         codigo: "--------------",
                //         dataehora: "--------------",
                //         usuario: {
                //             nome: "--------------",
                //             celular: "--------------"
                //         }
                //     }
                // ]

                // if (result.data.length == 0) {
                //     setDadosTabelaState(criarObjVazio);
                // } else {
                setDadosTabelaState(result.data);
                // }

                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        refVarsetInterval.current = setInterval(() => {
            buscaDeDadosContratos();
        }, 3000);

    }, []);

    const buscaCodigosSms = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDadosContratos = async () => {
            try {
                let result = await axios.get(urlsessionCodesSms,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                // let criarObjVazio = [
                //     {
                //         uuid: "c2010478-6dfc-4191-afd3-f494chinese71",
                //         codigo: "--------------",
                //         dataehora: "--------------",
                //         usuario: {
                //             nome: "--------------",
                //             celular: "--------------"
                //         }
                //     }
                // ]

                // if (result.data.length == 0) {
                //     setDadosTabelaState(criarObjVazio);
                // } else {
                setDadosTabelaState(result.data);
                // }

                setLoadingTable(false);

                buscaCodigosSmsSetInterval();

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, [buscaCodigosSmsSetInterval]);

    const onBlur = useCallback((): void => {
        console.log('blur');
    }, []);

    const onFocus = useCallback((): void => {
        console.log('focus');
    }, []);

    const onSearch = useCallback((val): void => {
        console.log('search:', val);
    }, []);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'usuario',
            ...getColumnSearchPropsModulo('usuario'),
            width: '12%',
            render: (val: any) => {
                return (
                    <>
                        {val?.nome ? val?.nome : ""}
                    </>
                )
            },
        },
        {
            title: 'Celular',
            dataIndex: 'usuario',
            ...getColumnSearchPropsCelular('usuario'),
            width: '10%',
            render: (val: any) => {
                const originalValue = unMask(val?.celular);
                const maskedValue = mask(originalValue, [
                    "(99) 9999-9999",
                    "(99) 9 9999-9999"
                ]);
                return maskedValue;
            }
            // sorter: (a, b) => a.age - b.age //para quando é numero
        },
        {
            title: 'Codigo',
            dataIndex: 'codigo',
            ...getColumnSearchProps('codigo'),
            width: '8%',
            render: (val: any) => {
                return (
                    <span>
                        <Tag style={{ fontSize: 14 }} color='blue' key={val}>
                            {val}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            ...getColumnSearchProps('tipo'),
            width: '8%',
            render: (val: any) => {
                let cor = '';
                switch (val) {
                    case 'whatsapp':
                        cor = 'green';
                        break;
                    case "sms":
                        cor = 'blue';
                        break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag style={{ fontSize: 14 }} color={cor} key={val}>
                            {val.toUpperCase()}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: 'Hora',
            dataIndex: 'dataehora',
            width: '8%',
            render: (dataehora: any) => {
                let data: any = "";
                if (dataehora) {
                    data = convertHora(dataehora);
                }
                return (
                    data
                )
            },
        }
    ];

    const comonentDidMount = useEffect((): void => {
        //buscando e setando na tabela de listagem o parana
        buscaCodigosSms();
    }, [buscaCodigosSms]);

    return (
        <>
            {/* <Row style={{ marginBottom: 16 }}>
                <Col>
                    <Button type="primary" className="botaoAdicionarAssociacao" disabled={!hasSelectedAdd} onClick={clickBotaoAddAssociacao} >
                        Adicionar
                    </Button>
                    <Button className="botaoViewAssociacao" type="primary" onClick={start} disabled={!hasSelectedView} >
                        Visualizar
                    </Button>
                </Col>
                <Col>
                    <Button type="primary" className="botaoEditarAssociacao" onClick={clickBotaoEditarAssoc} disabled={!hasSelectedEdit} >
                        Editar
                    </Button>
                </Col>
            </Row> */}
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><MobileOutlined style={{ fontSize: 35, marginRight: 10 }} />Listagem de Códigos Autenticação</Title>
                    {/* <Breadcrumb>
                        <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Associações</a></Breadcrumb.Item>
                        <Breadcrumb.Item> {isSalvarOrEditar ? "Adicionar" : "Editar"} Associação</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider /> */}
                </Col>
            </Row>
            <Row>
                <Col {...selectedEstadosInAddAssociacao}>
                    <Table
                        loading={loadingTable}
                        rowKey={(record) => record.uuid}
                        // onRow={(record, rowIndex) => {
                        //     return {
                        //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                        //     };
                        // }}
                        // rowSelection={rowSelection}
                        columns={columns} dataSource={dadosTabelaState}
                        scroll={{ x: 620 }}
                        size="small"
                        pagination={{ locale: { items_per_page: '' } }}
                        locale={{
                            emptyText: () => {
                                return <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        <span>
                                            Sem códigos
                                        </span>
                                    }
                                ></Empty>
                            }
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

export default ListagemCodigosSms;
