import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Breadcrumb, Divider, Row, Col, Typography, Form, Radio, Input, Spin, Button, notification } from 'antd';
import {
    UsergroupAddOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
    cardLayout,
    inputCaptacaoNome,
    inputCaptacaoCelular,
    inputCaptacaoCidade,
    inputCaptacaoMarca,
    inputCaptacaoModelo,
    inputCaptacaoData,
    inputCaptacaoContatado,
    inputCaptacaoDataContatado,
    inputCaptacaoObservacao,
    botaoSalvar,
} from './GridStyle';
import axios from 'axios';
import {
    urlsessionUsuarioCapitado
} from '../../../../services/request/urls';
import { getToken } from '../../../../services/token';
import { mask, unMask } from './../../../../utils/MascaraDeCampos';
import { convertDataUSParaBR, convertDataBrParaUS } from './../../../../services/Conversores';
import moment from 'moment';

const { Title } = Typography;

const AddObrigacaoViewDados: React.FC = () => {

    const refForm = useRef<any>(null);
    const refIdUsuario = useRef<any>(null);

    const history = useHistory();

    //Lista de estados antes da cidades

    const [loadingPage, setLoadingPage] = useState(false);
    const [isSalvarOrEditar, setIsSalvarOrEditar] = useState(false);

    // nome: "Shhehrjfjcn"
    // celular: "44333333333"
    // cidade: {
    //     nome: NOMEM,
    //     sigla: "sigla"
    // sigla: PR,
    // cidadeId: 4209102,
    // estadoId: 42
    // }
    // marca: "xiaomi"
    // modelo: "Redmi Note 7"
    // contatado: false
    // data_registro: "2021-01-26T16:12:51.407Z"
    // observacao: null
    // device_id: "e565e24614118dcc"
    // id: 23
    // uuid: "f35ea57c-d070-44cb-88d4-a7c697c0a7ac"

    const comonentDidMount = useEffect((): void => {
        setIsSalvarOrEditar(true);

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:captacaousuario');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {

            let celularNumber: any = null;
            if (localUserData?.celular) {
                const originalValue = localUserData?.celular ? unMask(localUserData?.celular) : null;
                celularNumber = originalValue ? mask(originalValue, [
                    "(99) 9999-9999",
                    "(99) 9 9999-9999"
                ]) : null
            }

            let data: any = "";
            if (localUserData?.data_registro) {
                data = convertDataUSParaBR(localUserData?.data_registro);
            }

            let dataContatado: any = "";
            if (localUserData.data_contato) {
                dataContatado = convertDataUSParaBR(localUserData.data_contato);
            }

            refForm.current.setFieldsValue(
                {
                    nome: localUserData?.nome ? localUserData.nome : undefined,
                    celular: celularNumber,
                    marca: localUserData?.marca ? localUserData.marca : undefined,
                    modelo: localUserData?.modelo ? localUserData.modelo : undefined,
                    contatado: localUserData.contatado == false ? localUserData.contatado : true,
                    data_registro: data,
                    data_contato: dataContatado,
                    observacao: localUserData?.observacao ? localUserData.observacao : undefined,
                    cidade: localUserData?.cidade ? localUserData.cidade.nome + ' - ' + localUserData.cidade.microrregiao.mesorregiao.estado.sigla : undefined,
                }
            );
            setIsSalvarOrEditar(false);

            refIdUsuario.current = localUserData.id

        }

    }, []);

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:captacaousuario');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:captacaousuario');
        }

    }, []);

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/listcaptacaousuario");
    }, [history]);

    const onClickBotaoEditar = useCallback((): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:captacaousuario');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }
        const editarPJ = async (values: any) => {
            setLoadingPage(true);
            try {
                let resultData = await axios.put(urlsessionUsuarioCapitado, values, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });

                setLoadingPage(false);
                notification.success({
                    message: 'Sucesso',
                    description: "Dados atualizados com sucesso!",
                });
                history.push("/listcaptacaousuario");
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!',
                });
            }
        }

        refForm.current.validateFields()
            .then((values: any) => {

                let objSalvar: any = {
                    id: localUserData?.id ? localUserData.id : null,
                    contatado: values.contatado,
                    observacao: values.observacao,
                    data_contato: convertDataBrParaUS(values.data_contato)
                }

                // editar
                editarPJ(objSalvar);
            })
            .catch((errorInfo: any) => {
                // console.log(errorInfo)
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, []);

    const onChangeDataContatado = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "99/99/9999"
        ]);

        refForm.current.setFieldsValue(
            { data_contato: maskedValue }
        );

    }, []);

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><UsergroupAddOutlined style={{ fontSize: 35, marginRight: 10 }} /> {isSalvarOrEditar ? "Adicionar" : "Editar"} Captação de Usuário</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Captação de Usuário</a></Breadcrumb.Item>
                        <Breadcrumb.Item>{isSalvarOrEditar ? "Adicionar" : "Editar"} Captação de Usuário</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <Spin spinning={loadingPage}>
                <Form
                    ref={refForm}
                    name="formCaptacaoUsuario"
                    layout="vertical"
                    onFinishFailed={() => { }}
                >
                    <Row>
                        <Col {...inputCaptacaoNome}>
                            <Form.Item
                                name="nome"
                                label="Nome"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor digite o nome do usuário!',
                                    },
                                ]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col {...inputCaptacaoCelular}>
                            <Form.Item
                                name="celular"
                                label="Celular"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor digite o nome do usuário!',
                                    },
                                ]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col {...inputCaptacaoCidade}>
                            <Form.Item
                                name="cidade"
                                label="Cidade"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor digite o nome do usuário!',
                                    },
                                ]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col {...inputCaptacaoMarca}>
                            <Form.Item
                                name="marca"
                                label="Marca"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor digite o nome do usuário!',
                                    },
                                ]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col {...inputCaptacaoModelo}>
                            <Form.Item
                                name="modelo"
                                label="Modelo"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor digite o nome do usuário!',
                                    },
                                ]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col {...inputCaptacaoData}>
                            <Form.Item
                                name="data_registro"
                                label="Data"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor digite o nome do usuário!',
                                    },
                                ]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col {...inputCaptacaoContatado}>
                            <Form.Item
                                name="contatado"
                                label="Contatado"
                                initialValue={true}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor selecione!',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio.Button value={true}>Contactado</Radio.Button>
                                    <Radio.Button value={false}>Não Contactado</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col {...inputCaptacaoDataContatado}>
                            <Form.Item
                                name="data_contato"
                                label="Data Contato"
                                rules={[
                                    {
                                        required: false,
                                        message: "Por favor digite a data",
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            if (value != '' && value != null) {
                                                if (value.length < 10) {
                                                    return Promise.reject('Data Inválida');
                                                } else {
                                                    if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                                        return Promise.resolve();
                                                    } else {
                                                        return Promise.reject('Data Inválida');
                                                    }
                                                }

                                            } else {
                                                return Promise.resolve();
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    onChange={(e) => onChangeDataContatado(e)}
                                    placeholder="31/12/2000"
                                />
                            </Form.Item>
                        </Col>
                        <Col {...inputCaptacaoObservacao}>
                            <Form.Item
                                name="observacao"
                                label="Observações"
                                rules={[
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
            <Row>
                <Col {...botaoSalvar}>
                    <Button style={{ marginRight: 10 }} onClick={onClickVoltarConfigOfSystem} >
                        Cancelar
                    </Button>

                    <Button type="primary" onClick={onClickBotaoEditar} >
                        Atualizar
                        </Button>
                </Col>
            </Row>

        </>
    );
}


export default AddObrigacaoViewDados;
