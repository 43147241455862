import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Breadcrumb, Divider, Row, Col, Typography, Form, Select, Input, Spin, Button, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import {
    cardLayout,
    inputPassword,
    inputTipo,
    inputUsuario,
    botaoSalvar,
    inputSelectEstadosCity,
    inputSelectCidades
} from './GridStyle';
import Icon from '@ant-design/icons';
import { FaUnlockAlt } from 'react-icons/fa';
import axios from 'axios';
import {
    urlBuscaCidadesPorIdEstado, urlBuscaEstados, urlTipoProcessoJuridico,
    urlProcessosJuridicos
} from '../../../../services/request/urls';
import { getToken } from '../../../../services/token';
import { removeAcento } from '../../../../utils/RemoveAcentos';

const { Title } = Typography;

const UnlokProcessoJuridico = (props: any) => <Icon {...props} component={FaUnlockAlt} ></Icon>

interface interfaceListTipoProcessosJ {
    id: number,
    nome: string,
};
interface interfaceDadosEstados {
    id: number,
    sigla: string,
    regiao: {
        id: number,
        sigla: string,
        nome: string
    },
    regiao_id: number,
    nome: string,
    populacao: number
};

interface interfaceDadosCidades {
    id: number,
    uuid: string
    uf: string,
    estadoId: number,
    nome: string,
};


const AdicionarAssociacao: React.FC = () => {

    const refForm = useRef<any>(null);

    const history = useHistory();

    //Lista de estados antes da cidades
    const [dadosEstadosAndCityList, setDadosEstadosAndCityList] = useState<Array<interfaceDadosEstados>>([]);
    const [loadingEstadosAndCitySelected, setLoadingEstadosAndCitySelected] = useState(false);

    const [dadosCidades, setDadosCidades] = useState<Array<interfaceDadosCidades>>([]);
    const [loadingCidadesSelected, setLoadingCidadesSelected] = useState(false);

    const [dadosListTipoPJ, setDadosListTipoPJ] = useState<Array<interfaceListTipoProcessosJ>>([]);
    const [loadingSelectedTipoPJ, setLoadingSelectedTipoPJ] = useState(false);

    const [loadingPage, setLoadingPage] = useState(false);
    const [isSalvarOrEditar, setIsSalvarOrEditar] = useState(false);

    const [disabledSelectedEstado, setDisabledSelectedEstado] = useState(false);
    const [disabledSelectedCidade, setDisabledSelectedCidade] = useState(false);
    const [disabledSelectedTipo, setDisabledSelectedTipo] = useState(false);

    //setando dados das cidades no selected
    const buscaEstadosESetSelected = useCallback(() => {
        setLoadingEstadosAndCitySelected(true);
        const buscaEstados = async () => {
            try {
                let resultCidade = await axios.get(urlBuscaEstados, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });
                setDadosEstadosAndCityList(resultCidade.data);
                setLoadingEstadosAndCitySelected(false);
            } catch (error) {
                setLoadingEstadosAndCitySelected(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar Estados!',
                });
            }
        }

        buscaEstados();

    }, []);

    const buscaTipoProcessosJuriESetSelected = useCallback(() => {
        setLoadingSelectedTipoPJ(true);
        const buscaEstados = async () => {
            try {
                let resultCidade = await axios.get(urlTipoProcessoJuridico, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });
                setDadosListTipoPJ(resultCidade.data);
                setLoadingSelectedTipoPJ(false);
            } catch (error) {
                setLoadingSelectedTipoPJ(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar Estados!',
                });
            }
        }

        buscaEstados();

    }, []);

    //setando dados das cidades no selected
    const buscaCityAndSetSelected = useCallback((idEstado: number) => {
        setLoadingCidadesSelected(true);
        const buscaCiaddes = async () => {
            try {
                let resultCidade = await axios.get(urlBuscaCidadesPorIdEstado + idEstado, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });
                setDadosCidades(resultCidade.data);
                setLoadingCidadesSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingCidadesSelected(false);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });
            }
        }

        buscaCiaddes();

    }, []);

    const comonentDidMount = useEffect((): void => {
        setIsSalvarOrEditar(true);
        buscaEstadosESetSelected();
        buscaTipoProcessosJuriESetSelected();

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:processosJuridicos');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            //buscar cidades
            buscaCityAndSetSelected(localUserData?.estadoId);

            refForm.current.setFieldsValue(
                {
                    estados: localUserData?.estadoId ? localUserData.estadoId : undefined,
                    cidadeId: localUserData?.cidadeId ? localUserData.cidadeId : undefined,
                    tipo: localUserData?.idTipo ? localUserData.idTipo : undefined,
                    usuario: localUserData?.usuario ? localUserData.usuario : undefined,
                    senha: localUserData?.senha ? localUserData.senha : undefined,
                }
            );
            setIsSalvarOrEditar(false);

            setDisabledSelectedEstado(true);
            setDisabledSelectedCidade(true);
            setDisabledSelectedTipo(true);
        }

    }, []);

    //executa apenas quando desmonta o component
    useEffect(() => () => {
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:processosJuridicos');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:processosJuridicos');
        }

    }, []);

    const onChangeEstadosAndCity = useCallback((e: number) => {
        buscaCityAndSetSelected(e);
        refForm.current.setFieldsValue(
            {
                cidadeId: undefined,
            }
        );
        refForm.current.validateFields(['cidadeId']);
    }, [buscaCityAndSetSelected]);

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/cidadesUser");
    }, [history]);

    const onClickBotaoEditar = useCallback((): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:processosJuridicos');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }
        const editarPJ = async (values: any) => {
            setLoadingPage(true);
            try {
                let resultData = await axios.put(urlProcessosJuridicos, values, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });

                setLoadingPage(false);
                notification.success({
                    message: 'Sucesso',
                    description: "Dados atualizados com sucesso!",
                });
                history.push("/cidadesUser");
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });
            }
        }

        refForm.current.validateFields()
            .then((values: any) => {
                if (localUserData?.id) {
                    values.id = localUserData.id
                }

                // editar
                editarPJ(values);
            })
            .catch((errorInfo: any) => {
                // console.log(errorInfo)
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, []);

    const onClickBotaoSalvar = useCallback((): void => {
        const salvarPJ = async (values: any) => {
            setLoadingPage(true)
            try {
                let resultData = await axios.post(urlProcessosJuridicos, values, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });

                setLoadingPage(false);
                notification.success({
                    message: 'Sucesso',
                    description: "Dados criados com sucesso!",
                });
                history.push("/cidadesUser");
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',

                });
            }
        }

        refForm.current.validateFields()
            .then((values: any) => {
                // salvar
                salvarPJ(values);
            })
            .catch((errorInfo: any) => {
                // console.log(errorInfo)
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, []);

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><UnlokProcessoJuridico style={{ fontSize: 35, marginRight: 10 }} /> {isSalvarOrEditar ? "Adicionar" : "Editar"} Processos Jurídicos (Senhas)</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Processos Juridicos</a></Breadcrumb.Item>
                        <Breadcrumb.Item>{isSalvarOrEditar ? "Adicionar" : "Editar"} Processos Juridicos</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <Spin spinning={loadingPage}>
                <Form
                    ref={refForm}
                    name="formUserPassInCidades"
                    layout="vertical"
                    onFinishFailed={() => { }}
                >
                    <Row>
                        <Col {...inputSelectEstadosCity}>
                            <Form.Item
                                name="estados"
                                label="Estados"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor selecione um estado!",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    loading={loadingEstadosAndCitySelected}
                                    notFoundContent={loadingEstadosAndCitySelected ? <Spin size="small" /> : null}
                                    placeholder="Selecione..."
                                    onChange={onChangeEstadosAndCity}
                                    disabled={disabledSelectedEstado}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dadosEstadosAndCityList.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.nome}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...inputSelectCidades}>
                            <Form.Item
                                name="cidadeId"
                                label="Cidade"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor selecione uma cidade!",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    loading={loadingCidadesSelected}
                                    notFoundContent={loadingCidadesSelected ? <Spin size="small" /> : null}
                                    placeholder="Selecione..."
                                    optionFilterProp="children"
                                    disabled={disabledSelectedCidade}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dadosCidades.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.nome + " - " + item.uf}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...inputTipo}>
                            <Form.Item
                                name="tipo"
                                label="Tipo"
                                initialValue={1}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por selecione o tipo!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    loading={loadingSelectedTipoPJ}
                                    notFoundContent={loadingSelectedTipoPJ ? <Spin size="small" /> : null}
                                    placeholder="Selecione..."
                                    optionFilterProp="children"
                                    disabled={disabledSelectedTipo}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dadosListTipoPJ.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.nome}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...inputUsuario}>
                            <Form.Item
                                name="usuario"
                                label="Usuário"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite o nome do usuário!',
                                    },
                                ]}
                            >
                                <Input placeholder="Nome do usuário" />
                            </Form.Item>
                        </Col>
                        <Col {...inputPassword}>
                            <Form.Item
                                name="senha"
                                label="Senha"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite a senha!',
                                    },
                                ]}
                            >
                                <Input placeholder="Senha" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
            <Row>
                <Col span={24} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}>
                    <Button loading={loadingPage} style={{ marginRight: 10 }} onClick={onClickVoltarConfigOfSystem} >
                        Cancelar
                    </Button>
                    {isSalvarOrEditar ?
                        <Button loading={loadingPage} type="primary" onClick={onClickBotaoSalvar} >
                            Salvar
                        </Button>
                        :
                        <Button loading={loadingPage} type="primary" onClick={onClickBotaoEditar} >
                            Atualizar
                        </Button>
                    }
                </Col>
            </Row>

        </>
    );
}


export default AdicionarAssociacao;
