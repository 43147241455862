import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import {
    Button, Form, notification, Typography, Spin, Row, Col, Breadcrumb, Divider, Select, Modal,
    Input, Radio, Table, Space, Tag, Popconfirm, Tooltip, Switch,
    FormInstance
} from 'antd';
import Highlighter from 'react-highlight-words';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import Icon, {
    SolutionOutlined,
    SearchOutlined, EditOutlined
} from '@ant-design/icons';
import {
    cardLayout,
    botaoSalvar,
    selectedVinteQuatro,
    selectedCidade,
    selectedPoder,
    campoValidadeDoTesteCol,
    modalAddModuloInputs,
    modaisDeConfig,
    colMeioIni,
    colMeioMeio
} from './GridStyle';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import axios from 'axios';
import {
    urlContratoVendedores,
    urlPoder,
    urlBuscaCidades,
    urlOutrosAtendimentosAtendimento,
    urlAssociacaoAll,
    urlOutrosAtendimentosAtendimentoContato,
    urlOutrosAtendimentosContato,
    urlOutrosAtendimentosAtendimentoVerificaExiste,
    urlOutrosAtendimentosAtendimentoTelefone,
    urlOutrosAtendimentosProduto,
    urlOutrosAtendimentosAtendimentoIDProduto
} from '../../../../services/request/urls';
import {
    convertDataEHoraBrParaUS, convertDataUSParaBR, convertMoedaUSParaBRInicioSemDigitos
} from './../../../../services/Conversores';

import {
    removeAcento
} from './../../../../utils/RemoveAcentos';
import { getToken } from '../../../../services/token';

import { mask, unMask } from '../../../../utils/MascaraDeCampos';

import { FaSearch, FaPlus, FaWhatsapp } from 'react-icons/fa';
import { ImFloppyDisk } from 'react-icons/im';
const IconSerach = (props: any) => { return <Icon {...props} component={FaSearch} ></Icon> }
const IconMais = (props: any) => { return <Icon {...props} component={FaPlus} ></Icon> }

const IconFloppyDisk = (props: any) => { return <Icon {...props} component={ImFloppyDisk} ></Icon> }
const IconWhatsApp = (props: any) => <Icon {...props} component={FaWhatsapp} ></Icon>

const { Title, Text } = Typography;

interface DadosCidadesAll {
    "id": number,
    "uuid": string,
    "nome": string,
    "microrregiao": {
        "mesorregiao": {
            "estado": {
                "id": number,
                "uuid": string,
                "sigla": string,
                "nome": string
            }
        }
    }
}
interface DadosAssociacaoAll {
    id: number,
    nome: string,
    tipo: string,
    uuid: string
}

interface DadosContatoEdit {
    atendimentoId: number
    data: string
    data_proximo_contato: string
    vendedorId: string
    id: number
    importante: boolean
    nome: string
    observacao: string
    status: string
    tipo: string
    telefone: string
    uuid: string
    vendedor: { nome: string }
}

interface DadosUrlAtendimentoEContato {
    produtoAtendimento: { id: number, nome: string }
    associacaoId: number | null,
    cidadeId: number | null,
    contatos: Array<DadosContatoEdit>,
    contrato: { nome: string, validade_teste: string | null, tipo_contrato: { id: number, nome: string } },
    contratoId: number,
    data: string,
    data_proximo_contato: string,
    id: number,
    nome: string,
    poderId: number,
    status: string,
    uuid: string,
    vendedorId: number,
    telefone_principal: string | null,
    cidade: { populacao: number }
}

interface interfaceConfigs {
    nome: string,
    id: number
}

const AddOrEditOutrosAtendimentos: React.FC = (props, parans) => {

    const { idParam }: any = useParams();

    const refAllDados = useRef<null | DadosUrlAtendimentoEContato>(null);
    const refNomePRODUTOIDParaModalContato = useRef<any>(null);
    const refNomeCidadeParaModalContato = useRef<any>(null);
    const refIdPoderParaModalContato = useRef<any>(2);

    const refContratoSituacao = useRef<{ nome: string, id: number } | null>(null);

    const refClickModal = useRef<boolean>(true);
    const formRef = useRef<any>(null);
    const refFormModal = useRef<any>(null);

    const history = useHistory();
    const inputNomeContato = useRef<any>(null);

    const idAtendimento = useRef<any>(null);
    const refInitialValueEditIdContato = useRef<undefined | string | number>(undefined);

    const [loadingFormAdd, setLoadingFormAdd] = useState(false);

    const [dadosCidadesList, setDadosCidadesList] = useState<Array<DadosCidadesAll>>([]);
    const [loadingSelectedCidades, setLoadingSelectedCidades] = useState(false);
    const [dadosAssocList, setDadosAssocList] = useState<Array<DadosAssociacaoAll>>([]);
    const [loadingSelectedAssoc, setLoadingSelectedAssoc] = useState(false);
    const [modalAddContato, setModalAddContato] = useState(false);

    const refFormModalAddProduto = useRef<FormInstance>(null);
    const [modalAddProdutoVisible, setModalAddProdutoVisible] = useState(false);
    const [loadingFormModalProduto, setLoadingFormModalProduto] = useState(false);

    const [dadosProdutoList, setDadosProdutoList] = useState<Array<interfaceConfigs>>([]);
    const [loadingSelectedProduto, setLoadingSelectedProduto] = useState(false);
    const refDadosSelectProdutos = React.useRef<Array<interfaceConfigs>>([]);


    const [modalViewDadosContato, setModalViewDadosContato] = useState(false);


    const [dadosVendedoresList, setDadosVendedoresList] = useState<Array<interfaceConfigs>>([]);
    const [loadingVendedoresSelected, setLoadingVendedoresSelected] = useState(false);


    const [dadosPoderList, setDadosPoderList] = useState<Array<interfaceConfigs>>([]);
    const [loadingPoderSelected, setLoadingPoderSelected] = useState(false);
    const refDadosPoderList = React.useRef<Array<interfaceConfigs>>([]);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaContato, setDadosTabelaContato] = useState<Array<DadosContatoEdit>>([]);
    const [loadingTable, setLoadingTable] = useState(false);


    const [valorOnChangeCity, setValorOnChangeCity] = useState('cidade');
    const [valueTagStatus, setValueTagStatus] = useState('');

    const [dadosViewDadosContatoInModal, setDadosViewDadosContatoInModal] = useState<any>(undefined);

    const [selectedDate, handleDateChange] = useState<any>(null);

    const [stateValueStatusModalContato, setStateValueStatusModalContato] = useState<string | null>(null);


    const [switchImportantChecked, setSwitchImportantChecked] = useState(false);


    const [id, setId] = useState(undefined);

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
    }, []);



    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosVendedoresList = useCallback(() => {

        setLoadingVendedoresSelected(true);
        const buscaDadosVendedores = async () => {
            try {
                let result = await axios.get(urlContratoVendedores,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosVendedoresList(result.data);

                setLoadingVendedoresSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingVendedoresSelected(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar vendedores!',
                    });
                }
            }
        }

        buscaDadosVendedores();

    }, []);


    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosCidadesList = useCallback(() => {

        setLoadingSelectedCidades(true);
        const buscaDadosPoder = async () => {
            try {
                let result = await axios.get(urlBuscaCidades,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosCidadesList(result.data)

                setLoadingSelectedCidades(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingSelectedCidades(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDadosPoder();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosAssociacaoList = useCallback(() => {

        setLoadingSelectedAssoc(true);
        const buscaDados = async () => {
            try {
                let result = await axios.get(urlAssociacaoAll,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosAssocList(result.data)

                setLoadingSelectedAssoc(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingSelectedAssoc(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDados();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const encontrarNomePoderPeloID = useCallback((id?: number | string | null) => {
        let resultadoPoder = dadosPoderList.filter((poder) => poder.id == id)
        if (resultadoPoder.length > 0) {
            return resultadoPoder[0].nome
        }
        return ''
    }, [dadosPoderList]);

    const buscarDadosPoderList = useCallback(() => {

        setLoadingPoderSelected(true);
        const buscaDadosPoder = async () => {
            try {
                let result = await axios.get(urlPoder,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosPoderList(result.data);
                refDadosPoderList.current = result.data

                setLoadingPoderSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingPoderSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDadosPoder();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const encontrarNomeProdutoPeloID = useCallback((id?: number | string | null) => {
        let result = refDadosSelectProdutos.current.filter((item) => item.id == id)
        if (result.length > 0) {
            return result[0].nome
        }
        return ''
    }, []);

    const buscarDadosProdutosList = useCallback((setCampo?, id?) => {

        setLoadingSelectedProduto(true);
        const requestAsync = async () => {
            try {
                let result = await axios.get(urlOutrosAtendimentosProduto,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosProdutoList(result.data)
                refDadosSelectProdutos.current = result.data

                if (setCampo) {
                    formRef.current?.setFieldsValue({ produto_atendimento_id: id })
                }

                setLoadingSelectedProduto(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingSelectedProduto(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        requestAsync();

    }, []);


    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarAtendimentoEContatoESetCampos = useCallback((id: number | string) => {

        setLoadingFormAdd(true);
        const buscaDados = async () => {
            try {
                let result = await axios.get(urlOutrosAtendimentosAtendimentoContato + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                const {
                    poderId, cidadeId, associacaoId, contatos, nome, status, contrato, telefone_principal, cidade,
                    produtoAtendimento
                }: DadosUrlAtendimentoEContato = result.data

                refAllDados.current = result.data

                refNomeCidadeParaModalContato.current = nome
                refIdPoderParaModalContato.current = poderId
                refNomePRODUTOIDParaModalContato.current = produtoAtendimento.id

                refContratoSituacao.current = contrato?.tipo_contrato

                if (cidadeId) {
                    setValorOnChangeCity('cidade')
                } else { setValorOnChangeCity('associacao') }

                formRef.current.setFieldsValue(
                    {
                        cidade: cidadeId,
                        associacao: associacaoId,
                        produto_atendimento_id: produtoAtendimento.id,
                        poder_id: poderId,
                        contratoMostrar: contrato?.nome,
                        status: status,
                        validadeDeteste: contrato?.validade_teste ? convertDataUSParaBR(contrato.validade_teste) : undefined,
                        populacao: cidade?.populacao ? convertMoedaUSParaBRInicioSemDigitos(cidade?.populacao.toString()) : undefined,
                    }
                );

                if (telefone_principal) {

                    const originalValue = unMask(telefone_principal);
                    const maskedValue = mask(originalValue, [
                        "(99) 9999-9999",
                        "(99) 9 9999-9999"
                    ]);
                    formRef.current?.setFieldsValue({
                        telefone_principal: maskedValue
                    })

                }

                setValueTagStatus(status);

                setDadosTabelaContato(contatos)

                setLoadingFormAdd(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingFormAdd(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDados();

    }, []);

    const comonentDidMount = useEffect((): void => {

        if (idParam) {
            buscarAtendimentoEContatoESetCampos(idParam);
            idAtendimento.current = idParam
            setId(idParam)
        }


        buscarDadosCidadesList();
        buscarDadosAssociacaoList();
        buscarDadosPoderList();

        buscarDadosProdutosList();

        buscarDadosVendedoresList();

    }, [idParam]);

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/outros-atendimentos");
    }, []);

    const clickCancelModalAddContato = useCallback(() => {
        setModalAddContato(false);
        refFormModal.current.resetFields();

        refClickModal.current = true
    }, []);


    const clickSubmitSalvarAtendimento = useCallback((): void => {

        formRef.current?.validateFields()
            .then((values: any) => {

                let obj = {
                    id: parseInt(idAtendimento.current),
                    telefone_principal: unMask(values.telefone_principal),
                }
                // values.id = parseInt(idAtendimento.current)
                // values.contrato = { id: values.contrato_id }

                const salvarDadosModal = async () => {
                    try {
                        setLoadingFormAdd(true);

                        let resultCidade = await axios.patch(urlOutrosAtendimentosAtendimentoTelefone, obj,
                            { headers: { 'Authorization': 'Bearer ' + getToken() } });
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram atualizados com sucesso!',
                        });
                        setLoadingFormAdd(false);

                        // onClickVoltarConfigOfSystem();

                    } catch (error) {

                        let msgErro: any = (error as Error);
                        setLoadingFormAdd(false);

                        notification.error({
                            message: 'Erro',
                            description:
                                msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                        });

                    }
                }
                salvarDadosModal();

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, []);


    const onChangeInputTelefone = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        refFormModal.current.setFieldsValue(
            { telefone: maskedValue }
        );
        if (maskedValue.length == 16) {
            refFormModal.current.validateFields(["telefone"])
        }

    }, []);

    const onChangeInputTelefonePrincipal = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        formRef.current.setFieldsValue(
            { telefone_principal: maskedValue }
        );

        if (maskedValue.length == 16) {
            formRef.current.validateFields(["telefone_principal"])
        }

    }, []);

    const clickBotaoViewDadosContatoModal = useCallback((idModal): void => {
        let objClicado = dadosTabelaContato.filter(element => {
            return element.id === idModal
        });

        let vendedorObj = dadosVendedoresList.filter((element) => {
            return element.id === parseInt(objClicado[0].vendedorId)
        });

        let fotMatTelefone = mask(objClicado[0].telefone, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        // iulhpuihewf

        let objView = {
            data: moment(objClicado[0].data).format('DD/MM/YYYY HH:mm'),
            data_proximo_contato: objClicado[0]?.data_proximo_contato ? moment(objClicado[0].data_proximo_contato).format('DD/MM/YYYY HH:mm') : '',
            id: objClicado[0]?.id,
            nome: objClicado[0]?.nome,
            observacao: objClicado[0]?.observacao,
            status: objClicado[0]?.status,
            tipo: objClicado[0]?.tipo,
            telefone: fotMatTelefone,
            uuid: objClicado[0]?.uuid,
            vendedor: vendedorObj[0]?.nome,
        }

        setModalViewDadosContato(true);

        setDadosViewDadosContatoInModal(objView)

    }, [dadosTabelaContato, dadosVendedoresList]);

    const clickBotaoEditarModal = useCallback((idModal): void => {
        let objClicado = dadosTabelaContato.filter(element => {
            return element.id === idModal
        });

        let fotMatTelefone = mask(objClicado[0].telefone, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        setModalAddContato(true);


        setTimeout(() => {
            refFormModal.current?.setFieldsValue(
                {
                    nome: objClicado[0].nome,
                    telefone: fotMatTelefone,
                    vendedor_id: objClicado[0].vendedorId,
                    // data_proximo_contato: objClicado[0]?.data_proximo_contato ? moment(objClicado[0].data_proximo_contato) : undefined,
                    status: objClicado[0].status,
                    tipo: objClicado[0].tipo,
                    observacao: objClicado[0].observacao,
                }
            );

            handleDateChange(objClicado[0]?.data_proximo_contato ? moment(objClicado[0].data_proximo_contato) : null)

            setSwitchImportantChecked(objClicado[0].importante)
            setStateValueStatusModalContato(objClicado[0].status)
        }, 500);

        // setInitialValueEditIdContato(objClicado[0].id)
        refInitialValueEditIdContato.current = objClicado[0].id

    }, [dadosTabelaContato]);

    const clickBotaoAddModalWithInputsPreInseridos = useCallback((idModal): void => {

        refInitialValueEditIdContato.current = undefined

        let objClicado = dadosTabelaContato.filter(element => {
            return element.id === idModal
        });

        let fotMatTelefone = mask(objClicado[0].telefone, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        setModalAddContato(true);


        setTimeout(() => {
            refFormModal.current?.setFieldsValue(
                {
                    nome: objClicado[0].nome,
                    telefone: fotMatTelefone,
                    vendedor_id: objClicado[0].vendedorId,
                    // data_proximo_contato: objClicado[0]?.data_proximo_contato ? moment(objClicado[0].data_proximo_contato) : undefined,
                    status: objClicado[0].status,
                    tipo: objClicado[0].tipo,
                    // observacao: objClicado[0].observacao,
                }
            );
            setStateValueStatusModalContato(objClicado[0].status)
        }, 500);

        handleDateChange(null)

    }, [dadosTabelaContato]);

    const onChangeSwitchContatoImportant = useCallback((checked): void => {
        setSwitchImportantChecked(checked)
    }, []);

    const clickSubmitModalAddContato = useCallback((): void => {

        setLoadingFormAdd(true);

        refFormModal.current?.validateFields()
            .then((values: any) => {

                values = {
                    ...values,
                    atendimento_id: parseInt(idAtendimento.current),
                    telefone: unMask(values.telefone),
                    // telefone_principal: unMask(values.telefone),
                    importante: switchImportantChecked,
                    // data_proximo_contato: values?.data_proximo_contato ? convertDataEHoraBrParaUS(values.data_proximo_contato) : undefined
                    data_proximo_contato: selectedDate ? convertDataEHoraBrParaUS(selectedDate) : null
                }


                if (refInitialValueEditIdContato.current) {
                    values.id = refInitialValueEditIdContato.current;

                    const editarDadosModal = async () => {
                        try {
                            let resultCidade = await axios.put(urlOutrosAtendimentosContato, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram editados com sucesso!',
                            });


                            buscarAtendimentoEContatoESetCampos(idAtendimento.current);


                            clickCancelModalAddContato();

                            refInitialValueEditIdContato.current = undefined

                            setLoadingFormAdd(false);
                        } catch (error) {
                            let msgErro: any = (error as Error);
                            refClickModal.current = true
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    editarDadosModal();
                } else {


                    const salvarDadosModal = async () => {
                        try {

                            let resultCidade = await axios.post(urlOutrosAtendimentosContato, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram criados com sucesso!',
                            });



                            setLoadingFormAdd(false);

                            buscarAtendimentoEContatoESetCampos(idAtendimento.current);

                            clickCancelModalAddContato();
                        } catch (error) {
                            let msgErro: any = (error as Error);
                            refClickModal.current = true
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }

                    const addContratoESalvarDadosModal = async (valores: any) => {

                        try {

                            valores.telefone_principal = unMask(values.telefone);

                            let resultCidade = await axios.post(urlOutrosAtendimentosAtendimento, valores,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });

                            idAtendimento.current = resultCidade.data.id
                            setId(resultCidade.data.id);

                            //SALVAR DADOS MODAL
                            values.atendimento_id = parseInt(resultCidade.data.id)

                            let resultmODAL = await axios.post(urlOutrosAtendimentosContato, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });

                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram criados com sucesso!',
                            });

                            buscarAtendimentoEContatoESetCampos(resultCidade.data.id);
                            clickCancelModalAddContato();
                            setLoadingFormAdd(false);


                        } catch (error) {
                            let msgErro: any = (error as Error);
                            refClickModal.current = true
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!',
                            });
                        }
                    }
                    formRef.current.validateFields()
                        .then((values: any) => {

                            if (values.cidadeOuAssociacao === 'cidade') {

                                values.cidade_id = values.cidade
                            } else {

                                values.associacao_id = values.associacao
                            }

                            if (!id) {
                                addContratoESalvarDadosModal(values);
                            } else {
                                salvarDadosModal();
                            }


                        })
                        .catch((errorInfo: any) => {
                            refClickModal.current = true
                            // console.log(errorInfo)
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Preencha os campos obrigatorios!',
                            });
                        });




                }


            })
            .catch((errorInfo: any) => {
                refClickModal.current = true
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [id, selectedDate, switchImportantChecked]);

    const onClickAddContato = useCallback((): void => {

        //get local storage
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:token');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const addContato = async (valores: any) => {

            try {

                let result = await axios.post(urlOutrosAtendimentosAtendimentoVerificaExiste, valores,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });


                setModalAddContato(true);

                if (localUserData.user.vendedor) {
                    refFormModal.current.setFieldsValue(
                        { vendedor_id: localUserData.user.id }
                    );
                }

                inputNomeContato.current.focus();

            } catch (error) {
                let msgErro: any = (error as Error);

                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!',
                });
            }
        }
        formRef.current.validateFields()
            .then((values: any) => {
                // setModalAddContato(true);
                // inputNomeContato.current.focus();

                //////////////////////////////////////////////////////////ANTIGO

                if (values.cidadeOuAssociacao === 'cidade') {

                    values.cidade_id = values.cidade
                } else {

                    values.associacao_id = values.associacao
                }

                handleDateChange(null);
                setSwitchImportantChecked(false);

                if (!id) {
                    addContato(values);
                } else {

                    //apenas abrir modal
                    setModalAddContato(true);

                    if (localUserData.user.vendedor) {
                        refFormModal.current.setFieldsValue(
                            { vendedor_id: localUserData.user.id }
                        );
                    }

                    inputNomeContato.current.focus();
                }


            })
            .catch((errorInfo: any) => {
                // console.log(errorInfo)
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [id]);



    const clickSubmitSalvarProdutoInAtendimento = useCallback((): void => {

        formRef.current?.validateFields()
            .then((values: any) => {

                let obj = {
                    produto_atendimento_id: values.produto_atendimento_id,
                }
                // values.id = parseInt(idAtendimento.current)
                // values.contrato = { id: values.contrato_id }

                const salvarDadosModal = async () => {
                    try {
                        setLoadingFormAdd(true);

                        await axios.patch(urlOutrosAtendimentosAtendimentoIDProduto(idAtendimento.current), obj,
                            { headers: { 'Authorization': 'Bearer ' + getToken() } });


                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram atualizados com sucesso!',
                        });
                        setLoadingFormAdd(false);

                        // onClickVoltarConfigOfSystem();

                    } catch (error) {

                        let msgErro: any = (error as Error);
                        setLoadingFormAdd(false);

                        notification.error({
                            message: 'Erro',
                            description:
                                msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                        });

                    }
                }
                salvarDadosModal();

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, []);



    //Search da tabela de listagem

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {

                let trecord = removeAcento(record[dataIndex].toString());

                return record[dataIndex] ? trecord.includes(removeAcento(value)) : false

            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    const getColumnSearchPropsObjNome = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex]?.nome ? record[dataIndex].nome.toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsData = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any): any => {
                let texto: string | undefined = ''
                if (record[dataIndex]) texto = moment(record[dataIndex]).format('DD/MM/YYYY HH:mm')

                return (
                    texto ? texto.toString().toLowerCase().includes(value.toLowerCase()) : ''
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    // fim do search da tabela de listagem


    const columnsTableContato = [
        {
            title: 'Data do contato',
            dataIndex: 'data',
            ...getColumnSearchPropsData('data'),
            width: '5%',
            render: (criado: any) => {
                let date = moment(criado).format('DD/MM/YYYY HH:mm')
                return (
                    date ? date : ''
                )
            },
        },
        {
            title: 'Nome do contato',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '7%',
            render: (nome: any, record: any) => {
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        zIndex: 5
                    }}>
                        <Text>{nome}</Text>
                        {
                            record?.telefone ?
                                <Button
                                    className='botaoWhatsApp_fwebfhewvouyfgvwa'
                                    type="primary"
                                    size='small'
                                    style={{ marginLeft: 5 }}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        const link = document.createElement('a');
                                        link.href = `https://api.whatsapp.com/send/?phone=55${record.telefone.trim()}&text&type=phone_number&app_absent=1`
                                        link.target = "_blank";

                                        document.body.appendChild(link);

                                        link.click();

                                        return
                                    }}
                                >
                                    <IconWhatsApp />
                                </Button>
                                :
                                <></>
                        }
                    </div >

                )
            }
        },
        {
            title: 'Próximo contato',
            dataIndex: 'data_proximo_contato',
            ...getColumnSearchPropsData('data_proximo_contato'),
            width: '5%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (criado: any) => {
                let date = criado ? moment(criado).format('DD/MM/YYYY HH:mm') : ''
                return (
                    date ? date : ''
                )
            },
        },
        {
            title: 'Vendedor',
            dataIndex: 'vendedor',
            ...getColumnSearchPropsObjNome('vendedor'),
            width: '3%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (vendedor: any) => (
                vendedor?.nome
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            ...getColumnSearchProps('status'),
            width: '3%',
            render: (val: any) => {
                let cor = '';
                switch (val) {
                    case "Sem Retorno":
                        cor = '#f00';
                        break;
                    case "Processo em andamento":
                        cor = '#999';
                        break;
                    case "A Finalizar":
                        cor = '#2db7f5';
                        break;
                    case "Efetivado":
                        cor = '#17751b';
                        break;
                    case "Sem interesse":
                        cor = '#930';
                        break;
                    case "Em Contato":
                        cor = '#7931ec';
                        break;
                    case "Agendado":
                        cor = '#7bd280';
                        break;
                    case "Apresentado":
                        cor = '#0751a0';
                        break;
                    // case "Em teste":
                    //     cor = 'purple';
                    //     break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag color={cor} key={val}>
                            {val}
                        </Tag>
                    </span>
                )
            },
        },
        // {
        //     title: 'Tipo',
        //     dataIndex: 'tipo',
        //     ...getColumnSearchProps('tipo'),
        //     width: '2.5%',
        //     render: (val: any) => {
        //         let cor = '';
        //         switch (val) {
        //             case 'Finalizado':
        //                 cor = '#006';
        //                 break;

        //             default:
        //                 cor = ''
        //                 break;
        //         }
        //         return (
        //             <span>
        //                 <Tag key={val}>
        //                     {val}
        //                 </Tag>
        //             </span>
        //         )
        //     },
        // },
        {
            title: 'Ações',
            dataIndex: 'id',
            // ...getColumnSearchProps('created_at'),
            width: '8%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (idModal: any) => (
                <>
                    <Row>
                        <Tooltip title="Adicionar" color="green" key="adicionargreen">
                            <Button
                                type="primary"
                                className="botaoVincularUsuarioInAtendimentos"
                                onClick={() => clickBotaoAddModalWithInputsPreInseridos(idModal)}
                            >
                                <IconMais />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Editar" color="gold" key="editargold">
                            <Button
                                type="primary"
                                style={{ marginLeft: 5 }}
                                className="EditarCidadesContratoSalvas"
                                onClick={() => clickBotaoEditarModal(idModal)}
                            >
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Visualizar" color="blue" key="visualizarblue">
                            <Button
                                type="primary"
                                style={{ marginLeft: 5 }}
                                onClick={() => clickBotaoViewDadosContatoModal(idModal)}
                            >
                                <SearchOutlined />
                            </Button>
                        </Tooltip>
                    </Row>
                </>
            ),
        },
    ];


    //Modal Adicionar Produto
    const clickSubmitModalAddProduto = useCallback((): void => {
        refFormModalAddProduto.current?.validateFields()
            .then((values: any) => {
                setLoadingFormModalProduto(true);

                const salvarDadosModal = async () => {
                    try {
                        let resultReq = await axios.post(urlOutrosAtendimentosProduto, values,
                            {
                                headers: { 'Authorization': 'Bearer ' + getToken() }
                            });
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Novo produto criados com sucesso!',
                        });

                        setLoadingFormModalProduto(false);
                        buscarDadosProdutosList(true, resultReq.data?.id);
                        clickCancelModalAddProduto();
                    } catch (error) {
                        setLoadingFormModalProduto(false);
                        notification.error({
                            message: 'Erro',
                            description:
                                'Não foi possivel salvar, entre em contato com suporte!',
                        });
                    }
                }
                salvarDadosModal();

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, []);

    const clickCancelModalAddProduto = useCallback((): void => {
        setModalAddProdutoVisible(false);
        refFormModalAddProduto.current?.resetFields();
    }, []);

    const onClickAddProduto = useCallback((e): void => {
        setModalAddProdutoVisible(true);
    }, []);

    const renderViewModalProduto = useCallback(() => {

        return (
            <Modal
                title="Adicionar produto"
                className="modalAddCargo"
                visible={modalAddProdutoVisible}
                onOk={clickSubmitModalAddProduto}
                onCancel={clickCancelModalAddProduto}
                footer={[
                    <Button key="back" onClick={clickCancelModalAddProduto}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" onClick={clickSubmitModalAddProduto}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormModalProduto}>
                    <Form
                        ref={refFormModalAddProduto}
                        name="formModalAddProduto"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor digite o nome do produto!',
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                >
                                    <Input placeholder="Nome do produto" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }, [modalAddProdutoVisible, loadingFormModalProduto]);
    //Modal Adicionar Produto fechar

    return (
        <>
            <Spin spinning={loadingFormAdd}>
                <Row>
                    <Col {...cardLayout}>
                        <Title level={3}><SolutionOutlined style={{ fontSize: 35, marginRight: 10 }} /> {!id ? "Cadastrar" : "Editar"} Outro Atendimento</Title>
                        <Breadcrumb>
                            <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Outros Atendimentos</a></Breadcrumb.Item>
                            <Breadcrumb.Item>{!id ? "Cadastrar" : "Editar"} Outros Atendimento</Breadcrumb.Item>
                        </Breadcrumb>
                        <Divider />
                    </Col>
                </Row>
                <Form
                    ref={formRef}
                    name="formNewAtendimentos"
                    layout="horizontal"
                    initialValues={{
                        remember: true,
                    }}
                >
                    <Row>
                        <Col {...colMeioIni}>
                            <Row>
                                {!id &&
                                    <>
                                        <Col {...selectedVinteQuatro}>
                                            <Form.Item
                                                name="cidadeOuAssociacao"
                                                // label="Poder"
                                                initialValue='cidade'
                                                style={{ margin: 0, marginBottom: 15 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione!',
                                                    },
                                                ]}
                                            >
                                                <Radio.Group onChange={e => {
                                                    setValorOnChangeCity(e.target.value)
                                                    if (e.target.value === 'cidade') {
                                                        let valorCampoCidade = formRef.current.getFieldValue('cidade');
                                                        if (valorCampoCidade) {
                                                            let arrCidadeSelecionada = dadosCidadesList.filter(element => {
                                                                return element.id === valorCampoCidade
                                                            });

                                                            refNomeCidadeParaModalContato.current = arrCidadeSelecionada[0]?.nome
                                                        }
                                                    } else {
                                                        //associacao
                                                        let valorCampoAssociacao = formRef.current.getFieldValue('associacao');
                                                        if (valorCampoAssociacao) {
                                                            let arrAssociacaoSelecionada = dadosAssocList.filter(element => {
                                                                return element.id === valorCampoAssociacao
                                                            });

                                                            refNomeCidadeParaModalContato.current = arrAssociacaoSelecionada[0]?.nome
                                                        }
                                                    }
                                                }}>
                                                    <Radio value='cidade'>Cidade</Radio>
                                                    <Radio value='associacao'>Associação</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                                {dadosTabelaContato.length != 0 &&
                                    <Col {...selectedVinteQuatro}>
                                        <Form.Item
                                            name="status"
                                            label="Status"
                                            style={{ margin: 0, marginBottom: 15 }}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Por favor selecione a cidade!',
                                                },
                                            ]}
                                        >
                                            <Tag
                                                style={{
                                                    fontSize: 16
                                                }}
                                                color={
                                                    valueTagStatus === "Sem Retorno" ?
                                                        '#f00' :
                                                        valueTagStatus === "A Finalizar" ?
                                                            '#2db7f5' :
                                                            valueTagStatus === "Efetivado" ?
                                                                '#17751b' :
                                                                valueTagStatus === "Sem interesse" ?
                                                                    '#930' :
                                                                    valueTagStatus === "Em Contato" ?
                                                                        '#7931ec' :
                                                                        valueTagStatus === "Agendado" ?
                                                                            '#7bd280' :
                                                                            valueTagStatus === "Apresentado" ?
                                                                                '#0751a0' :
                                                                                valueTagStatus === "Processo em andamento" ?
                                                                                    '#999' : ''
                                                }
                                            >
                                                {valueTagStatus}
                                            </Tag>
                                        </Form.Item>
                                    </Col>
                                }
                                {/* <Row> */}
                                <Col {...selectedPoder}>
                                    <Form.Item
                                        name="poder_id"
                                        label="Poder"
                                        initialValue={2}
                                        style={{ margin: 0, marginBottom: 15 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecione o poder!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            disabled={id}
                                            loading={loadingPoderSelected}
                                            notFoundContent={loadingPoderSelected ? <Spin size="small" /> : null}
                                            optionFilterProp="children"
                                            onSelect={(id: any, obj: any) => {

                                                refIdPoderParaModalContato.current = id

                                            }}
                                            placeholder="Selecione..."
                                            filterOption={(input: any, option: any) =>
                                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {dadosPoderList.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome}
                                                    </Select.Option>
                                                )
                                            })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* </Row>
                            <Row> */}
                                <Col {...selectedCidade}>
                                    {
                                        valorOnChangeCity === 'cidade' ?

                                            <Form.Item
                                                name="cidade"
                                                label="Cidade"
                                                style={{ margin: 0, marginBottom: 15 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione a cidade!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    disabled={id}
                                                    loading={loadingSelectedCidades}
                                                    notFoundContent={loadingSelectedCidades ? <Spin size="small" /> : null}
                                                    optionFilterProp="children"
                                                    onSelect={(id: any, obj: any) => {

                                                        refNomeCidadeParaModalContato.current = obj?.children

                                                    }}
                                                    placeholder="Selecione..."
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }
                                                    }
                                                >
                                                    {
                                                        dadosCidadesList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome + ' - ' + item?.microrregiao?.mesorregiao?.estado?.sigla}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            :
                                            <Form.Item
                                                name="associacao"
                                                label="Associação"
                                                style={{ margin: 0, marginBottom: 15 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione a associação!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    disabled={id}
                                                    loading={loadingSelectedAssoc}
                                                    notFoundContent={loadingSelectedAssoc ? <Spin size="small" /> : null}
                                                    optionFilterProp="children"
                                                    onSelect={(id: any, obj: any) => {

                                                        refNomeCidadeParaModalContato.current = obj?.children

                                                    }}
                                                    placeholder="Selecione..."
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }
                                                    }
                                                >
                                                    {
                                                        dadosAssocList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                    }
                                </Col>
                                {/* <Divider style={{ margin: 0, marginBottom: 20 }} className="dividerPermisaoDoApp" /> */}
                            </Row>
                        </Col>
                        <Col {...colMeioMeio}>

                            <Row>
                                {dadosTabelaContato.length != 0 &&
                                    <>
                                        <Col {...selectedPoder} style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            // backgroundColor: 'red',
                                            justifyContent: 'space-between',
                                            // alignItems: 'flex-end',

                                        }}>
                                            <Form.Item
                                                name="telefone_principal"
                                                label="Telefone Principal"
                                                style={{
                                                    margin: 0, marginBottom: 15,
                                                    width: '100%'
                                                }}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'Por favor digite o telefone!',
                                                    },
                                                    () => ({
                                                        validator(rule, value) {
                                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                                if (value != '' && value != null) {
                                                                    value = unMask(value);

                                                                    if (value.length >= 10) {
                                                                        resolve();
                                                                    } else {
                                                                        reject("Telefone inválido");
                                                                    }
                                                                } else {
                                                                    resolve();
                                                                }
                                                            });
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input
                                                    type="tel"
                                                    autoComplete="off"
                                                    onChange={(e) => onChangeInputTelefonePrincipal(e)}
                                                    placeholder="(44) 9 9999-9999"
                                                />
                                            </Form.Item>
                                            <Tooltip title="Salvar" color="green" key="salvarTelefonePrincipal">
                                                <Button
                                                    style={{
                                                        marginBottom: 15,
                                                        marginLeft: 5
                                                    }}
                                                    className="botaoVincularUsuarioInAtendimentos"
                                                    type="primary"
                                                    onClick={() => clickSubmitSalvarAtendimento()}
                                                >
                                                    <IconFloppyDisk />
                                                </Button>
                                            </Tooltip>
                                        </Col>

                                        <Col {...selectedCidade}>
                                            <Form.Item
                                                name="populacao"
                                                label="População"
                                                style={{ margin: 0, marginBottom: 15 }}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'Por favor digite o telefone!',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    autoComplete="off"
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col {...campoValidadeDoTesteCol} style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            // backgroundColor: 'red',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-end'
                                        }}>
                                            <Form.Item
                                                name="validadeDeteste"
                                                label="Validade do teste"
                                                style={{
                                                    margin: 0, marginBottom: 15,
                                                    width: '100%'
                                                }}
                                                hidden={
                                                    refContratoSituacao.current?.nome === 'Período de Teste' ||
                                                        refContratoSituacao.current?.nome === 'Automático' ?
                                                        false : true
                                                }
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'Por favor selecione o Contrato!',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    disabled
                                                    autoComplete="off"
                                                />
                                            </Form.Item>
                                        </Col>

                                    </>

                                }
                            </Row>
                            {/* <Divider style={{ margin: 0, marginBottom: 20 }} className="dividerPermisaoDoApp" /> */}
                        </Col>
                        <Col {...colMeioMeio}>
                            <Col {...selectedPoder} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                // backgroundColor: 'red',
                                justifyContent: 'space-between',
                                // alignItems: 'flex-end',

                            }}>
                                <Form.Item
                                    name="produto_atendimento_id"
                                    label="Produto"
                                    style={{
                                        margin: 0, marginBottom: 15,
                                        width: '100%'
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor Selecione!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        loading={loadingSelectedProduto}
                                        notFoundContent={loadingSelectedProduto ? <Spin size="small" /> : null}
                                        optionFilterProp="children"
                                        placeholder="Selecione..."
                                        onSelect={(id: any) => {
                                            refNomePRODUTOIDParaModalContato.current = id
                                        }}
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                        dropdownRender={menu => (
                                            <div>

                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Button block onClick={onClickAddProduto} type="dashed">Adicionar Produto</Button>
                                                </div>
                                                <Divider style={{ margin: '4px 0' }} />
                                                {menu}
                                            </div>
                                        )}
                                    >
                                        {
                                            dadosProdutoList.map((item) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                    >
                                                        {item.nome}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                {dadosTabelaContato.length != 0 &&
                                    <Tooltip title="Salvar" color="green" key="salvarTelefonePrincipal">
                                        <Button
                                            style={{
                                                marginBottom: 15,
                                                marginLeft: 5
                                            }}
                                            className="botaoVincularUsuarioInAtendimentos"
                                            type="primary"
                                            onClick={() => clickSubmitSalvarProdutoInAtendimento()}
                                        >
                                            <IconFloppyDisk />
                                        </Button>
                                    </Tooltip>
                                }
                            </Col>
                        </Col>


                        <Divider style={{ margin: 0, marginBottom: 20 }} className="dividerPermisaoDoApp" />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button
                                style={{ marginBottom: 20 }}
                                type="primary"
                                onClick={() => onClickAddContato()}
                            >Adicionar Contato</Button>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col span={24}>
                        {dadosTabelaContato.length != 0 &&
                            <Table
                                rowKey={(record) => record.id.toString()}
                                loading={loadingTable}
                                onRow={(record, rowIndex) => {
                                    return {
                                        // onClick: event => { onclickTable(record, rowIndex) }, // click row
                                    };
                                }}
                                // rowSelection={rowSelectionContato}
                                columns={columnsTableContato} dataSource={dadosTabelaContato}
                                // scroll={{ x: 695 }}
                                scroll={{ x: 1095 }}
                                size="small"
                                pagination={{ locale: { items_per_page: '' } }}
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Divider />
                    <Col {...botaoSalvar}>
                        <div style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>

                            <Button style={{ marginRight: 10 }} onClick={onClickVoltarConfigOfSystem} >
                                Cancelar
                            </Button>
                            {/* {id &&
                                <Button type="primary" onClick={clickSubmitSalvarAtendimento} >
                                    Atualizar
                                </Button>
                            } */}
                        </div>
                    </Col>
                </Row>
                <Modal
                    title="Adicionar Contato"
                    visible={modalAddContato}
                    className="modalAddAtendimentos"
                    onOk={() => {
                        if (refClickModal.current) {
                            refClickModal.current = false
                            clickSubmitModalAddContato()
                            setStateValueStatusModalContato(null)
                        }
                    }}
                    onCancel={() => {
                        clickCancelModalAddContato()
                        setStateValueStatusModalContato(null)
                    }}
                    footer={[
                        <Button key="back" disabled={loadingFormAdd} onClick={() => {
                            clickCancelModalAddContato()
                            setStateValueStatusModalContato(null)
                        }}>
                            Cancelar
                        </Button>,
                        <React.Fragment key='fragmento'>
                            {!id ?
                                <Popconfirm
                                    key="pop"
                                    placement="topRight"
                                    title={() => (
                                        <div className='popConfirmEditAtendimentos'>
                                            <Text>
                                                {`Deseja realmente salvar contato para cidade de ${refNomeCidadeParaModalContato?.current} - ${encontrarNomePoderPeloID(refIdPoderParaModalContato?.current)}?`}
                                            </Text>
                                        </div>
                                    )}
                                    onConfirm={() => {
                                        if (refClickModal.current) {
                                            refClickModal.current = false
                                            clickSubmitModalAddContato()
                                            setStateValueStatusModalContato(null)
                                        }
                                    }}
                                    onCancel={() => {
                                        setStateValueStatusModalContato(null)
                                    }}
                                    okText="Sim"
                                    cancelText="Não"
                                >
                                    <Button key="talvez" type="primary" loading={loadingFormAdd}>
                                        Salvar
                                    </Button>
                                </Popconfirm>
                                :
                                <Button key="submit" type="primary" loading={loadingFormAdd} onClick={() => {
                                    if (refClickModal.current) {
                                        refClickModal.current = false
                                        clickSubmitModalAddContato()
                                        setStateValueStatusModalContato(null)
                                    }
                                }}>
                                    Salvar
                                </Button>
                            }
                        </React.Fragment>,
                        <React.Fragment key='frgBotaoSalvarEInavitacr'>
                            {
                                (id && stateValueStatusModalContato != null &&
                                    [
                                        'Habilitado / Sem interesse',
                                        'Não renovou',
                                        'Sem interesse',
                                        'Bloqueado'
                                    ].includes(stateValueStatusModalContato)) ?
                                    <Tooltip title="Salvar e Inativar Contrato" color="red" key="tolptsalareinativrconatrao">
                                        <Button key="submit"
                                            type="primary"
                                            loading={loadingFormAdd}
                                            onClick={() => {
                                                if (refClickModal.current) {
                                                    refClickModal.current = false
                                                    clickSubmitModalAddContato()
                                                    setStateValueStatusModalContato(null)
                                                }
                                            }}
                                            danger
                                        >
                                            Salvar e Inativar
                                        </Button>
                                    </Tooltip>
                                    :
                                    <></>
                            }
                        </React.Fragment>,
                    ]}
                >
                    <Spin spinning={loadingFormAdd}>
                        <Form
                            ref={refFormModal}
                            name="formModalAddContato"
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            onFinishFailed={() => { }}
                        >
                            <Row>
                                <Col {...modalAddModuloInputs} >
                                    <Row style={{ marginBottom: 5, marginLeft: 5 }}>
                                        <Text strong>{valorOnChangeCity === 'cidade' ?
                                            'Cidade:' : 'Associação'
                                        }</Text>
                                        <Text style={{ marginLeft: 5 }}>
                                            {refNomeCidadeParaModalContato?.current}
                                        </Text>
                                    </Row>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    <Row style={{ marginBottom: 5, marginLeft: 5 }}>
                                        <Text strong>
                                            {'Poder: '}
                                        </Text>
                                        <Text style={{ marginLeft: 5 }}>
                                            {encontrarNomePoderPeloID(refIdPoderParaModalContato?.current)}
                                            {/* {refIdPoderParaModalContato?.current - 1} */}
                                        </Text>
                                    </Row>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    <Row style={{ marginBottom: 5, marginLeft: 5 }}>
                                        <Text strong>
                                            {
                                                'Produto:'
                                            }
                                        </Text>
                                        <Text style={{ marginLeft: 5 }}>
                                            {encontrarNomeProdutoPeloID(refNomePRODUTOIDParaModalContato?.current)}
                                        </Text>
                                    </Row>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o nome!",
                                            }
                                        ]}
                                        name="nome"
                                        label="Nome do contato"
                                        style={{ margin: 5 }}
                                    >
                                        <Input
                                            ref={inputNomeContato}
                                            autoComplete="off"
                                            // onChange={onChangeInputNumberNumero}
                                            placeholder="Nome"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o telefone!",
                                            }
                                        ]}
                                        name="telefone"
                                        label="Telefone do contato"
                                        style={{ margin: 5 }}
                                    >
                                        <Input
                                            type="tel"
                                            autoComplete="off"
                                            onChange={(e) => onChangeInputTelefone(e)}
                                            placeholder="(44) 9 9999-9999"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        name="vendedor_id"
                                        label="Vendedor"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecione o vendedor!',
                                            },
                                        ]}
                                        style={{ margin: 5 }}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            // mode="multiple"
                                            loading={loadingVendedoresSelected}
                                            notFoundContent={loadingVendedoresSelected ? <Spin size="small" /> : null}
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                        >
                                            {
                                                dadosVendedoresList.map((item) => {
                                                    return (
                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.nome}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col {...modalAddModuloInputs}>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: "Preencha o data!",
                                            },

                                        ]}
                                        name="data_proximo_contato"
                                        label="Próximo contato"
                                        style={{ margin: 5 }}

                                    >
                                        <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                            <DateTimePicker
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                required={false}
                                                style={{
                                                    width: '100%'
                                                }}
                                                okLabel="Ok"
                                                clearLabel="Limpar"
                                                cancelLabel="Cancelar"
                                                clearable
                                                format="dd/MM/yyyy HH:mm"
                                                className="datePickerCOlors"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Form.Item>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                // required: true,
                                                // message: "Preencha o status!",
                                            }
                                        ]}
                                        name="important"
                                        label="Contato Importante?"
                                        style={{ margin: 5 }}
                                    >
                                        <>
                                            <Switch checkedChildren="Sim" unCheckedChildren="Não" checked={switchImportantChecked} onChange={onChangeSwitchContatoImportant} />
                                        </>
                                    </Form.Item>
                                </Col>
                                <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o status!",
                                            }
                                        ]}
                                        name="status"
                                        label="Status"
                                        style={{ margin: 5 }}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            onChange={(value) => {
                                                setStateValueStatusModalContato(value)
                                            }}
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                        >
                                            <Select.Option
                                                value={"Efetivado"}
                                                key={"Efetivado"}
                                            >
                                                {"Efetivado"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Em Contato"}
                                                key={"Em Contato"}
                                            >
                                                {"Em Contato"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Sem Retorno"}
                                                key={"Sem Retorno"}
                                            >
                                                {"Sem Retorno"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Processo em andamento"}
                                                key={"Processo em andamento"}
                                            >
                                                {"Processo em andamento"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Sem interesse"}
                                                key={"Sem interesse"}
                                            >
                                                {"Sem interesse"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Agendado"}
                                                key={"Agendado"}
                                            >
                                                {"Agendado"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Apresentado"}
                                                key={"Apresentado"}
                                            >
                                                {"Apresentado"}
                                            </Select.Option>

                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* <Col {...modalAddModuloInputs}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Preencha o tipo!",
                                            }
                                        ]}
                                        name="tipo"
                                        label="Tipo"
                                        style={{ margin: 5 }}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            onChange={(value) => {
                                                // setStateValueTipoModalContato(value)
                                            }}

                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                        >
                                            <Select.Option
                                                value={"Qualidade"}
                                                key={"Qualidade"}
                                            >
                                                {"Qualidade"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Contratação"}
                                                key={"Contratação"}
                                            >
                                                {"Contratação"}
                                            </Select.Option>
                                            <Select.Option
                                                value={"Renovação"}
                                                key={"Renovação"}
                                            >
                                                {"Renovação"}
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col> */}

                                <Col {...modaisDeConfig}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: "Por favor comente!",
                                            }
                                        ]}
                                        name="observacao"
                                        label="Observação"
                                    >
                                        <Input.TextArea style={{ maxHeight: 200, height: 100 }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>

                <Modal
                    title="Dados Contato"
                    visible={modalViewDadosContato}
                    className="classmodalDadosContatoInatendimento"
                    onOk={() => setModalViewDadosContato(false)}
                    onCancel={() => setModalViewDadosContato(false)}
                    footer={[
                        <Button key="back" onClick={() => setModalViewDadosContato(false)}>
                            Fechar
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>{'Nome:'}</Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.nome}
                                </Text>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Telefone: </Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.telefone}
                                </Text>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Vendedor: </Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.vendedor}
                                </Text>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Status: </Text>
                                <Tag
                                    style={{
                                        marginLeft: 5,
                                        fontSize: 14
                                    }}
                                    color={
                                        dadosViewDadosContatoInModal?.status === "Sem Retorno" ?
                                            '#f00' :
                                            dadosViewDadosContatoInModal?.status === "A Finalizar" ?
                                                '#2db7f5' :
                                                dadosViewDadosContatoInModal?.status === "Efetivado" ?
                                                    '#17751b' :
                                                    dadosViewDadosContatoInModal?.status === "Sem interesse" ?
                                                        '#930' :
                                                        dadosViewDadosContatoInModal?.status === "Em Contato" ?
                                                            '#7931ec' :
                                                            valueTagStatus === "Agendado" ?
                                                                '#7bd280' :
                                                                valueTagStatus === "Apresentado" ?
                                                                    '#0751a0' :
                                                                    dadosViewDadosContatoInModal?.status === "Processo em andamento" ?
                                                                        '#999' : ''
                                    }
                                >
                                    {dadosViewDadosContatoInModal?.status}
                                </Tag>
                            </Row>
                        </Col>
                        {/* <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Tipo: </Text>
                                <Tag
                                    style={{
                                        marginLeft: 5,
                                        fontSize: 14
                                    }}
                                // color={}
                                >
                                    {dadosViewDadosContatoInModal?.tipo}
                                </Tag>
                            </Row>
                        </Col> */}
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Data do contato: </Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.data}
                                </Text>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Data do próximo contato: </Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.data_proximo_contato}
                                </Text>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 5 }}>
                            <Row>
                                <Text strong>Observação: </Text>
                                <Text style={{ marginLeft: 5 }}>
                                    {dadosViewDadosContatoInModal?.observacao}
                                </Text>
                            </Row>
                        </Col>
                    </Row>
                </Modal>
            </Spin >
            {renderViewModalProduto()}
        </>
    );
}

export default AddOrEditOutrosAtendimentos;
