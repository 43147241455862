import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
    Row, Col, Form, Select, Table, notification, Input, Button, Space,
    Divider, Typography
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import * as UsuariosActions from '../../../../store/modules/usuario/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {
    urlUsers, urlNotificacao,
    urlConfigRotasPainelPermissao, urlRotasPainelPermissao
} from '../../../../services/request/urls';
import { getToken } from './../../../../services/token';

interface dadosDaTabela {
    id: number;
    nome: string;
    path: string;
    status: boolean;
};

const { Text } = Typography;

const PermissoesPainel: React.FC = () => {

    const refForm = useRef<any>(null);
    const refFormSelectRota = useRef<any>(null);
    const refIdSalvarPermissao = useRef<any>(null);
    const refBotaoAddTodasRotas = useRef<any>(null);

    const dispatch = useDispatch();
    const history = useHistory();

    // localStorage.setItem('@GovFacil:userData', JSON.stringify(this.user));

    // userData = JSON.parse(localStorage.getItem('@GovFacil:userData');

    const signal2 = useRef(axios.CancelToken.source());

    //Search da tabela de listagem
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<dadosDaTabela>>([]);
    const [dadosTabelaSelected, setDadosTabelaSelected] = useState<Array<dadosDaTabela>>([]);
    const [realmenteSalvarNoLocalStorage, setRealmenteSalvarNoLocalStorage] = useState(true);
    const [dadosTotais, setDadosTotais] = useState<Array<dadosDaTabela>>([]);
    // const [loadingForm, setLoadingForm] = useState(false);

    const refDadosTabelaState = useRef<object[] | Array<any> | null | undefined>();
    const refDadosTabelaSelected = useRef<object[] | Array<any> | null | undefined>();

    //component did mount
    useEffect(() => {
        //scroll sempre no topo
        window.scrollTo(0, 0);

        //envia referencia do form par poder validar
        dispatch(UsuariosActions.formularioRefUsers(refForm));

        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const buscaRotasPainel = async () => {
            try {
                let resultRotas = await axios.get(urlConfigRotasPainelPermissao,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal2.current.token
                    });

                if (localUserData?.isEdit) {
                    // urlRotasPainelPermissao
                    let resultRotasUser: any = null
                    // resultRotasUser = await axios.get(urlRotasPainelPermissao + "/" + localUserData.idUser,
                    //     {
                    //         headers: { 'Authorization': 'Bearer ' + getToken() },
                    //         cancelToken: signal2.current.token
                    //     });

                    // //setar id em var para poder editar
                    // refIdSalvarPermissao.current = resultRotasUser.data ? resultRotasUser.data.id : null

                    //seta no state os dados que estão no local storage
                    if ((localUserData && localUserData?.step5) && localUserData.step5?.dadosTabelaState.length > 0 || localUserData.step5?.dadosTabelaSelected.length > 0) {
                        setDadosTabelaState(localUserData?.step5.dadosTabelaState)
                        setDadosTabelaSelected(localUserData?.step5.dadosTabelaSelected)
                    } else {

                        let isEqualsRotas = false;
                        let copyRotasSelected = [...resultRotas.data];

                        if (resultRotasUser?.data) {//se existir resultado busca

                            for (let index = 0; index < resultRotas.data.length; index++) {
                                const objTOTALrotas = resultRotas.data[index];

                                resultRotasUser.data.rotasPainel.forEach((element: any, posicao: any) => {
                                    if (objTOTALrotas.id == element.id) {
                                        isEqualsRotas = true;
                                    }
                                });

                                if (isEqualsRotas) {
                                    copyRotasSelected = copyRotasSelected.filter((valores: any) => {
                                        return valores.id != objTOTALrotas.id
                                    })
                                    isEqualsRotas = false
                                }

                            }

                            localUserData = {
                                ...localUserData,
                                "step5": {
                                    dadosTabelaState: resultRotasUser.data.rotasPainel,
                                    dadosTabelaSelected: copyRotasSelected,
                                }
                            }
                            localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));
                            setDadosTabelaState(resultRotasUser.data.rotasPainel)
                            setDadosTabelaSelected(copyRotasSelected)

                        } else {

                            localUserData = {
                                ...localUserData,
                                "step5": {
                                    dadosTabelaState: [],
                                    dadosTabelaSelected: resultRotas.data,
                                }
                            }
                            localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));
                            setDadosTabelaState([])
                            setDadosTabelaSelected(resultRotas.data)
                        }

                    }

                } else {

                    //seta no state os dados que estão no local storage
                    if ((localUserData && localUserData?.step5) && localUserData.step5?.dadosTabelaState.length > 0 || localUserData.step5?.dadosTabelaSelected.length > 0) {
                        setDadosTabelaState(localUserData?.step5.dadosTabelaState)
                        setDadosTabelaSelected(localUserData?.step5.dadosTabelaSelected)
                    } else {
                        localUserData = {
                            ...localUserData,
                            "step5": {
                                dadosTabelaState: [],
                                dadosTabelaSelected: resultRotas.data,
                            }
                        }
                        localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));
                        setDadosTabelaState([])
                        setDadosTabelaSelected(resultRotas.data)
                    }
                }

                setDadosTotais(resultRotas.data);

            } catch (error) {
                console.log(error)
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar rotas!',
                    });
                }
            }
        }

        buscaRotasPainel();
    }, [dispatch]);

    //executa apenas quando desmonta o component DESTROY
    useEffect(() => () => {
        if (realmenteSalvarNoLocalStorage) {
            //pega dados do localStorage se existir
            let dadosLocalStorage2: string | null = localStorage.getItem('@GovFacil:userData');
            let localUserData2: any = null;
            if (typeof dadosLocalStorage2 == 'string') {
                localUserData2 = JSON.parse(dadosLocalStorage2);
            }
            localUserData2 = {
                ...localUserData2,
                "step5": {
                    dadosTabelaState: refDadosTabelaState.current,
                    dadosTabelaSelected: refDadosTabelaSelected.current,
                }
            }
            localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData2));
        }


        signal2.current.cancel("Requisicao cancelada!")
    }, [realmenteSalvarNoLocalStorage]);

    //executa todo momento que é atualizado as variaveis
    useEffect(() => {
        refDadosTabelaState.current = dadosTabelaState;
        refDadosTabelaSelected.current = dadosTabelaSelected;
    }, [dadosTabelaState, dadosTabelaSelected]);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, []);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    //seact da tabela
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    // fim do search da tabela de listagem

    const excluirDaTabela = useCallback((recorde: any): void => {
        //removendo da tabela
        let filter = dadosTabelaState.filter((val) => {
            return val !== recorde
        })
        setDadosTabelaState(filter);

        //incluirndo o valor removido no select
        let arrayTabela = dadosTabelaSelected.concat(recorde);
        setDadosTabelaSelected(arrayTabela);
    }, [dadosTabelaState, dadosTabelaSelected]);

    //ao selecionar um item das rotas remove do select e add na tabela
    const onChangeSelect = useCallback((e, vallorSelecionado): void => {
        if (dadosTabelaState.length === 0) {
            dadosTotais.forEach((item) => {
                if (item.nome === vallorSelecionado.children) {
                    setDadosTabelaState([item]);

                    let filter = dadosTabelaSelected.filter((val) => {
                        return val !== item
                    })
                    setDadosTabelaSelected(filter)
                }
            })
        } else {

            dadosTotais.forEach((item) => {
                if (item.nome === vallorSelecionado.children) {
                    let arrayTabela = dadosTabelaState.concat(item);
                    setDadosTabelaState(arrayTabela);

                    let filter = dadosTabelaSelected.filter((val) => {
                        return val.nome !== vallorSelecionado.children
                    })

                    setDadosTabelaSelected(filter);
                }
            })
        }
        refForm.current.setFieldsValue({ "selectRota": null });
        refFormSelectRota.current.blur();
    }, [dadosTabelaState, dadosTabelaSelected, dadosTotais]);

    //Adicionar todas as cidades de uma vez so dentro da tabela
    const onClickAddTodasRotasInTable = useCallback(() => {

        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        localUserData = {
            ...localUserData,
            "step5": {
                dadosTabelaState: dadosTotais,
                dadosTabelaSelected: [],
            }
        }
        localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));
        setDadosTabelaState(dadosTotais)
        setDadosTabelaSelected([])

        refBotaoAddTodasRotas.current?.blur();
        refFormSelectRota.current?.blur();

    }, [dadosTotais, dadosTabelaState]);

    //Adicionar todas as cidades de uma vez so dentro da tabela
    const onClickRemoverTodasAsRotasInTable = useCallback(() => {

        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        localUserData = {
            ...localUserData,
            "step5": {
                dadosTabelaState: [],
                dadosTabelaSelected: dadosTotais,
            }
        }
        localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));
        setDadosTabelaState([])
        setDadosTabelaSelected(dadosTotais)

        refBotaoAddTodasRotas.current?.blur();
        refFormSelectRota.current?.blur();

    }, [dadosTotais, dadosTabelaState]);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
        },
        {
            title: 'Path',
            dataIndex: 'path',
            ...getColumnSearchProps('path'),
        },
        {
            title: 'Remover',
            dataIndex: 'excluir',
            render: (text: any, record: any) =>
                dadosTabelaState.length >= 1 ? (
                    <Button type="primary" onClick={() => excluirDaTabela(record)} danger >
                        <DeleteOutlined />
                    </Button>
                ) : null,
        },
    ];

    const formarObjDaManeiraPraEditarNotificacaoEUsuario = useCallback((ObjFormatLsStorage: any): any => {

        let salvar: any = {}
        let copyNotify: any = {}
        let copyUser: any = {}

        copyNotify = {
            "permissao_app": ObjFormatLsStorage?.permissao_app ? ObjFormatLsStorage?.permissao_app : undefined,
            "permissao_notificacao": ObjFormatLsStorage?.notificacao?.permissao_notificacao ? ObjFormatLsStorage.notificacao.permissao_notificacao : undefined,
            "contratoId": ObjFormatLsStorage?.contrato?.id ? ObjFormatLsStorage.contrato.id : undefined
        }

        copyUser = {
            ...ObjFormatLsStorage,
        }
        delete copyUser.permissao_app
        delete copyUser.notificacao.permissao_notificacao

        salvar.usuarioSalvar = copyUser
        salvar.notificacaoSalvar = copyNotify

        return salvar
    }, []);

    //Adicionar todas as cidades de uma vez so dentro da tabela
    const formarObjDaManeiraPraSalvar = useCallback((LsStorage: any): any => {

        // ----------- Preparando obj--------------

        try {
            let objHorario: Array<object> = [];
            let objIntervalo: Array<object> = [];
            let objPermissaoNotificacao: Array<object> = [];

            // preparar horario para salvar
            if (LsStorage?.step4?.horario) {
                LsStorage?.step4?.horario.forEach((val: any, i: any) => {
                    objHorario.push({ id: val })
                });

                LsStorage.step4.horario = objHorario
            }

            // preparar intervalo para salvar
            if (LsStorage?.step4?.intervalo) {
                LsStorage?.step4?.intervalo.forEach((val: any, i: any) => {
                    objIntervalo.push({ id: val })
                });

                LsStorage.step4.intervalo = objIntervalo
            }

            if (LsStorage?.permissao_notificacao && LsStorage?.permissao_notificacao.length != 0) {
                LsStorage?.permissao_notificacao.forEach((val: any, i: any) => {
                    val.contratoId = LsStorage?.step2?.contrato;

                    objPermissaoNotificacao.push(val)
                });

                LsStorage.step4.permissao_notificacao = objPermissaoNotificacao

            } else {
                LsStorage.step4.permissao_notificacao = []
            }

            // ------------------------------------------------------------------------------

            //salvar rotas da step5, permisao painel
            let objIdRotas: any = [];
            let objsaveRotas: any = {};

            if (dadosTabelaState) {
                dadosTabelaState.forEach((val: any, i: any) => {
                    objIdRotas.push({ id: val.id })
                });

                objsaveRotas.rotasPainel = objIdRotas
            }

            // ------------------------------------------------------------------------------

            // verifica se tem step3

            let step3: any = undefined;
            if (LsStorage?.step3?.salvar) {
                step3 = LsStorage?.step3?.salvar
            }

            let objSalvar: any = {
                ...LsStorage.step1,
                contrato: { "id": LsStorage.step2.contrato },
                permissao_app: step3 ? {
                    ...step3
                } : undefined,
                notificacao: {
                    ...LsStorage.step4
                }
            }

            return objSalvar
        } catch (error) {
            // console.log('error')
            // console.log(error)
        }


    }, [dadosTabelaState]);

    const onFinish = useCallback((formValues): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        //comentado pq não esta usando rotas de permissao painel
        // if (dadosTabelaState.length != 0) {

        // } else {
        //     notification.error({
        //         message: 'Erro',
        //         description: "É obrigatorio selecionar pelo menos uma rota!",
        //     });
        //     return
        // }

        //navegar para usuarios e salvar

        // const salvarDadosModoPainel = async () => {
        //     dispatch(UsuariosActions.setSpinnerStep(true));
        //     try {
        //         //Salvando dados do usuario
        //         let resultDadosUser = await axios.post(
        //             urlUsers,
        //             localUserData.step1,
        //             { headers: { 'Authorization': 'Bearer ' + getToken() } });

        //         //FIM salvar usuario

        //         //Salvar Permissao Painel
        //         let objIdRotas: any = [];
        //         let objSalvar: any = {};

        //         if (dadosTabelaState) {
        //             dadosTabelaState.forEach((val: any, i: any) => {
        //                 objIdRotas.push({ id: val.id })
        //             });

        //             objSalvar.usuarioId = resultDadosUser?.data?.id
        //             objSalvar.rotasPainel = objIdRotas
        //         }

        //         let resultPermissaoPainel = await axios.post(
        //             urlRotasPainelPermissao,
        //             objSalvar,
        //             { headers: { 'Authorization': 'Bearer ' + getToken() } });

        //         //FIMMM Salvar Permissao Painel

        //         notification.success({
        //             message: 'Sucesso',
        //             description:
        //                 'Seus dados foram criados com sucesso!',
        //         });
        //         dispatch(UsuariosActions.setSpinnerStep(false));
        //         history.push("/usuarios?at=1");
        //     } catch (error) {
        //         let msgErro: any = (error as Error);
        //         // console.log(error);
        //         dispatch(UsuariosActions.setSpinnerStep(false));
        //         notification.error({
        //             message: 'Erro',
        //             description: msgErro?.response?.data?.message,
        //         });
        //     }
        // }
        // const editarModoPainel = async () => {
        //     dispatch(UsuariosActions.setSpinnerStep(true));
        //     try {
        //         //Salvando dados do usuario
        //         let resultDadosUser = await axios.put(
        //             urlUsers + "/" + localUserData?.idUser,
        //             localUserData.step1,
        //             { headers: { 'Authorization': 'Bearer ' + getToken() } });
        //         //FIM salvar usuario

        //         //Salvar Permissao Painel
        //         let objIdRotas: any = [];
        //         let objSalvar: any = {};

        //         if (dadosTabelaState) {
        //             dadosTabelaState.forEach((val: any, i: any) => {
        //                 objIdRotas.push({ id: val.id })
        //             });

        //             objSalvar.usuarioId = resultDadosUser?.data?.id
        //             objSalvar.rotasPainel = objIdRotas
        //             objSalvar.id = refIdSalvarPermissao.current
        //         }


        //         if (refIdSalvarPermissao.current) {

        //             let resultPermissaoPainel = await axios.put(
        //                 urlRotasPainelPermissao,
        //                 objSalvar,
        //                 { headers: { 'Authorization': 'Bearer ' + getToken() } });

        //         } else {
        //             let resultPermissaoPainel = await axios.post(
        //                 urlRotasPainelPermissao,
        //                 objSalvar,
        //                 { headers: { 'Authorization': 'Bearer ' + getToken() } });
        //         }

        //         //FIMMM Salvar Permissao Painel

        //         notification.success({
        //             message: 'Sucesso',
        //             description:
        //                 'Seus dados foram alterados com sucesso!',
        //         });
        //         dispatch(UsuariosActions.setSpinnerStep(false));
        //         history.push("/usuarios?at=1");
        //     } catch (error) {
        //         let msgErro: any = (error as Error);
        //         dispatch(UsuariosActions.setSpinnerStep(false));
        //         console.log(error)
        //         notification.error({
        //             message: 'Erro',
        //             description: msgErro?.response?.data?.message,
        //         });
        //     }
        // }

        //MODO todOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOs
        //navegar para usuarios e salvar
        const salvarDadosModoTodos = async () => {
            dispatch(UsuariosActions.setSpinnerStep(true));
            try {

                let salvarrnewObj = formarObjDaManeiraPraSalvar(localUserData)


                //Salvando dados do usuario
                let resultDadosUser = await axios.post(
                    urlUsers,
                    // localUserData.step1,
                    salvarrnewObj,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                //FIM salvar usuario

                //salvar permissao APP
                // {

                // localUserData.step3.salvar.usuarioId = resultDadosUser?.data?.id

                // await axios.post(
                //     urlBuscaModulosPermissaoApp,
                //     localUserData.step3.salvar,
                //     { headers: { 'Authorization': 'Bearer ' + getToken() } });

                // }
                //FIM salvar permissao APP

                //SALVAR NOTIFICAÇÃO

                // let objHorario: Array<object> = [];
                // let objIntervalo: Array<object> = [];
                // let objPermissaoNotificacao: Array<object> = [];

                // // preparar horario para salvar
                // if (localUserData?.step4?.horario) {
                //     localUserData?.step4?.horario.forEach((val: any, i: any) => {
                //         objHorario.push({ id: val })
                //     });

                //     localUserData.step4.horario = objHorario
                // }

                // // preparar intervalo para salvar
                // if (localUserData?.step4?.intervalo) {
                //     localUserData?.step4?.intervalo.forEach((val: any, i: any) => {
                //         objIntervalo.push({ id: val })
                //     });

                //     localUserData.step4.intervalo = objIntervalo
                // }

                // if (localUserData?.permissao_notificacao && localUserData?.permissao_notificacao.length != 0) {
                //     localUserData?.permissao_notificacao.forEach((val: any, i: any) => {
                //         val.contratoId = localUserData?.step2?.contrato_id;
                //         val.usuarioId = resultDadosUser?.data?.id;

                //         objPermissaoNotificacao.push(val)
                //     });

                //     localUserData.step4.permissao_notificacao = objPermissaoNotificacao

                // } else {
                //     localUserData.step4.permissao_notificacao = []
                // }

                // let resultNotifi = await axios.post(
                //     urlNotificacao,
                //     localUserData.step4,
                //     { headers: { 'Authorization': 'Bearer ' + getToken() } });

                //FIM NOTIFICAÇÃO

                //Salvar Permissao Painel
                // let objIdRotas: any = [];
                // let objSalvar: any = {};

                // if (dadosTabelaState) {
                //     dadosTabelaState.forEach((val: any, i: any) => {
                //         objIdRotas.push({ id: val.id })
                //     });

                //     objSalvar.usuarioId = resultDadosUser?.data?.id
                //     objSalvar.rotasPainel = objIdRotas
                // }

                // let resultPermissaoPainel = await axios.post(
                //     urlRotasPainelPermissao,
                //     objSalvar,
                //     { headers: { 'Authorization': 'Bearer ' + getToken() } });

                //FIMMM Salvar Permissao Painel

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });
                dispatch(UsuariosActions.setSpinnerStep(false));
                history.push("/usuarios?at=1");
            } catch (error) {
                let msgErro: any = (error as Error);
                dispatch(UsuariosActions.setSpinnerStep(false));
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message,
                });
            }
        }
        const editarModoTodos = async () => {
            dispatch(UsuariosActions.setSpinnerStep(true));
            try {

                let editarrnewObjAntido = formarObjDaManeiraPraSalvar(localUserData)
                const editarrnewObj = formarObjDaManeiraPraEditarNotificacaoEUsuario(editarrnewObjAntido)

                //Salvando dados do usuario
                let resultDadosUser = await axios.put(
                    urlUsers + "/" + localUserData?.idUser,
                    editarrnewObj.usuarioSalvar,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                //FIM salvar usuario

                //Salvando dados do usuario permisso
                let resultDadosUserPermisso = await axios.put(
                    urlUsers + "/" + localUserData?.idUser + "/permissoes",
                    editarrnewObj.notificacaoSalvar,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                //FIM salvar usuario permisso

                //salvar permissao APP

                //VERIFICAR SE Contrato é add ou editado
                // let dadosContarto: any = [];
                // let iscontratoAdicionadoNovo: boolean = false
                // //se existir dados no local storage nao precisa fazer a requisição
                // if (localUserData?.dadosTotaisContratosDoUsuario) {

                //     dadosContarto = localUserData?.dadosTotaisContratosDoUsuario

                //     //para poder setar true na lixeira de excluir o item
                //     dadosContarto.forEach((element: any) => {

                //         if (localUserData?.step2?.contrato_id == element.id) {

                //             if (!element?.eUsuario) {
                //                 iscontratoAdicionadoNovo = true
                //             }
                //         }
                //     });

                // }

                // //salvar permissao APP
                // localUserData.step3.salvar.usuarioId = resultDadosUser?.data?.id

                // if (iscontratoAdicionadoNovo) {
                //     //para salvar novo contrato ao usuario
                //     await axios.post(
                //         urlBuscaModulosPermissaoApp,
                //         localUserData.step3.salvar,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });

                // } else {

                //     // para alterar o contrato do usuario

                //     //verificar se ja existe mesmo o editar dos dados
                //     await axios.put(
                //         urlBuscaModulosPermissaoApp,
                //         localUserData.step3.salvar,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });
                // }

                // //FIM salvar permissao APP

                // //SALVAR NOTIFICAÇÃO

                // let objHorario: Array<object> = [];
                // let objIntervalo: Array<object> = [];
                // let objPermissaoNotificacao: Array<object> = [];

                // // preparar horario para salvar
                // if (localUserData?.step4?.horario) {
                //     localUserData?.step4?.horario.forEach((val: any, i: any) => {
                //         objHorario.push({ id: val })
                //     });

                //     localUserData.step4.horario = objHorario
                // }

                // // preparar intervalo para salvar
                // if (localUserData?.step4?.intervalo) {
                //     localUserData?.step4?.intervalo.forEach((val: any, i: any) => {
                //         objIntervalo.push({ id: val })
                //     });

                //     localUserData.step4.intervalo = objIntervalo
                // }

                // if (localUserData?.permissao_notificacao && localUserData?.permissao_notificacao.length != 0) {
                //     localUserData?.permissao_notificacao.forEach((val: any, i: any) => {
                //         val.contratoId = localUserData?.step2?.contrato_id;
                //         val.usuarioId = resultDadosUser?.data?.id;

                //         objPermissaoNotificacao.push(val)
                //     });

                //     localUserData.step4.permissao_notificacao = objPermissaoNotificacao

                // } else {
                //     localUserData.step4.permissao_notificacao = []
                // }

                // if (localUserData.step4?.id) {
                //     let resultNotifi = await axios.put(
                //         urlNotificacao,
                //         localUserData.step4,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });
                // } else {
                //     let resultNotifi = await axios.post(
                //         urlNotificacao,
                //         localUserData.step4,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });
                // }

                // //FIM NOTIFICAÇÃO

                // //Salvar Permissao Painel
                // let objIdRotas: any = [];
                // let objSalvar: any = {};

                // if (dadosTabelaState) {
                //     dadosTabelaState.forEach((val: any, i: any) => {
                //         objIdRotas.push({ id: val.id })
                //     });

                //     objSalvar.usuarioId = resultDadosUser?.data?.id
                //     objSalvar.rotasPainel = objIdRotas
                //     objSalvar.id = refIdSalvarPermissao.current
                // }


                // if (refIdSalvarPermissao.current) {

                //     let resultPermissaoPainel = await axios.put(
                //         urlRotasPainelPermissao,
                //         objSalvar,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });

                // } else {
                //     let resultPermissaoPainel = await axios.post(
                //         urlRotasPainelPermissao,
                //         objSalvar,
                //         { headers: { 'Authorization': 'Bearer ' + getToken() } });
                // }

                //FIMMM Salvar Permissao Painel

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram alterados com sucesso!',
                });
                dispatch(UsuariosActions.setSpinnerStep(false));
                history.push("/usuarios?at=1");
            } catch (error) {
                let msgErro: any = (error as Error);
                dispatch(UsuariosActions.setSpinnerStep(false));
                console.log(error)
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message,
                });
            }
        }

        if (localUserData?.isEdit === true) {
            editarModoTodos();
        } else {
            salvarDadosModoTodos();
        }

        // switch (localUserData.step1?.sistema) {
        //     case 'painel':
        //         setRealmenteSalvarNoLocalStorage(false);
        //         //usuario e permissoes panel
        //         if (localUserData?.isEdit === true) {
        //             editarModoPainel();
        //         } else {
        //             salvarDadosModoPainel();
        //         }

        //         break;
        //     case 'todos':
        //         setRealmenteSalvarNoLocalStorage(false);
        //         if (localUserData?.isEdit === true) {
        //             editarModoTodos();
        //         } else {
        //             salvarDadosModoTodos();
        //         }

        //         break;

        //     default:
        //         break;
        // }

    }, [dadosTabelaState, history]);

    return (
        <>
            {/* <Spin spinning={loadingForm}> */}
            <Form
                ref={refForm}
                name="formPermissaoPainel"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={() => { }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="selectRota"
                            label="Selecione o item do menu desejado"
                        >
                            <Select
                                ref={refFormSelectRota}
                                placeholder="Selecione a rota.."
                                showSearch
                                optionFilterProp="children"
                                onChange={onChangeSelect}
                                filterOption={(input: any, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                dropdownRender={menu => (
                                    <div>

                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                            <Button
                                                block
                                                onClick={onClickAddTodasRotasInTable}
                                                type="dashed"
                                                ref={refBotaoAddTodasRotas}
                                            >Todos</Button>
                                        </div>
                                        <Divider style={{ margin: '4px 0' }} />
                                        {menu}
                                    </div>
                                )}
                            >
                                {
                                    dadosTabelaSelected.map((item) => {
                                        return (
                                            <Select.Option
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.nome}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Text>Rotas</Text><Button onClick={onClickRemoverTodasAsRotasInTable} hidden={dadosTabelaState.length != 0 ? false : true} className="botaoRemoverRotasInTableAll" danger type="primary">Remover Todos</Button>
            <Table size="small" onRow={(record, rowIndex) => {
                return {
                    onClick: event => { }, // click row
                    onDoubleClick: event => { }, // double click row
                    onContextMenu: event => { }, // right button click row
                    onMouseEnter: event => { }, // mouse enter row
                    onMouseLeave: event => { }, // mouse leave row
                };
            }}
                rowKey={(record) => record.id.toString()}
                columns={columns} dataSource={dadosTabelaState}
                pagination={{ locale: { items_per_page: '' } }}
            />
            {/* </Spin> */}
        </>
    );
}

export default PermissoesPainel;
