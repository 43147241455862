import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Table, Input, Button, Cascader, Select, Spin, Space, Row, Col, notification, Typography, Tag, Empty } from 'antd';
import Highlighter from 'react-highlight-words';
import {
    selectedNomeOrgaoAdd,
    selectedEstadosInAddAssociacao,
    cardLayout
} from './GridStyle';
import { SearchOutlined, MobileOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
// import { useForm } from 'antd/lib/form/Form';
import { getToken } from '../../services/token';
import {
    urlsessionUsuarioCapitado, urlsessionUsuariosCapitados,
} from '../../services/request/urls';
import axios from 'axios';
import { FaSearch, FaSitemap } from 'react-icons/fa';
import { mask, unMask } from '../../utils/MascaraDeCampos';
import { convertDataUSParaBR, convertHora } from '../../services/Conversores';

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

const ListCaptacaoDeUsuario: React.FC = () => {

    const refSelectContatctados = useRef<any>(null);

    const refVarsetInterval = useRef<any>(null);
    // formRef = React.createRef();

    const history = useHistory();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<any>([]);
    // const [varsetInterval, setVarsetInterval] = useState<any>(null);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedView, setHasSelectedView] = useState(false);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [valorSelecione, setValorSelecione] = useState('');

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!");

        clearInterval(refVarsetInterval.current);

    }, []);

    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                        text
                    ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsModulo = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                return (
                    record[dataIndex]?.nome ? record[dataIndex]?.nome.toString().toLowerCase().includes(value.toLowerCase()) : ''
                );
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                        text
                    ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    const getColumnSearchPropsCelular = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                return (
                    record[dataIndex]?.celular ? record[dataIndex]?.celular.toString().toLowerCase().includes(value.toLowerCase()) : ''
                );
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                        text
                    ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    const getColumnSearchPropsData = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let texto: any = ''
                if (record[dataIndex]) { texto = convertDataUSParaBR(record[dataIndex]) }

                return (
                    texto ? texto.toString().toLowerCase().includes(value.toLowerCase()) : ''
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                        text
                    ),
        })
    }, [searchText, searchedColumn]);

    // fim do search da tabela de listagem

    //abrir navegar em add contrato
    const clickBotaoEditarAssoc = useCallback((): void => {

        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {

                let objSelecionado: any = {}
                dadosTabelaState.forEach((dados: any): void => {
                    if (dados.uuid == selectedRowKeys[0]) {
                        objSelecionado = dados
                    }
                })

                try {

                    localStorage.setItem('@GovFacil:captacaousuario', JSON.stringify(objSelecionado));

                    history.push('/listcaptacaousuario/addobrigacao');
                } catch (error) {
                    let msgErro: any = (error as Error);
                    notification.error({
                        message: 'Erro',
                        description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    //abrir modal
    const clickBotaoAddAssociacao = useCallback((): void => {
        notification.warning({
            message: 'Atenção',
            description:
                'Em Breve!',
        });
        // let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:associacao');
        // let localUserData: any = null;
        // if (typeof dadosLocalStorage == 'string') {
        //     localUserData = JSON.parse(dadosLocalStorage);
        // }

        // if (localUserData) {
        //     localStorage.removeItem('@GovFacil:associacao');
        // }

        // history.push("/associacao/adicionar");
    }, []);

    // validatorConfmSenha = (rule, value, callback) => {
    //     try {
    //         let password = this.formRef.current.getFieldValue("senha");
    //         if (password != undefined && value === password) {
    //         } else {
    //             throw new Error('Something wrong!');
    //         }

    //     } catch (error) {
    //         callback(error)
    //     }

    // }

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.uuid]);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(true);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(true);
            setHasSelectedEdit(true);
        }
    }, [selectedRowKeys]);

    const filterCascade = useCallback((inputValue: any, path: any): any => {
        return path.some((option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }, []);

    const buscaDadosUsuariosContactadosAndSetTable = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlsessionUsuariosCapitados + '/true',
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDados();

    }, []);

    const buscaDadosUsuariosNaoContactadosAndSetTable = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlsessionUsuariosCapitados + '/false',
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDados();

    }, []);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    // celular: "44333333333"
    // cidadeId: 4209102,
    // sigla: PR,
    // cidade: {
    //     nome: NOMEM,
    //     sigla: "sigla"
    // }
    // contatado: false
    // data_registro: "2021-01-26T16:12:51.407Z"
    // device_id: "e565e24614118dcc"
    // estadoId: 42
    // geolocalizacao: "Umuarama, PR, BR"
    // id: 23
    // marca: "xiaomi"
    // modelo: "Redmi Note 7"
    // nome: "Shhehrjfjcn"
    // observacao: null
    // uuid: "f35ea57c-d070-44cb-88d4-a7c697c0a7ac"

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '12%',
            render: (val: any) => {
                return (
                    <>
                        {val ? val : ""}
                    </>
                )
            },
        },
        {
            title: 'Celular',
            dataIndex: 'celular',
            ...getColumnSearchPropsCelular('celular'),
            width: '10%',
            render: (val: any) => {
                const originalValue = val ? unMask(val) : null;
                const maskedValue = originalValue ? mask(originalValue, [
                    "(99) 9999-9999",
                    "(99) 9 9999-9999"
                ]) : null
                return maskedValue;
            }
            // sorter: (a, b) => a.age - b.age //para quando é numero
        },
        {
            title: 'Cidade Selecionada',
            dataIndex: 'cidade',
            // ...getColumnSearchPropsModulo('geolocalizacao'),
            width: '12%',
            render: (val: any) => {

                let siglaa = '';
                if (val.microrregiao.mesorregiao.estado.sigla) {
                    siglaa = val.microrregiao.mesorregiao.estado.sigla
                }
                return (
                    <>
                        {val.nome != undefined ? val.nome + ' - ' + siglaa : ""}
                    </>
                )
            },
        },
        {
            title: 'Contatado',
            dataIndex: 'contatado',
            // ...getColumnSearchProps('codigo'),
            width: '8%',
            render: (val: any) => {
                let color = 'red';
                let texto = 'Não Contatado';
                if (val) {
                    color = 'green'
                    texto = 'Contatado'
                } else {
                    color = 'red'
                    texto = 'Não Contatado'
                }
                return (
                    <span>
                        <Tag style={{ fontSize: 14 }} color={color} key={val}>
                            {texto}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: 'Data',
            dataIndex: 'data_registro',
            ...getColumnSearchPropsData('data_registro'),
            width: '2%',
            render: (data_registro: any) => {
                let data: any = "";
                if (data_registro) {
                    data = convertDataUSParaBR(data_registro);
                }
                return (
                    data
                )
            },
        },
    ];

    const comonentDidMount = useEffect((): void => {
        //buscando e setando na tabela de listagem o parana
        buscaDadosUsuariosNaoContactadosAndSetTable();

    }, [buscaDadosUsuariosNaoContactadosAndSetTable]);

    const onChangeSelectFiltroBuscaTabela = useCallback((e): void => {

        //setando valor do selecione em stado para caso for inativo renderizar outro botao
        setValorSelecione(e);
        switch (e) {
            case 'Contactados':
                buscaDadosUsuariosContactadosAndSetTable();
                break;
            case 'Não Contactados':
                buscaDadosUsuariosNaoContactadosAndSetTable();
                break;

            default:
                buscaDadosUsuariosNaoContactadosAndSetTable();
                break;
        }
    }, []);

    const requestContactarUser = useCallback((): void => {
        setLoadingTable(true);
        const buscaDeDados = async () => {
            // if (selectedRowKeys.length == 1 || selectedRowKeys.length > 1) {
            if (selectedRowKeys.length == 1) {
                let linha: any = [];

                dadosTabelaState.forEach(({ nome, uuid, id, observacao }: any): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (uuid == val) {
                            linha.push({ id: id, contatado: true, observacao: observacao })
                        }
                    });

                })

                try {
                    await axios.put(urlsessionUsuarioCapitado, linha[0], {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    })

                    setLoadingTable(false);
                    buscaDadosUsuariosNaoContactadosAndSetTable();

                    notification.success({
                        message: 'Sucesso',
                        description:
                            `O usuário foi contatato com sucesso!`,
                    });
                } catch (error) {
                    console.log(error)
                    setLoadingTable(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            `Não foi possivel
                         atualizar usuario, entre em contato com suporte!`
                    });
                }
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                // setHasSelectedDel(false);
                setHasSelectedView(false);
                setSelectedRowKeys([]);

            }
        }

        buscaDeDados();
    }, [selectedRowKeys, dadosTabelaState, buscaDadosUsuariosNaoContactadosAndSetTable, buscaDadosUsuariosContactadosAndSetTable]);

    const requestNaoContactarUser = useCallback((): void => {
        setLoadingTable(true);
        const buscaDeDados = async () => {
            // if (selectedRowKeys.length == 1 || selectedRowKeys.length > 1) {
            if (selectedRowKeys.length == 1) {
                let linha: any = [];
                dadosTabelaState.forEach(({ nome, uuid, id, observacao }: any): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (uuid == val) {
                            linha.push({ id: id, contatado: false, observacao: observacao })
                        }
                    });

                })

                try {
                    await axios.put(urlsessionUsuarioCapitado, linha[0], {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    })

                    setLoadingTable(false);
                    buscaDadosUsuariosContactadosAndSetTable();

                    notification.success({
                        message: 'Sucesso',
                        description:
                            `O usuário foi contatato com sucesso!`,
                    });
                } catch (error) {
                    setLoadingTable(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            `Não foi possivel
                         atualizar usuario, entre em contato com suporte!`
                    });
                }
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                // setHasSelectedDel(false);
                setHasSelectedView(false);
                setSelectedRowKeys([]);

            }
        }

        buscaDeDados();
    }, [selectedRowKeys, dadosTabelaState, buscaDadosUsuariosContactadosAndSetTable]);

    return (
        <>
            <Row style={{ marginBottom: 16 }}>
                <Col>
                    {/* <Button type="primary" className="botaoAdicionarAssociacao" disabled={!hasSelectedAdd} onClick={clickBotaoAddAssociacao} >
                        Adicionar
                    </Button> */}
                    <Button className="botaoAAnotarObservacaoContatctar" type="primary" onClick={clickBotaoEditarAssoc} disabled={!hasSelectedView} >
                        Anotar Observações
                    </Button>
                </Col>
                {/* <Col>
                    <Button type="primary" className="botaoEditarAssociacao" onClick={clickBotaoEditarAssoc} disabled={!hasSelectedEdit} >
                        Editar
                    </Button>
                </Col> */}
                <Col>
                    <Select
                        showSearch
                        placeholder="Selecione..."
                        optionFilterProp="children"
                        className="botaoListVendedor"
                        ref={refSelectContatctados}
                        onChange={onChangeSelectFiltroBuscaTabela}
                        disabled={!hasSelectedAdd}
                        filterOption={(input: any, option: any) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Select.Option value="Contactados">Contactados</Select.Option>
                        <Select.Option value="Não Contactados">Não Contactados</Select.Option>
                    </Select>

                    {/* {valorSelecione != 'Contactados' ?
                        <Button className="botaoAtivarUsuario" type="primary" onClick={requestContactarUser}
                            // disabled={!hasSelectedDel}
                            disabled={!hasSelectedEdit}
                        >
                            Contactar
                        </Button>
                        : ""}
                    {valorSelecione == 'Contactados' ?
                        <Button className="botaoDeleteUsuario" type="primary" danger onClick={requestNaoContactarUser}
                            // disabled={!hasSelectedDel}
                            disabled={!hasSelectedEdit}
                        >
                            Descontactar
                        </Button>
                        : ""} */}
                </Col>
            </Row>
            <Row>
                <Col {...cardLayout}>
                    {/* <Title level={3}><MobileOutlined style={{ fontSize: 35, marginRight: 10 }} />Listagem Capitacao de Usuário</Title> */}
                    {/* <Breadcrumb>
                        <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Associações</a></Breadcrumb.Item>
                        <Breadcrumb.Item> {isSalvarOrEditar ? "Adicionar" : "Editar"} Associação</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider /> */}
                </Col>
            </Row>
            <Row>
                <Col {...selectedEstadosInAddAssociacao}>
                    <Table
                        loading={loadingTable}
                        rowKey={(record) => record.uuid}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { onclickTable(record, rowIndex) }, // click row
                            };
                        }}
                        rowSelection={rowSelection}
                        columns={columns} dataSource={dadosTabelaState}
                        scroll={{ x: 900 }}
                        size="small"
                        pagination={{ locale: { items_per_page: '' } }}
                        locale={{
                            emptyText: () => {
                                return <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        <span>
                                            Sem códigos
                                        </span>
                                    }
                                ></Empty>
                            }
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

export default ListCaptacaoDeUsuario;
