import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Table, Input, Button, Space, notification, Row, Col,
    Tag, Select, Typography, Tooltip
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/locale/pt_BR';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { urlOutrosAtendimentosAtendimento } from '../../services/request/urls';
import { convertDataUSParaBR, convertMoedaUSParaBRInicioSemDigitos } from './../../services/Conversores';
import { getToken } from './../../services/token';
import Icon from '@ant-design/icons';
// import { useForm } from 'antd/lib/form/Form';

import { FaSearch } from 'react-icons/fa';
// import { MdCleaningServices } from 'react-icons/md';
import { GiBroom } from 'react-icons/gi';
import {
    removeAcento
} from './../../utils/RemoveAcentos';
import { stateGlobal } from '../../interfaces/Global';

const { Text } = Typography;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>
const IconBroom = (props: any) => <Icon {...props} component={GiBroom} ></Icon>
// const IconBroomMagic = (props: any) => <Icon {...props} component={GiMagicBroom} ></Icon>

interface interfaceListDadosAtendimentos {
    "id": number,
    "nome": string,
    "vendedor": {
        "nome": string
    },
    "poder": {
        "nome": string
    },
    "data": string,
    "data_proximo_contato": string,
    "status": string
}

const ListOutrosAtendimentos: React.FC = () => {

    // const formRef = useRef<any>(null);
    // formRef = React.createRef();
    const propsState = useSelector((state: stateGlobal) => state.global);

    const refConfirmTabelaPoder = useRef<any>(null);
    const refsetSelectedKeysTabelaPoder = useRef<any>(null);
    const refConfirmTabelaVendedor = useRef<any>(null);
    const refsetSelectedKeysTabelaVendedor = useRef<any>(null);
    const refCloneDataTable = useRef<Array<interfaceListDadosAtendimentos>>([]);
    const refDataTableNullProxCont = useRef<Array<interfaceListDadosAtendimentos>>([]);

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const history = useHistory();
    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();
    const signal1 = axios.CancelToken.source();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfaceListDadosAtendimentos>>([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedView, setHasSelectedView] = useState(false);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);

    const [filterStatusTable, setFilterStatusTable] = useState<Array<string>>(() => {

        let dadosLocalStogFiltro: string | null = localStorage.getItem('@GovFacil:FiltroOutrosAtendimentosStatus');
        let localStogFiltro: any = null;
        if (typeof dadosLocalStogFiltro == 'string') {
            localStogFiltro = JSON.parse(dadosLocalStogFiltro);
        }

        if (localStogFiltro) {
            if (localStogFiltro?.status) {
                return localStogFiltro?.status
            } else {
                return []
            }
        } else {
            return []
        }
    });

    const buscaAtendimentoListAll = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlOutrosAtendimentosAtendimento,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal1.token
                    });

                refCloneDataTable.current = result.data
                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingTable(false)
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDados();

    }, []);

    const buscaAtendimentoListPorOrgao = useCallback((orgao): void => {

        setLoadingTable(true)
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlOutrosAtendimentosAtendimento + '/' + orgao,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal1.token
                    });


                refCloneDataTable.current = result.data
                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingTable(false)
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDados();

    }, []);

    //executa apenas ao iniciar o component
    useEffect(() => {

        let dadosLocalStogFiltro: string | null = localStorage.getItem('@GovFacil:FiltroOutrosAtendimentos');
        let localStogFiltro: any = null;
        if (typeof dadosLocalStogFiltro == 'string') {
            localStogFiltro = JSON.parse(dadosLocalStogFiltro);
        }

        if (localStogFiltro) {
            if (localStogFiltro?.vendedorText) {
                refsetSelectedKeysTabelaVendedor.current([localStogFiltro?.vendedorText])

                refConfirmTabelaVendedor.current();
                setSearchText(localStogFiltro?.vendedorText);
                setSearchedColumn('vendedor');
            }
        }

        // console.log('refConfirmTabela.current')
        // refsetSelectedKeysTabelaPoder.current(['exe'])

        // refConfirmTabelaPoder.current();
        // setSearchText('exe');
        // setSearchedColumn('poder');

        // setTimeout(() => {


        // }, 200);


        buscaAtendimentoListAll();
    }, []);

    const onChangeSelectFiltroBuscaTabela = useCallback((e): void => {

        //setando valor do selecione em stado para caso for inativo renderizar outro botao
        // setValorSelecione(e);
        switch (e) {
            case 'cidade':
                buscaAtendimentoListPorOrgao('cidade');
                break;
            case 'associacao':
                buscaAtendimentoListPorOrgao('associacao');
                break;

            default:
                buscaAtendimentoListAll();
                break;
        }
    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
        signal1.cancel("Requisicao cancelada!")
    }, []);

    //Search da tabela de listagem

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);

        if (dataIndex === 'vendedor') {
            localStorage.setItem('@GovFacil:FiltroOutrosAtendimentos', JSON.stringify({
                vendedorText: selectedKeys[0]
            }));
        }
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let trecord = removeAcento(record[dataIndex].toString());
                return record[dataIndex] ? trecord.includes(removeAcento(value)) : false
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn, handleSearch, handleReset]);

    const getColumnSearchPropsObjNome = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {

                if (dataIndex === 'poder') {
                    refConfirmTabelaPoder.current = confirm
                    refsetSelectedKeysTabelaPoder.current = setSelectedKeys
                }
                if (dataIndex === 'vendedor') {
                    refConfirmTabelaVendedor.current = confirm
                    refsetSelectedKeysTabelaVendedor.current = setSelectedKeys
                }
                return (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={node => {
                                searchInput = node;
                            }}
                            placeholder={`Digite...`}
                            value={selectedKeys[0]}
                            onChange={e => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                            }}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Pesquisar
                            </Button>
                            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                                Limpar
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let trecord = removeAcento(record[dataIndex].nome.toString());
                return record[dataIndex]?.nome ? trecord.includes(removeAcento(value)) : false
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) => {
                return (
                    searchedColumn === dataIndex ? (
                        <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[searchText]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ''}
                        />
                    ) : (
                        text
                    )
                )
            }
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsObjCidadePopulacao = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {

                if (dataIndex === 'poder') {
                    refConfirmTabelaPoder.current = confirm
                    refsetSelectedKeysTabelaPoder.current = setSelectedKeys
                }
                if (dataIndex === 'vendedor') {
                    refConfirmTabelaVendedor.current = confirm
                    refsetSelectedKeysTabelaVendedor.current = setSelectedKeys
                }
                return (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={node => {
                                searchInput = node;
                            }}
                            placeholder={`Digite...`}
                            value={selectedKeys[0]}
                            onChange={e => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                            }}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Pesquisar
                            </Button>
                            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                                Limpar
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let trecord = removeAcento(record[dataIndex]?.populacao.toString());
                let trecordComPonto = convertMoedaUSParaBRInicioSemDigitos(removeAcento(record[dataIndex]?.populacao.toString()));

                let retornar = false
                if (record[dataIndex]?.populacao ? trecord.includes(removeAcento(value)) : false) {
                    retornar = true
                } else if (record[dataIndex]?.populacao ? trecordComPonto.includes(removeAcento(value)) : false) {
                    retornar = true
                }

                return retornar
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) => {
                return (
                    searchedColumn === dataIndex ? (
                        <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[searchText]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ''}
                        />
                    ) : (
                        text
                    )
                )
            }
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsData = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let texto: string | undefined = ''
                if (record[dataIndex]) texto = moment(record[dataIndex]).format('DD/MM/YYYY HH:mm')

                return (
                    texto ? texto.toString().toLowerCase().includes(value.toLowerCase()) : false
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    // fim do search da tabela de listagem


    //abrir modal
    const navigateAdicionar = useCallback((): void => {
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:processosJuridicos');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:processosJuridicos');
        }

        history.push("/outros-atendimentos/adicionar");
    }, []);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.id.toString())) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.id.toString()
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.id.toString()]);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
            } else {
                let teste = selectedRowKeys.concat(record.id.toString());
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
            }
        }
    }, [selectedRowKeys]);

    //abrir navegar editar
    const clickBotaoEditar = useCallback((): void => {

        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {

                let objSelecionado: any = {}
                dadosTabelaState.forEach((dados): void => {
                    let convertId: string = dados.id.toString();
                    if (convertId == selectedRowKeys[0]) {
                        objSelecionado = dados
                    }
                })

                try {

                    localStorage.setItem('@GovFacil:processosJuridicos', JSON.stringify(objSelecionado));

                    history.push("/outros-atendimentos/editar/" + objSelecionado.id);
                } catch (error) {
                    let msgErro: any = (error as Error);
                    notification.error({
                        message: 'Erro',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Preencha os campos obrigatorios!',
                    });
                }

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(true);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(true);
            setHasSelectedEdit(true);
        }
    }, [selectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns: any = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            width: '13%',
        },
        {
            title: 'Produto',
            dataIndex: 'produtoAtendimento',
            ...getColumnSearchPropsObjNome('produtoAtendimento'),
            width: '4%',
            render: (produto: any) => {

                return produto.nome
            },
        },
        {
            title: 'População',
            dataIndex: 'cidade',
            ...getColumnSearchPropsObjCidadePopulacao('cidade'),
            width: '1%',
            sorter: (a: any, b: any) => {
                let aPopul: any = null
                let bPopulacao: any = null
                if (a.cidade?.populacao) {
                    aPopul = a.cidade?.populacao
                } else {
                    aPopul = 0
                }
                if (b.cidade?.populacao) {
                    bPopulacao = b.cidade?.populacao
                } else {
                    bPopulacao = 0
                }

                return (
                    bPopulacao - aPopul
                )
            },
            showSorterTooltip: false,
            render: (cidade: any) => (
                convertMoedaUSParaBRInicioSemDigitos(cidade?.populacao.toString())
            ),
        },
        {
            title: 'Poder',
            dataIndex: 'poder',
            ...getColumnSearchPropsObjNome('poder'),
            width: '4%',
            render: (poder: any) => {

                let str = ''
                if (poder?.nome === 'Executivo') {
                    str = 'Exec'
                }

                if (poder?.nome === 'Legislativo') {
                    str = 'Legis'
                }

                return str
            },
        },
        {
            title: 'Vendedor',
            dataIndex: 'vendedor',
            ...getColumnSearchPropsObjNome('vendedor'),
            width: '10%',
            sorter: (a: any, b: any) => {
                return (
                    a.vendedor.nome.localeCompare(b.vendedor.nome)
                )
            },
            showSorterTooltip: false,
            render: (vendedor: any) => (
                vendedor?.nome
            ),
        },
        {
            title: 'Último contato',
            dataIndex: 'data',
            ...getColumnSearchPropsData('data'),
            width: '11%',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.data) {
                    aMonMen = moment(a.data).unix()
                } else {
                    aMonMen = 0
                }
                if (b.data) {
                    bMonMen = moment(b.data).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            render: (criado: any) => (
                criado ?
                    moment(criado).format('DD/MM/YYYY HH:mm')
                    : ''
            ),
        },
        {
            title: 'Próximo contato',
            dataIndex: 'data_proximo_contato',
            ...getColumnSearchPropsData('data_proximo_contato'),
            width: '11%',
            showSorterTooltip: false,
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.data_proximo_contato) {
                    aMonMen = moment(a.data_proximo_contato).unix()
                } else {
                    aMonMen = 0
                }
                if (b.data_proximo_contato) {
                    bMonMen = moment(b.data_proximo_contato).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            render: (criado: any, objCompleto: any) => {

                // console.log(objCompleto.importante)
                return (
                    <Text
                        // type={objCompleto.importante ? 'danger' : undefined}

                        style={{
                            color: objCompleto.importante ? '#EB3C54' : undefined
                        }}
                        strong={objCompleto.importante}
                    >
                        {
                            criado ?
                                moment(criado).format('DD/MM/YYYY HH:mm')
                                : ''
                        }
                    </Text>

                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            // ...getColumnSearchProps('status'),
            width: '4%',
            filters: [
                {
                    text: 'A Finalizar',
                    value: 'A Finalizar',
                },
                {
                    text: 'Efetivado',
                    value: 'Efetivado',
                },
                {
                    text: 'Em Contato',
                    value: 'Em Contato',
                },
                {
                    text: 'Sem Retorno',
                    value: 'Sem Retorno',
                },
                {
                    text: 'Processo em andamento',
                    value: 'Processo em andamento',
                },
                {
                    text: 'Sem interesse',
                    value: 'Sem interesse',
                },
                {
                    text: 'Apresentado',
                    value: 'Apresentado',
                },
                {
                    text: 'Agendado',
                    value: 'Agendado',
                },
            ],
            defaultFilteredValue: filterStatusTable,
            onFilter: (value: any, record: any) => {
                return record.status.indexOf(value) === 0;
            },
            // filterDropdown: (props: any) => {
            //     console.log(props)
            // },
            // filterSearch: true,
            render: (val: any) => {
                let cor = '';
                switch (val) {
                    case "Sem Retorno":
                        cor = '#f00';
                        break;
                    case "Efetivado":
                        cor = '#17751b';
                        break;
                    case "Sem interesse":
                        cor = '#930';
                        break;
                    case "Processo em andamento":
                        cor = '#999';
                        break;
                    case "A Finalizar":
                        // cor = '#096DD9';
                        cor = '#2db7f5';
                        break;
                    case "Em Contato":
                        cor = '#7931ec';
                        break;
                    case "Agendado":
                        cor = '#7bd280';
                        break;
                    case "Apresentado":
                        cor = '#0751a0';
                        break;
                    // case "Em teste":
                    //     cor = 'purple';
                    //     break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag color={cor} key={val}>
                            {val}
                        </Tag>
                    </span>
                )
            },
        },
    ];


    const onClickCleanProxContTable = useCallback((): void => {

        const buscaDeDados = async () => {

            try {

                let objTableNullProx: Array<interfaceListDadosAtendimentos> = [];
                let objTable: Array<interfaceListDadosAtendimentos> = [];

                if (refDataTableNullProxCont.current.length === 0) {
                    dadosTabelaState.forEach((element, index) => {
                        if (
                            element.data_proximo_contato === undefined ||
                            element.data_proximo_contato === null ||
                            element.data_proximo_contato === ''
                        ) {

                            objTableNullProx.push(element)

                        } else {
                            objTable.push(element)
                        }
                    })

                    refDataTableNullProxCont.current = objTableNullProx
                    setDadosTabelaState(objTable)


                } else {
                    refDataTableNullProxCont.current = []
                    setDadosTabelaState(refCloneDataTable.current)

                }



            } catch (error) {
                let msgErro: any = (error as Error);

            }


        }

        buscaDeDados();

    }, [dadosTabelaState]);

    return (
        <>

            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    // background: propsState.theme === "light" ? '#fff' : '#141414',
                    //
                    // width: '80%',
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Row style={{ marginBottom: 0 }}>
                    <Col>
                        <Button type="primary" className="botaoAdicionarProcessoJuridico" disabled={!hasSelectedAdd} onClick={navigateAdicionar} >
                            Adicionar
                        </Button>
                        {/* <Button className="botaoViewProcessoJuridico" type="primary" onClick={start} disabled={!hasSelectedView} >
                        Visualizar
                    </Button> */}
                        <Button type="primary" className="botaoEditarProcessoJuridico" onClick={clickBotaoEditar} disabled={!hasSelectedEdit} >
                            Editar
                        </Button>
                    </Col>
                    <Col>
                        <Select
                            allowClear
                            showSearch
                            placeholder="Selecione..."
                            optionFilterProp="children"
                            className="botaoListVendedor"
                            onChange={onChangeSelectFiltroBuscaTabela}
                            disabled={!hasSelectedAdd}
                            filterOption={(input: any, option: any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value="cidade">Cidade</Select.Option>
                            <Select.Option value="associacao">Associação</Select.Option>
                        </Select>

                        {/* {valorSelecione == 'Inativos' ?
                        <Button className="botaoAtivarUsuario" type="primary" onClick={onClickAtivarUser}
                            // disabled={!hasSelectedDel}
                            disabled={!hasSelectedEdit}
                        >
                            Ativar
                        </Button>
                        : ""}
                    {valorSelecione != 'Inativos' ?
                        <Button className="botaoDeleteUsuario" type="primary" danger onClick={onClickInativarUser}
                            // disabled={!hasSelectedDel}
                            disabled={!hasSelectedEdit}
                        >
                            Inativar
                        </Button>
                        : ""} */}
                    </Col>
                </Row>
            </div>
            <Table
                style={{
                    marginTop: 50
                }}
                className='marginTopTables'
                rowKey={(record) => record.id.toString()}
                loading={loadingTable}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onclickTable(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { }, // mouse leave row
                    };
                }}
                rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 1005 }}
                size="small"
                onChange={
                    (pagination, filters, sorter, extra) => {

                        refContagemDoFiltro.current = extra.currentDataSource;

                        localStorage.setItem('@GovFacil:FiltroOutrosAtendimentosStatus', JSON.stringify({
                            status: filters.status,
                        }));
                    }
                }

                title={(registros) => {
                    return (
                        <div
                            style={{
                                backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                </Text>
                                {
                                    refDataTableNullProxCont.current.length === 0 ?
                                        <Tooltip title="Remover brancos" color="blue" key="LimparBlueAtendimento">
                                            <Button
                                                type="primary"
                                                // className="botaoAdicionarProcessoJuridico"
                                                // disabled={!hasSelectedAdd}
                                                style={{
                                                    fontSize: 20,
                                                    paddingTop: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 4,
                                                    paddingRight: 4,
                                                }}
                                                onClick={onClickCleanProxContTable}
                                            >
                                                <IconBroom />
                                            </Button>
                                        </Tooltip>
                                        :
                                        <Tooltip title="Add brancos" color="blue" key="AddBlueAtendimento">
                                            <Button
                                                type="primary"
                                                // className="botaoAdicionarProcessoJuridico"
                                                // disabled={!hasSelectedAdd}
                                                style={{
                                                    fontSize: 20,
                                                    paddingTop: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 4,
                                                    paddingRight: 4,
                                                }}
                                                onClick={onClickCleanProxContTable}
                                            >
                                                <IconBroom />
                                            </Button>
                                        </Tooltip>
                                }


                            </div>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
        </>
    );
}

export default ListOutrosAtendimentos;
