import { useCallback, useEffect, useRef, useState } from "react";
import {
    convertMoedaBRDoJSComExtamente2CasasDecimais
} from "../../../../../../../services/Conversores";
import { addMonths, isAfter, isBefore, subMonths } from "date-fns";
import { Button, notification, Row, Tooltip, Typography } from "antd";

import { useHistory } from "react-router-dom";
import { useGetColumnSearchProps } from "../../../../../../../hooks/FilterTable/stringCLearFiltros";
import { useGetColumnSearchPropsData } from "../../../../../../../hooks/FilterTable/date";
import moment from "moment";
import axios from "axios";
import { getToken } from "../../../../../../../services/token";
import { urlAcessosContrato, urlContratoAtivos, urlContratoCidadesQuantidadeUsuarios, urlContratoUsuarios, urlDadosAcessosContratoUsuarioCidade } from "../../../../../../../services/request/urls";
import Icon from "@ant-design/icons";
import { FaPlus } from "react-icons/fa";

const IconMais = (props: any) => { return <Icon {...props} component={FaPlus} ></Icon> }

const { Text } = Typography

interface IReqContrato {
    id: number;
    uuid: string;
    nome: string;
    status: boolean;
    envio_proposta: string;
    vigencia: string | number | null | Date | boolean | any;
    valor_contratado: string;
    cadastro_processos: string;
    observacao: string;
    created_at: string;
    updated_at: string;
    modalidade: {
        id: number;
        nome: string;
    },
    esfera: {
        id: number;
        nome: string;
    },
    poder: {
        id: number;
        nome: string;
    },
    situacao_contrato: {
        id: number;
        nome: string;
    },
    tipo_contrato: {
        id: number;
        nome: string;
    },
    controladorContrato: [
        {
            id: number | null;
            contratoId: number | null;
            cidadeId: number | null;
            estadoId: number | null;
            usuarioId: number | null;
            permissaoNotificacaoId: number | null;
        }
    ]
}

interface IReqUsuariosDoContrato {
    "id": number,
    "uuid": string,
    "sistema": string,
    "ativo": boolean,
    "nome": string,
    "celular": string,
    "email": string | null,
    "vendedor": boolean,
    "avatar": string | null,
    "cargo": {
        "id": number,
        "nome": string
    },
    "created_at": string | null,
    "updated_at": string | null
}

interface IReqCidadesDoContrato {
    "id": number,
    "uuid": string,
    "ibge_id_antigo": number,
    "nome": string,
    "latitude": string,
    "longitude": string,
    "populacao": number,
    "microrregiaoId": number,
    "uf": string,
    "estadoId": number,
    "estadoNome": string,
    "estadoSigla": string,
    "usuarios": string
}

interface IData {
    "_id": string,
    "ultimoAcesso": string,
    "usuario": string | null,
    "cargo": string | null,
    "cidadeNome": string | null,
    "uf": string | null,
    "modulo": string | null,
    "subModulo": string | null,
    "idIbge": number,
    "cargoId": number,
    "contrato": string | null,
    "marca": string | null,
    "modelo": string | null,
    "so": string | null,
    "versao": string | null,
    "usuarioId": number,
    "contratoId": number
}

interface IDataModulosPorUsuario {
    "_id": string,
    "usuario": string,
    "telefone": string | null,
    "cargo": string | null,
    "cargoId": number | null,
    "contrato": string | null,
    "contratoId": number | null,
    "marca": string | null,
    "modelo": string | null,
    "so": string | null,
    "versao": string | null,
    "acessos_modulos": Array<
        {
            "cidadeNome": string | null,
            "idIbge": number | null,
            "dataEHora": string,
            "modulo": string | null,
            "subModulo": string | null,
        }
    >,
    "totalAcessos": number | null,
}

interface IProps {
    setLoadingScreen: (val: boolean) => void
    activeKeyTabs: string
    refFiltersGlobal: any
}

export const useFiltrosAndTable = (
    {
        refFiltersGlobal,
        activeKeyTabs,
        setLoadingScreen
    }: IProps
) => {

    const history = useHistory();

    const refFuncaoLimparFiltrosString = useRef<any>(null);

    const signal = axios.CancelToken.source();

    const [loadingTableModulos, setLoadingTableModulos] = useState(false);
    const [visibleModalModulosPorUsuario, setVisibleModalModulosPorUsuario] = useState(false);
    const [dadosTabelaModalModulosPorUsuario, setDadosTabelaModalModulosPorUsuario] = useState<Array<IDataModulosPorUsuario>>([]);

    const refFilterDataInicio = useRef<any>();
    const refFilterDataFim = useRef<any>();
    const [filterDataInicio, setFilterDataInicio] = useState<any>();
    const [filterDataFim, setFilterDataFim] = useState<any>();


    const [valueTipoId, setValueTipoId] = useState<number>();
    const refTipoId = useRef<any>();


    const refContratoId = useRef<number>();
    const [valueContratoId, setValueContratoId] = useState<number>();
    const [dataListContrato, setDataListContrato] = useState<Array<IReqContrato>>([]);
    const refAllDadosContrato = useRef<Array<IReqContrato>>([]);

    const refCidadesDoContratoId = useRef<number>();
    const [valueCidadesDoContratoId, setValueCidadesDoContratoId] = useState<number>();
    const [dataListCidadesDoContrato, setDataListCidadesDoContrato] = useState<Array<IReqCidadesDoContrato>>([]);
    // const refAllDadosCidadesDoContrato = useRef<Array<IReqContrato>>([]);

    const refUsuariosDoContratoId = useRef<Array<number>>([]);
    const [valueUsuariosDoContratoId, setValueUsuariosDoContratoId] = useState<Array<number>>([]);
    const [dataListUsuariosDoContrato, setDataListUsuariosDoContrato] = useState<Array<IReqUsuariosDoContrato>>([]);
    // const refAllDadosUsuariosDoContrato = useRef<Array<IReqContrato>>([]);

    const refContagemDoFiltro = useRef<Array<any>>([]);
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<IData>>([]);

    useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
    }, []);

    //setando dados das cidades no selected
    const requestFiltroUltAcessTable = useCallback(() => {

        const requestAsync = async () => {
            try {
                setLoadingScreen(true);

                let { data } = await axios.get(urlAcessosContrato(refContratoId.current),
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token,
                        params: {
                            idIbge: refCidadesDoContratoId.current ? refCidadesDoContratoId.current : undefined,
                            excluirUsuarios: refUsuariosDoContratoId.current?.length > 0 ? refUsuariosDoContratoId.current.join(',') : undefined,
                            // inicio: refFilterDataInicio.current ? refFilterDataInicio.current : undefined,
                            // fim: refFilterDataFim.current ? refFilterDataFim.current : undefined,
                        }
                    }
                );

                setDadosTabelaState(data);

                setLoadingScreen(false);

            } catch (error) {
                setLoadingScreen(false);
                let msgErro: any = (error as Error);

                setLoadingScreen(false);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });

            }
        }

        requestAsync();

    }, []);


    const buscaDadosUsuariosPorContrato = useCallback((id: string | number) => {

        const requestAsync = async () => {
            try {

                // setLoadingTable(true)

                let result = await axios.get(urlContratoUsuarios + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    }
                );

                setDataListUsuariosDoContrato(result.data);

                // if (result.data.length === 1) {

                //     setValueUsuariosDoContratoId([result.data[0].id]);
                //     refUsuariosDoContratoId.current = [result.data[0].id];

                // } else {
                setValueUsuariosDoContratoId([]);
                refUsuariosDoContratoId.current = [];
                // }

                // setLoadingTable(false)

                return true

            } catch (error) {
                // setLoadingTable(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
                return false
            }
        }

        return requestAsync();

    }, []);

    const buscaDadosCidadesPorContrato = useCallback((id: string | number) => {

        const buscaDeDadosContratos = async () => {
            try {

                // setLoadingTable(true)

                let result = await axios.get(urlContratoCidadesQuantidadeUsuarios + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    }
                );

                setDataListCidadesDoContrato(result.data);

                if (result.data.length === 1) {

                    setValueCidadesDoContratoId(result.data[0].id);
                    refCidadesDoContratoId.current = result.data[0].id;

                } else {
                    setValueCidadesDoContratoId(undefined);
                    refCidadesDoContratoId.current = undefined;
                }

                // setLoadingTable(false)

                return true

            } catch (error) {
                // setLoadingTable(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
                return false
            }
        }

        return buscaDeDadosContratos();

    }, []);

    const buscaDadosContratosAndSetSelected = useCallback((): void => {

        const buscaDeDadosContratos = async () => {
            try {

                // setLoadingTable(true)
                let result = await axios.get(urlContratoAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                refAllDadosContrato.current = result.data

                setDataListContrato(result.data);
                // setLoadingTable(false)


            } catch (error) {

                // setLoadingTable(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);


    useEffect(() => {

        //set filtros ao iniciar
        const dataNow = moment()

        setFilterDataInicio(dataNow)
        refFilterDataInicio.current = dataNow

        if (!refFilterDataFim.current) {
            let dataInicio = moment(dataNow).toDate();

            const dataFim = addMonths(dataInicio, 1)

            setFilterDataFim(moment(dataFim))
            refFilterDataFim.current = dataFim

        }

        // requestFiltroUltAcessTable();

        buscaDadosContratosAndSetSelected();


    }, []);


    const onChangeSelectDataInicio = useCallback((value: any): void => {

        if (value) {
            // validar se a data inicial não poder maior que a data final
            const resultado = isAfter(value!, refFilterDataFim.current!)

            if (resultado) {
                notification.error({
                    message: 'Data inválida.',
                    description:
                        'A data inicial não pode ser posterior à data final.',
                });
                return
            }

            setFilterDataInicio(value)
            refFilterDataInicio.current = value

            if (!refFilterDataFim.current) {
                let dataInicio = moment(value).toDate();

                const dataFim = addMonths(dataInicio, 1)

                setFilterDataFim(moment(dataFim))
                refFilterDataFim.current = dataFim

            }

            requestFiltroUltAcessTable()


            return
        }

        setFilterDataFim(undefined)
        setFilterDataInicio(undefined)
        refFilterDataFim.current = undefined
        refFilterDataInicio.current = undefined

        requestFiltroUltAcessTable()

        return

    }, []);


    const onChangeSelectDataFim = useCallback((value: any): void => {

        if (value) {
            // validar se a data inicial não poder maior que a data final
            const resultado = isBefore(value!, refFilterDataInicio.current!)

            if (resultado) {
                notification.error({
                    message: 'Data inválida.',
                    description:
                        'A data final não pode ser anterior à data inicial.',
                });
                return
            }

            setFilterDataFim(value)
            refFilterDataFim.current = value

            if (!refFilterDataInicio.current) {
                let dataFim = moment(value).toDate();

                const dataInicio = subMonths(dataFim, 1)

                setFilterDataInicio(moment(dataInicio))
                refFilterDataInicio.current = dataInicio

            }

            requestFiltroUltAcessTable()

            return
        }

        setFilterDataFim(undefined)
        setFilterDataInicio(undefined)
        refFilterDataFim.current = undefined
        refFilterDataInicio.current = undefined

        requestFiltroUltAcessTable()


        return

    }, []);

    const handleCancelModalModulosPorUsuario = useCallback((): void => {
        setLoadingTableModulos(false)
        setVisibleModalModulosPorUsuario(false)
        setDadosTabelaModalModulosPorUsuario([])
    }, []);

    const handleBtnModulosPorUsuario = useCallback((record: IData): void => {

        const requestAsync = async () => {
            try {

                if (!record?.contratoId) {
                    notification.error({
                        message: 'Erro',
                        description:
                            "O atributo 'Contrato' é obrigatório para acessar esta funcionalidade.",
                    });
                    return;
                }

                if (!record?.usuarioId) {
                    notification.error({
                        message: 'Erro',
                        description:
                            "O atributo 'Usuário' é obrigatório para acessar esta funcionalidade.",
                    });
                    return;
                }

                if (!record?.idIbge) {
                    notification.error({
                        message: 'Erro',
                        description:
                            "O atributo 'Cidade' é obrigatório para acessar esta funcionalidade.",
                    });
                    return;
                }

                if (!record?.ultimoAcesso) {
                    notification.error({
                        message: 'Erro',
                        description:
                            "O atributo 'Último Acesso' é obrigatório para acessar esta funcionalidade.",
                    });
                    return;
                }

                setLoadingTableModulos(true)
                setVisibleModalModulosPorUsuario(true)
                let { data } = await axios.get(urlDadosAcessosContratoUsuarioCidade(
                    record.contratoId,
                    record.usuarioId,
                    record.idIbge,
                ),
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        params: {
                            dia: moment(record.ultimoAcesso).format('YYYY-MM-DD').toString()
                        }
                    }
                );

                setDadosTabelaModalModulosPorUsuario(data);
                setLoadingTableModulos(false)

                // refAllDadosContrato.current = result.data

            } catch (error) {

                handleCancelModalModulosPorUsuario()

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        requestAsync();

    }, []);


    const onChangeSelectTipo = useCallback((value: any): void => {

        refTipoId.current = value
        setValueTipoId(value)

        requestFiltroUltAcessTable()

    }, []);


    const onChangeSelectContratos = useCallback(async (value, obj) => {

        setValueContratoId(value)
        refContratoId.current = value

        await buscaDadosCidadesPorContrato(value)
        await buscaDadosUsuariosPorContrato(value)

        requestFiltroUltAcessTable();

    }, []);

    const onChangeSelectCidadesDoContratos = useCallback((value, obj): void => {

        setValueCidadesDoContratoId(value)
        refCidadesDoContratoId.current = value

        requestFiltroUltAcessTable();

    }, []);

    const onChangeSelectUsuariosDoContratos = useCallback((value, obj): void => {

        setValueUsuariosDoContratoId(value)
        refUsuariosDoContratoId.current = value

        requestFiltroUltAcessTable();

    }, []);

    const onRefreshPage = useCallback((): void => {
        requestFiltroUltAcessTable();
    }, []);


    const columns = [
        {
            title: 'Município',
            dataIndex: 'cidadeNome',
            ...useGetColumnSearchProps('cidadeNome', refFuncaoLimparFiltrosString),
            width: '10%',
            sorter: (a: any, b: any) => {
                return a?.cidadeNome?.localeCompare(b?.cidadeNome)
            },
            showSorterTooltip: false,
            render: (nome: any, record: IData) => {

                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            record?.uf ?
                                nome + ' - ' + record?.uf
                                :
                                nome
                        }
                    </div>
                )
            }
        },
        {
            title: 'Usuário',
            dataIndex: 'usuario',
            ...useGetColumnSearchProps('usuario', refFuncaoLimparFiltrosString),
            width: '8%',
            sorter: (a: any, b: any) => {
                return a?.usuario?.localeCompare(b?.usuario)
            },
            showSorterTooltip: false,
            render: (usuario: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            usuario
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            ...useGetColumnSearchProps('cargo', refFuncaoLimparFiltrosString),
            width: '8%',
            sorter: (a: any, b: any) => {
                return a?.cargo?.localeCompare(b?.cargo)
            },
            showSorterTooltip: false,
            render: (cargo: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            cargo
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Último Acesso',
            dataIndex: 'ultimoAcesso',
            ...useGetColumnSearchPropsData('ultimoAcesso'),
            width: '3%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.ultimoAcesso) {
                    aMonMen = moment(a.ultimoAcesso).unix()
                } else {
                    aMonMen = 0
                }
                if (b.ultimoAcesso) {
                    bMonMen = moment(b.ultimoAcesso).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.ultimoAcesso?.localeCompare(b.ultimoAcesso),
            render: (data: any) => {

                let stringData: string = ''
                if (data) {
                    stringData = moment(data).format('DD/MM/YYYY HH:mm').toString()
                }
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                stringData
                            }
                        </Text>
                    </div>
                )
            },
        },
        {
            title: 'Ações',
            dataIndex: '_id',
            // ...getColumnSearchProps('created_at'),
            width: '1%',
            className: 'rowAcoes_UltimoAcesso_lcbqweuiwegcv',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (id: any, record: IData) => (
                <>
                    <Row>
                        <Tooltip title="Módulos" color="blue">
                            <Button
                                type="primary"
                                onClick={
                                    () => handleBtnModulosPorUsuario(record)
                                }
                                className="btnIconMoreModal_hjebwgfvouab"
                            >
                                <IconMais />
                            </Button>
                        </Tooltip>
                    </Row>
                </>
            ),
        },
    ];

    const columnsModalModulosPorUsuario: any = [
        {
            title: 'Município',
            dataIndex: 'cidadeNome',
            ...useGetColumnSearchProps('cidadeNome', refFuncaoLimparFiltrosString),
            width: '10%',
            sorter: (a: any, b: any) => {
                return a.cidadeNome?.localeCompare(b.cidadeNome)
            },
            showSorterTooltip: false,
            render: (nome: any, record: IData) => {

                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            record?.uf ?
                                nome + ' - ' + record?.uf
                                :
                                nome
                        }
                    </div>
                )
            }
        },
        {
            title: 'Módulo',
            dataIndex: 'modulo',
            ...useGetColumnSearchProps('modulo', refFuncaoLimparFiltrosString),
            width: '8%',
            sorter: (a: any, b: any) => {
                return a.modulo?.localeCompare(b.modulo)
            },
            showSorterTooltip: false,
            render: (modulo: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            modulo
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Sub Módulo',
            dataIndex: 'subModulo',
            ...useGetColumnSearchProps('subModulo', refFuncaoLimparFiltrosString),
            width: '8%',
            sorter: (a: any, b: any) => {
                return a.subModulo?.localeCompare(b.subModulo)
            },
            showSorterTooltip: false,
            render: (subModulo: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            subModulo
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Data',
            dataIndex: 'dataEHora',
            ...useGetColumnSearchPropsData('dataEHora'),
            width: '6%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.dataEHora) {
                    aMonMen = moment(a.dataEHora).unix()
                } else {
                    aMonMen = 0
                }
                if (b.dataEHora) {
                    bMonMen = moment(b.dataEHora).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.dataEHora?.localeCompare(b.dataEHora),
            render: (data: any) => {

                let stringData: string = ''
                if (data) {
                    stringData = moment(data).format('DD/MM/YYYY HH:mm').toString()
                }
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                stringData
                            }
                        </Text>
                    </div>
                )
            },
        }
    ];

    return {
        onChangeSelectDataInicio,
        onChangeSelectDataFim,
        filterDataInicio,
        filterDataFim,
        valueTipoId,
        onChangeSelectTipo,
        dadosTabelaState,
        columns,
        refContagemDoFiltro,

        valueContratoId,
        dataListContrato,
        onChangeSelectContratos,

        valueCidadesDoContratoId,
        dataListCidadesDoContrato,
        onChangeSelectCidadesDoContratos,

        valueUsuariosDoContratoId,
        dataListUsuariosDoContrato,
        onChangeSelectUsuariosDoContratos,

        visibleModalModulosPorUsuario,
        handleCancelModalModulosPorUsuario,
        loadingTableModulos,
        columnsModalModulosPorUsuario,
        dadosTabelaModalModulosPorUsuario,
        onRefreshPage,
    }
}
