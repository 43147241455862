import React, { useState, useEffect } from 'react';
import RouteAuth from './auth';
import RouteDashboard from './dashboard';
import { useSelector } from 'react-redux';
import { LoginState } from './../interfaces';

// import AuthLayout from '~/pages/_layouts/auth'
// import DefaultLayout from '~/pages/_layouts/default'

// import { store } from '~/store'

const RouteVerify: React.FC = () => {

    const propsState = useSelector((state: LoginState) => state.login);

    const [estaLogado, setestaLogado] = useState(false);

    useEffect((): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:token');
            // let localUserData: any = null;
            if (typeof dadosLocalStorage == 'string') {
                // localUserData = JSON.parse(dadosLocalStorage);
                // console.log(localUserData.token)
                setestaLogado(true)
            } else {
                setestaLogado(propsState.signed)
            }

    }, [propsState]);

    //pegando os states redux auth e desestruturando para state signed
    // const { signed } = store.getState().auth//saber se o user esta logado
    // const signed = true

    //se ele nao estiver logado e a rota privada vai para raiz
    if (!estaLogado) {
        return <RouteAuth />
    } else {
        return <RouteDashboard />
    }

    //se ele estiver logdo e a rota nao for privada
    // if (estaLogado) {
    //     return <RouteDashboard />
    // }

    //see ele esta logado e esta tentando acessar uma rota privada
    // const Layout = signed ? DefaultLayout : AuthLayout   //se ele estiver logado e um layout se nao e outro layout

    // return (
    //     <RouteDashboard />
    // )
}

export default RouteVerify;
