import { Button, Col, Layout, Modal, Row, Select, Spin, Table, Tooltip, Typography } from "antd";
import { useFiltrosAndTable } from "./useFiltrosAndTable";
import locale from 'antd/es/locale/pt_BR';
import { removeAcento, removeAcentoTelefone } from "../../../../../../../utils/RemoveAcentos";
import { forwardRef } from "react";

// @ts-ignore
import ImagemLogo from './../../../../../../../assets/logo.png';
import { RedoOutlined } from "@ant-design/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import { formatNumberTelefone } from "../../../../../../../services/Conversores";

const { Text, Title } = Typography

interface IProps {
    activeKeyTabs: string
    clickNavigateTab: (aba: string) => void
    refFiltersGlobal: any
    setLoadingScreen: (val: boolean) => void
    loadingScreen: boolean
}

const FiltrosAndTable = forwardRef((
    {
        activeKeyTabs,
        clickNavigateTab,
        refFiltersGlobal,
        setLoadingScreen,
        loadingScreen
    }: IProps,
    ref?: React.ForwardedRef<any>
) => {

    const {
        onChangeSelectDataInicio,
        onChangeSelectDataFim,
        filterDataInicio,
        filterDataFim,
        dadosTabelaState,
        columns,
        refContagemDoFiltro,

        valueUsuarioId,
        dataListUsuarios,
        onChangeSelectUsuarios,

        visibleModalModulosPorUsuario,
        handleCancelModalModulosPorUsuario,
        loadingTableModulos,
        columnsModalModulosPorUsuario,
        dadosTabelaModalModulosPorUsuario,
        onRefreshPage,
        loadingSelectedUsuarios

    } = useFiltrosAndTable({ refFiltersGlobal, activeKeyTabs, setLoadingScreen })

    return (
        <div
            ref={ref}
            style={{
                margin: 0,
                padding: 0
            }}
            className="layout_UltimoAcesso_cvrehjvfqw"
        >
            <Layout
                style={{
                    borderRadius: 8,
                }}
                className="layoutVGrey_UltimoAcesso_lvbweqrafw"
            >
                <Row>
                    <Layout
                        className="layout_UltimoAcesso_cvrehjvfqw"
                    >
                        <div
                            className="divImgGovFacilRel_UltimoAcesso_fewkhgfvqof"
                        >
                            <img
                                className="imgRelUltimoAcesso_fewhljbv"
                                style={{
                                    width: '200px'
                                }}
                                src={ImagemLogo}
                            />
                        </div>
                    </Layout>
                </Row>
                <Row>
                    <Col span={24}>
                        <Layout

                            className="layout_UltimoAcesso_cvrehjvfqw layoutStyledSelectandFilterUltimoAcesso_cwrejhfvbwaou"
                        >
                            <Row
                                style={{
                                    alignItems: 'center'
                                }}
                            >
                                <Col
                                    style={{
                                        margin: 5,
                                        marginLeft: 10,
                                        marginRight: 10
                                    }}
                                >
                                    <Title
                                        style={{
                                            margin: 0
                                        }}
                                        level={5}
                                        type="secondary"
                                    >
                                        {"Filtros: "}
                                    </Title>
                                </Col>
                                <Row>
                                    <Col
                                        className="colSelectRelUltimosAcessos_dewqhfvqoowef"
                                    >
                                        <div
                                            className='divColOpAnaliseFilters_chjbvchlebvqwrewvbosd'
                                        >
                                            <div
                                                className="titleSelectRelUltimosAcessos_cewlfhjcvbqweofbv"
                                            >
                                                <Text>{'Usuário:'}</Text>
                                            </div>
                                            <div
                                                className="selectRelUltimoAcess_vwqeralhvjbwaw"
                                            >
                                                <Select
                                                    showSearch
                                                    loading={loadingSelectedUsuarios}
                                                    notFoundContent={loadingSelectedUsuarios ? <Spin size="small" /> : null}
                                                    placeholder="Selecione um usuário"
                                                    optionFilterProp="children"
                                                    onChange={onChangeSelectUsuarios}
                                                    value={valueUsuarioId}
                                                    style={{
                                                        width: '100%',
                                                        margin: 0,
                                                        marginBottom: 2,
                                                        marginRight: 5,
                                                    }}
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcentoTelefone(input)
                                                        let listCidade = removeAcentoTelefone(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}

                                                >
                                                    {
                                                        dataListUsuarios.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {
                                                                        item?.celular ?
                                                                            item.nome + ' - ' + formatNumberTelefone(item?.celular)
                                                                            :
                                                                            item.nome
                                                                    }
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        className="colSelectRelUltimosAcessos_dewqhfvqoowef"
                                    >
                                        <div
                                            className='divColOpAnaliseFilters_chjbvchlebvqwrewvbosd'
                                        >
                                            <div
                                                className="titleSelectRelAcessosUsuario_cewlfhjcvbqweofbv"
                                            >
                                                <Text >{'Data de início:'}</Text>
                                            </div>
                                            <div
                                                className="selectRelUltimoAcess_vwqeralhvjbwaw"
                                            >
                                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                                    <DatePicker
                                                        value={filterDataInicio}
                                                        onChange={onChangeSelectDataInicio}
                                                        required={false}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        okLabel="Ok"
                                                        // clearLabel="Limpar"
                                                        cancelLabel="Cancelar"
                                                        // clearable
                                                        format="dd/MM/yyyy"
                                                        className="datePickerCOlors"

                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        className="colSelectRelUltimosAcessos_dewqhfvqoowef"
                                    >
                                        <div
                                            className='divColOpAnaliseFilters_chjbvchlebvqwrewvbosd'
                                        // style={{ margin: 2 }}
                                        >
                                            <div
                                                className="titleSelectRelAcessosUsuario_cewlfhjcvbqweofbv"
                                            >
                                                <Text >{'Data de fim:'}</Text>
                                            </div>
                                            <div
                                                className="selectRelUltimoAcess_vwqeralhvjbwaw"
                                            >
                                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                                    <DatePicker
                                                        value={filterDataFim}
                                                        onChange={onChangeSelectDataFim}
                                                        required={false}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        okLabel="Ok"
                                                        // clearLabel="Limpar"
                                                        cancelLabel="Cancelar"
                                                        // clearable
                                                        format="dd/MM/yyyy"
                                                        className="datePickerCOlors"

                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </Row>
                        </Layout>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Layout
                            style={{
                                borderRadius: 8,
                                margin: 5,
                                padding: 5
                            }}
                            className="layout_UltimoAcesso_cvrehjvfqw"
                        >
                            <Table
                                style={{
                                    // marginTop: 50
                                }}
                                className='tableRelUltimoAcesso_fwelhvfweoaufyv'
                                rowKey={(record) => record.data}
                                columns={columns}
                                dataSource={dadosTabelaState}
                                scroll={{ x: 950 }}
                                onChange={
                                    (pagination, filters, sorter, extra) => {
                                        refContagemDoFiltro.current = extra.currentDataSource
                                    }
                                }
                                title={(registros) => {

                                    return (
                                        <div
                                            style={{
                                                padding: 10,
                                                margin: -8,
                                                marginBottom: 0.5,
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                            className="divTable_UltimoAcesso_awywfgbowreag"
                                        >
                                            <Text>
                                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                                    dadosTabelaState.length :
                                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                            </Text>
                                            {
                                                valueUsuarioId ?
                                                    <div className="divRemoverDoRelatorio_dewhfvqwuoweg">
                                                        <Tooltip title="Atualizar página" color="blue">
                                                            <Button
                                                                type="primary"
                                                                style={{
                                                                    fontSize: 20
                                                                }}
                                                                onClick={onRefreshPage}
                                                                icon={<RedoOutlined style={{ fontSize: 20 }} />}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    )
                                }}
                                footer={(registros) => {
                                    return (
                                        <div
                                            style={{
                                                textAlign: 'end'
                                            }}
                                        >
                                            <Text>
                                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                                    dadosTabelaState.length :
                                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                            </Text>
                                        </div>
                                    )
                                }}
                                size="small"
                                pagination={{
                                    locale: { items_per_page: '' },
                                    showSizeChanger: false,

                                    // defaultPageSize: 100,
                                    pageSize: (dadosTabelaState.length + 10),

                                    // position: ['topRight', 'bottomRight'],
                                    position: [],
                                    // pageSizeOptions: ['5', '10', '20', '50', '100', '200', '1000']
                                }}
                                locale={locale.Table}
                            />
                        </Layout>
                    </Col>
                </Row>
            </Layout>
            <Modal
                title="Módulos por usuário"
                visible={visibleModalModulosPorUsuario}
                className="modalRelModulosPorUsuarios"
                onOk={handleCancelModalModulosPorUsuario}
                onCancel={handleCancelModalModulosPorUsuario}
                footer={[
                    <Button key="back" disabled={loadingTableModulos} onClick={handleCancelModalModulosPorUsuario}>
                        Fechar
                    </Button>
                ]}
                destroyOnClose
            >
                <div
                    className='layoutVGrey_UltimoAcesso_lvbweqrafw'
                    style={{
                        borderRadius: 8,
                        padding: 5
                    }}
                >
                    <div
                        className='layout_UltimoAcesso_cvrehjvfqw'
                        style={{
                            borderRadius: 8,
                            padding: 5,
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Text
                            style={{
                                marginRight: 10
                            }}
                        >
                            {'Contrato: '}
                            <Text strong>{
                                dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario?.contrato ?
                                    dadosTabelaModalModulosPorUsuario.contrato
                                    :
                                    ""
                            }</Text>
                        </Text>
                        <Text
                            style={{
                                marginRight: 10
                            }}
                        >
                            {'Usuário: '}
                            <Text strong>{
                                dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario?.usuario ?
                                    dadosTabelaModalModulosPorUsuario.usuario + ' - ' + dadosTabelaModalModulosPorUsuario?.cargo
                                    :
                                    ""
                            }</Text>
                        </Text>
                        <Text
                            style={{
                                marginRight: 10
                            }}
                        >
                            {'Versão do app: '}
                            <Text strong>{
                                dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario?.versao ?
                                    dadosTabelaModalModulosPorUsuario.versao
                                    :
                                    ""
                            }</Text>
                        </Text>
                    </div>
                </div>
                <Table
                    rowKey={(record) => record.dataEHora}
                    loading={loadingTableModulos}
                    columns={columnsModalModulosPorUsuario}
                    dataSource={dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario.acessos_modulos ? dadosTabelaModalModulosPorUsuario.acessos_modulos : []}
                    scroll={{ y: 400, x: 550 }}
                    size="small"
                    title={(registros) => {
                        return (
                            <div
                                style={{
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5
                                }}
                                className="divTable_UltimoAcesso_awywfgbowreag"
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${dadosTabelaModalModulosPorUsuario?.acessos_modulos.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    footer={(registros) => {
                        return (
                            <div
                                style={{
                                    textAlign: 'end'
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${dadosTabelaModalModulosPorUsuario?.acessos_modulos.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                        pageSize: dadosTabelaModalModulosPorUsuario && dadosTabelaModalModulosPorUsuario?.acessos_modulos.length > 0 && dadosTabelaModalModulosPorUsuario?.acessos_modulos ?
                            (dadosTabelaModalModulosPorUsuario?.acessos_modulos.length + 10) : (10),
                        position: [],
                        // pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    locale={locale.Table}
                />
            </Modal>
        </div>
    )
})

export default FiltrosAndTable;
