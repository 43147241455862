import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Table, Input, Button, notification, Space, Modal, Select, Tag, Row, Col,
    Typography, Tooltip
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import locale from 'antd/es/locale/pt_BR';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import './style.css';
import {
    urlContrato, urlContratoVendedores, urlContratoEstados,
    urlContratoAtivos, urlContratoInativos, urlContratoUsuariosVinculados, urlContratosUsuarioControlador
} from '../../services/request/urls';
import axios from 'axios';
import { getToken } from './../../services/token';
import { convertDataUSParaBR } from './../../services/Conversores';
// import { useForm } from 'antd/lib/form/Form';

import {
    removeAcento
} from './../../utils/RemoveAcentos';

import { FaSearch, FaUserTimes, FaUsers, FaLayerGroup } from 'react-icons/fa';
import { BiLayerPlus } from 'react-icons/bi';

import { stateGlobal } from '../../interfaces/Global';
import { validacaoPermissaoPorCargo } from '../../services/VerificaSePodeAcessar/verificacaoporCargo';
import { mask, unMask } from '../../utils/MascaraDeCampos';

const { Text } = Typography;

interface interfDadosTabela {
    id: number;
    uuid: string;
    nome: string;
    status: boolean;
    envio_proposta: string;
    vigencia: string | number | null | Date | boolean | any;
    valor_contratado: string;
    cadastro_processos: string;
    observacao: string;
    created_at: string;
    updated_at: string;
    modalidade: {
        id: number;
        nome: string;
    },
    esfera: {
        id: number;
        nome: string;
    },
    poder: {
        id: number;
        nome: string;
    },
    situacao_contrato: {
        id: number;
        nome: string;
    },
    tipo_contrato: {
        id: number;
        nome: string;
    },
    controladorContrato: [
        {
            id: number | null;
            contratoId: number | null;
            cidadeId: number | null;
            estadoId: number | null;
            usuarioId: number | null;
            permissaoNotificacaoId: number | null;
        }
    ]
}

interface dadosUsuariosTabelaModal {
    id: number,
    nome: string,
    status_vinculo: boolean
}

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>
const IconDesvincularUser = (props: any) => <Icon {...props} component={FaUserTimes} ></Icon>
const IconUsuarios = (props: any) => <Icon {...props} component={FaUsers} ></Icon>
const IconBiLayerPlus = (props: any) => <Icon {...props} component={BiLayerPlus} ></Icon>

const ListagemContrato: React.FC = () => {

    const history = useHistory();
    const propsState = useSelector((state: stateGlobal) => state.global);

    const refIdContratoSelecionado = useRef<number | null>(null);
    const refDadosUsuarioModal = useRef<any>(null);

    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refContagemDoFiltroTabelaModal = React.useRef<Array<any>>([]);

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfDadosTabela>>([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedView, setHasSelectedView] = useState(false);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [hasSelectedDel, setHasSelectedDel] = useState(false);
    const [valorSelecione, setValorSelecione] = useState('Contratos');

    const [modalDesvincularUserVisible, setModalDesvincularUserVisible] = useState(false);
    const [loadingTableModal, setLoadingTableModal] = useState(false);
    const [selectedRowKeysModal, setSelectedRowKeysModal] = useState<Array<string>>([]);
    const [searchTextModal, setSearchTextModal] = useState('');
    const [searchedColumnModal, setSearchedColumnModal] = useState('');
    const [dadosTabelaUsuariosModal, setDadosTabelaUsuariosModal] = useState<Array<dadosUsuariosTabelaModal>>([]);

    //executa apenas ao iniciar o component
    useEffect(() => {
        setLoadingTable(true)
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlContratoAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDados();

    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
    }, []);

    const buscaDadosContratosAtivosAndSetTable = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDadosContratos = async () => {
            try {
                let result = await axios.get(urlContratoAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    const buscaDadosContratosInativosAndSetTable = useCallback((): void => {

        setLoadingTable(true)
        const buscaDeDadosContratos = async () => {
            try {
                let result = await axios.get(urlContratoInativos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setDadosTabelaState(result.data);
                setLoadingTable(false)

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingTable(false)
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    const handleSearchModal = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchTextModal(selectedKeys[0]);
        setSearchedColumnModal(dataIndex);
    }, [searchText]);

    const handleResetModal = useCallback((clearFilters): void => {
        clearFilters();
        setSearchTextModal('');
    }, []);

    //Search da tabela de listagem
    const getColumnSearchPropsModal = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearchModal(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearchModal(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleResetModal(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) =>
                record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumnModal === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchTextModal, searchedColumnModal]);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters): void => {
        clearFilters();
        setSearchText('');
    }, []);

    //Search da tabela de listagem
    const getColumnSearchProps = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {

                let trecord = removeAcento(record[dataIndex].toString());

                return record[dataIndex] ? trecord.includes(removeAcento(value)) : false

            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchProps2 = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {
                let trecord = removeAcento(record[dataIndex]?.nome.toString());

                return record[dataIndex]?.nome ? trecord.includes(removeAcento(value)) : false
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchProps2Arr = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any) => {

                let trecord = removeAcento(record[dataIndex][0]?.nome.toString());

                return record[dataIndex][0]?.nome ? trecord.includes(removeAcento(value)) : false

            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);

    const getColumnSearchPropsData = useCallback((dataIndex) => {
        let searchInput: any = '';
        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
            onFilter: (value: any, record: any): any => {
                let texto: string | undefined = ''
                if (record[dataIndex]) texto = convertDataUSParaBR(record[dataIndex])

                return (
                    texto ? texto.toString().toLowerCase().includes(value.toLowerCase()) : false
                )
            },
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },
            render: (text: any) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        })
    }, [searchText, searchedColumn]);
    // fim do search da tabela de listagem

    const start = useCallback((): void => {

    }, []);

    //abrir navegar em add contrato
    const clickBotaoAddContrato = useCallback((): void => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:contrato');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@GovFacil:contrato');
        }
        history.push('/contrato/adicionar');

    }, []);

    //abrir navegar em add contrato
    const clickBotaoEditarContrato = useCallback((): void => {

        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {

                setLoadingTableModal(true)
                let objSelecionado: any = {}
                dadosTabelaState.forEach((dados): void => {
                    if (dados.uuid == selectedRowKeys[0]) {
                        objSelecionado = dados
                    }
                })

                try {
                    let resultContratos = await axios.get(urlContrato + '/' + objSelecionado?.id,
                        { headers: { 'Authorization': 'Bearer ' + getToken() } });


                    // let resultVendedores = await axios.get(urlContratoVendedores + '/' + objSelecionado?.id,
                    //     { headers: { 'Authorization': 'Bearer ' + getToken() } });

                    // objSelecionado.vendedores = resultVendedores.data;

                    // let resultEstados = await axios.get(urlContratoEstados + '/' + objSelecionado?.id,
                    //     { headers: { 'Authorization': 'Bearer ' + getToken() } });

                    // objSelecionado.estados = resultEstados.data;
                    // tipo_contrato:

                    // console.log(resultCidades.data)
                    // objSelecionado.cidades = resultCidades.data;

                    const newObjContrato = {
                        ...resultContratos.data,
                        plano: objSelecionado.plano
                    }

                    localStorage.setItem('@GovFacil:contrato', JSON.stringify(newObjContrato));

                    history.push('/contrato/adicionar');
                } catch (error) {

                    let msgErro: any = (error as Error);
                    notification.error({
                        message: 'Erro',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }

            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    const onChangeSelectFiltroBuscaTabela = useCallback((e): void => {

        //setando valor do selecione em stado para caso for inativo renderizar outro botao
        setValorSelecione(e);
        switch (e) {
            case 'Inativos':
                buscaDadosContratosInativosAndSetTable();
                break;
            case 'Contratos':
                buscaDadosContratosAtivosAndSetTable();
                break;

            default:
                buscaDadosContratosAtivosAndSetTable();
                break;
        }
    }, []);

    const requestAtivarOuInativarContrato = useCallback((obj): void => {
        setLoadingTable(true);
        const buscaDeDados = async () => {
            // if (selectedRowKeys.length == 1 || selectedRowKeys.length > 1) {
            if (selectedRowKeys.length == 1) {
                let linha: any = [];
                dadosTabelaState.forEach(({ nome, uuid, id }): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (uuid == val) {
                            linha.push({ id })
                        }
                    });

                })

                try {
                    await axios.patch(urlContrato + "/" + linha[0].id, obj, {
                        //EXEMPLO APENAS PARA EXCLUIR UM [0] ATE CORRIGIR O BACK
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    })

                    setLoadingTable(false);
                    if (obj.status) {
                        buscaDadosContratosInativosAndSetTable();
                    } else {
                        buscaDadosContratosAtivosAndSetTable();
                    }

                    notification.success({
                        message: 'Sucesso',
                        description:
                            `O contrato foi ${obj.status ? 'ativado' : 'inativado'} com sucesso!`,
                    });
                } catch (error) {
                    setLoadingTable(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            `Não foi possivel
                        ${obj.status ? 'ativar' : 'inativar'}
                         o contrato, entre em contato com suporte!`
                    });
                }
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setHasSelectedView(false);
                setSelectedRowKeys([]);

            }
        }

        buscaDeDados();
    }, [selectedRowKeys, dadosTabelaState, buscaDadosContratosAtivosAndSetTable, buscaDadosContratosInativosAndSetTable]);

    const onClickInativarContrato = useCallback((): void => {
        Modal.confirm({
            className: 'modalContratoAtivoInativo',
            title: 'Cuidado',
            icon: <ExclamationCircleOutlined />,
            content: 'Os usuários deste contrato perderão todo o acesso ao sistema! Tem certeza que deseja continuar?',
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                requestAtivarOuInativarContrato({ status: false });
            },
            onCancel() {
            },
        });
    }, [requestAtivarOuInativarContrato]);

    const onClickAtivarContrato = useCallback((): void => {
        Modal.confirm({
            className: 'modalContratoAtivoInativo',
            title: 'Cuidado',
            icon: <ExclamationCircleOutlined />,
            content: 'Os usuários deste contrato terão acesso novamente ao sistema! Tem certeza que deseja continuar?',
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                requestAtivarOuInativarContrato({ status: true });
            },
            onCancel() {
            },
        });
    }, [requestAtivarOuInativarContrato]);

    const onClickDesvincularUser = useCallback((): void => {
        setLoadingTableModal(true);
        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {
                let linha: any = [];
                dadosTabelaState.forEach(({ nome, uuid, id }): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (uuid == val) {
                            linha.push({ id })
                        }
                    });

                })

                try {

                    //abrir modal antes da requisição
                    setModalDesvincularUserVisible(true);

                    let resultUsuarios = await axios.get(urlContratoUsuariosVinculados + "/" + linha[0].id, {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                    let userAtivoCheck: any = [];
                    resultUsuarios.data.forEach((valorRequest: dadosUsuariosTabelaModal): void => {

                        if (valorRequest.status_vinculo) {
                            userAtivoCheck.push(valorRequest.id.toString());
                        }

                    });

                    //setandop check na tabela
                    setSelectedRowKeysModal(userAtivoCheck);

                    setDadosTabelaUsuariosModal(resultUsuarios.data);

                    refIdContratoSelecionado.current = linha[0].id;
                    refDadosUsuarioModal.current = resultUsuarios.data;

                    setLoadingTableModal(false);

                } catch (error) {
                    setLoadingTableModal(false);
                    notification.error({
                        message: 'Erro',
                        description: `Não foi possivel buscar, entre em contato com suporte!`
                    });
                }
            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    const onClickListarUsuariosDoContrato = useCallback((): void => {
        setLoadingTableModal(true);
        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {
                let linha: any = [];
                dadosTabelaState.forEach(({ nome, uuid, id }): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (uuid == val) {
                            linha.push({ id, nome })
                        }
                    });

                })

                try {

                    localStorage.setItem('@GovFacil:ListUsersContrato', JSON.stringify(linha[0]));

                    history.push(`/usuarios`);

                } catch (error) {
                    setLoadingTableModal(false);
                    notification.error({
                        message: 'Erro',
                        description: `Não foi possivel buscar, entre em contato com suporte!`
                    });
                }
            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    const onClickPgModuloDoContrato = useCallback((): void => {
        setLoadingTableModal(true);
        const buscaDeDados = async () => {

            if (selectedRowKeys.length == 1) {
                let linha: any = [];
                dadosTabelaState.forEach(({ nome, uuid, id }): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (uuid == val) {
                            linha.push({ id, nome })
                        }
                    });

                })

                try {

                    // localStorage.setItem('@GovFacil:ModuloDoContrato', JSON.stringify(linha[0]));

                    history.push(`/contrato/modulo/${linha[0].id}`);

                } catch (error) {
                    setLoadingTableModal(false);
                    notification.error({
                        message: 'Erro',
                        description: `Não foi possivel buscar, entre em contato com suporte!`
                    });
                }
            }
        }

        buscaDeDados();

    }, [selectedRowKeys, dadosTabelaState]);

    const clickSubmitModalAddConfig = useCallback((): void => {

        const alterarDadosUsuario = async () => {

            setLoadingTableModal(true);

            let objSalvarStatus: any = [];
            let isBooleanSelecionado: boolean = false;

            refDadosUsuarioModal.current.forEach((valorRequest: dadosUsuariosTabelaModal): void => {
                let objStatus: any = {};
                isBooleanSelecionado = false;

                objStatus.usuarioId = valorRequest.id
                objStatus.contratoId = refIdContratoSelecionado.current

                selectedRowKeysModal.forEach((element) => {
                    if (element == valorRequest.id.toString()) {
                        isBooleanSelecionado = true;
                    }
                });

                if (isBooleanSelecionado) {
                    objStatus.ativo = true;
                } else {
                    objStatus.ativo = false;
                }

                objSalvarStatus.push(objStatus);

            });

            try {
                let resultUsuarios = await axios.patch(urlContratosUsuarioControlador, objSalvarStatus, {
                    headers: { 'Authorization': 'Bearer ' + getToken() }
                });

                notification.success({
                    message: 'Sucesso',
                    description: `Alterações feitas com sucesso!`
                });

                setModalDesvincularUserVisible(false);
                setLoadingTableModal(false);
            } catch (error) {
                setLoadingTableModal(false);
                setLoadingTableModal(false);
                notification.error({
                    message: 'Erro',
                    description: `Não foi possivel salvar, entre em contato com suporte!`
                });
            }

        }

        alterarDadosUsuario();

    }, [selectedRowKeysModal]);

    const clickCancelModalAddConfig = useCallback((): void => {
        setModalDesvincularUserVisible(false);
        setSelectedRowKeysModal([]);
    }, []);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.uuid)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.uuid
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.uuid]);
                setHasSelectedAdd(false);
                setHasSelectedView(true);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else {
                let teste = selectedRowKeys.concat(record.uuid);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedView(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(true);
            }
        }
    }, [selectedRowKeys]);

    // click na linha da tabela de listagem de usuario
    const onclickTableModal = useCallback((record: any, row: any): void => {
        if (selectedRowKeysModal.includes(record.id.toString())) {
            let filter = selectedRowKeysModal.filter((val) => {
                return val != record.id.toString()
            })
            if (filter.length === 1) {
                setSelectedRowKeysModal(filter);
            } else if (filter.length === 0) {
                setSelectedRowKeysModal(filter);
            } else {
                setSelectedRowKeysModal(filter);
            }

        } else {
            if (selectedRowKeysModal.length == 0) {
                setSelectedRowKeysModal([record.id.toString()]);
            } else {
                let teste = selectedRowKeysModal.concat(record.id.toString());
                setSelectedRowKeysModal(teste);
            }
        }
    }, [selectedRowKeysModal, setSelectedRowKeysModal]);

    //checkbox da tabela de listagem
    const onSelectChangeModal = useCallback((selectedRowKeysModal): void => {
        if (selectedRowKeysModal.length > 1) {
            setSelectedRowKeysModal(selectedRowKeysModal);
        } else if (selectedRowKeysModal.length === 0) {
            setSelectedRowKeysModal(selectedRowKeysModal);
        } else {
            setSelectedRowKeysModal(selectedRowKeysModal);
        }
    }, [selectedRowKeysModal]);

    const rowSelectionModal = {
        selectedRowKeys: selectedRowKeysModal,
        onChange: onSelectChangeModal,
        columnWidth: '1%'
    };

    const columnsModal = [
        {
            title: 'Nome do Usuario',
            dataIndex: 'nome',
            ...getColumnSearchPropsModal('nome'),
            width: '15%',
        },
        {
            title: 'Celular',
            dataIndex: 'celular',
            ...getColumnSearchProps('celular'),
            width: '9%',
            render: (celular: any) => {
                let formatedCell: string | null = null
                if (celular) {

                    const originalValue = unMask(celular);
                    const maskedValue = mask(originalValue, [
                        "(99) 9999-9999",
                        "(99) 9 9999-9999"
                    ]);
                    formatedCell = maskedValue;
                }
                return formatedCell
            }
        },
    ];

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((selectedRowKeys): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(true);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(true);
            setHasSelectedView(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedAdd(false);
            setHasSelectedView(true);
            setHasSelectedEdit(true);
            setHasSelectedDel(true);
        }
    }, [selectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Nome do contrato',
            dataIndex: 'nome',
            ...getColumnSearchProps('nome'),
            width: '15%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Vendedor',
            dataIndex: 'vendedores',
            ...getColumnSearchProps2Arr('vendedores'),
            width: '6%',
            sorter: (a: any, b: any) => {
                return (
                    a.vendedores[0].nome.localeCompare(b.vendedores[0].nome)
                )
            },
            showSorterTooltip: false,
            render: (val: any) => {
                return (
                    val[0]?.nome
                    // <span>
                    //     <Tag color={''} key={val?.id}>
                    //         {}
                    //     </Tag>
                    // </span>
                )
            }
        },
        {
            title: 'Poder',
            dataIndex: 'poder',
            ...getColumnSearchProps2('poder'),
            width: '6%',
            render: (val: any) => val?.nome,
            showSorterTooltip: false,
        },
        {
            title: 'Tipo de contrato',
            dataIndex: 'tipo_contrato',
            // ...getColumnSearchProps2('tipo_contrato'),
            filters: [
                {
                    text: 'Período de Teste',
                    value: 'Período de Teste',
                },
                {
                    text: 'Govfácil',
                    value: 'Govfácil',
                },
                {
                    text: 'Parceiros',
                    value: 'Parceiros',
                },
                {
                    text: 'Cliente',
                    value: 'Cliente',
                },
                {
                    text: 'Automático',
                    value: 'Automático',
                },
            ],
            onFilter: (value: any, record: any) => {
                return record.tipo_contrato?.nome?.indexOf(value) === 0
                // return true
            },
            width: '6%',
            render: (val: any) => {
                let cor = '';
                switch (val?.nome) {
                    case 'Cliente':
                        cor = 'green';
                        break;
                    case "Período de Teste":
                        cor = 'gold';
                        break;
                    case "Parceiros":
                        cor = 'red';
                        break;
                    case "Govfácil":
                        cor = 'blue';
                        break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag color={cor} key={val?.id}>
                            {val?.nome}
                        </Tag>
                    </span>
                )
            },

        },
        {
            title: 'Situação',
            dataIndex: 'situacao_contrato',
            // ...getColumnSearchProps2('situacao_contrato'),
            width: '8%',
            filters: [
                {
                    text: 'Aguardando Emissão NF',
                    value: 'Aguardando Emissão NF',
                },
                {
                    text: 'Aguardando Pagamento',
                    value: 'Aguardando Pagamento',
                },
                {
                    text: 'Pago',
                    value: 'Pago',
                },
                {
                    text: 'Encerrado',
                    value: 'Encerrado',
                },
                {
                    text: 'Cancelado',
                    value: 'Cancelado',
                },
                {
                    text: 'Proposta Enviada',
                    value: 'Proposta Enviada',
                },
                {
                    text: 'Período de Teste',
                    value: 'Período de Teste',
                },
                {
                    text: 'Processo em Andamento',
                    value: 'Processo em Andamento',
                },
            ],
            onFilter: (value: any, record: any) => {
                return record.situacao_contrato?.nome?.indexOf(value) === 0
            },
            render: (val: any) => {
                let cor = '';
                switch (val?.nome) {
                    case 'Pago':
                        cor = 'green';
                        break;
                    case "Proposta Enviada":
                        cor = 'gold';
                        break;
                    case "Aguardando Emissão NF":
                        cor = 'blue';
                        break;
                    case "Aguardando Pagamento":
                        cor = 'purple';
                        break;
                    case "Cancelado":
                        cor = 'red';
                        break;
                    case "Encerrado":
                        cor = 'red';
                        break;
                    case "Período de Teste":
                        cor = 'volcano';
                        break;

                    default:
                        cor = ''
                        break;
                }
                return (
                    <span>
                        <Tag color={cor} key={val?.id}>
                            {val?.nome}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: 'Validade de Teste',
            dataIndex: 'validade_teste',
            ...getColumnSearchPropsData('validade_teste'),
            width: '5%',
            showSorterTooltip: false,
            sorter: (a: any, b: any): any => {

                let aMonMen: number = 0
                let bMonMen: number = 0
                if (a?.validade_teste) {
                    aMonMen = moment(a.validade_teste).unix()
                } else {
                    aMonMen = 0
                }
                if (b?.validade_teste) {
                    bMonMen = moment(b.validade_teste).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            render: (validade_teste: any): any => {
                let data: any = null;
                if (validade_teste) {
                    data = moment(validade_teste).format('DD/MM/YYYY')
                }
                return (
                    data
                )
            },
        },
        {
            title: 'Vigencia Atual',
            dataIndex: 'vigencia_atual',
            ...getColumnSearchPropsData('vigencia_atual'),
            width: '5%',
            showSorterTooltip: false,
            sorter: (a: any, b: any): any => {

                let aMonMen: number = 0
                let bMonMen: number = 0
                if (a?.vigencia_atual) {
                    aMonMen = moment(a.vigencia_atual).unix()
                } else {
                    aMonMen = 0
                }
                if (b?.vigencia_atual) {
                    bMonMen = moment(b.vigencia_atual).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            render: (vigencia_atual: any): any => {
                let data: any = null;
                if (vigencia_atual) {
                    data = moment(vigencia_atual).format('DD/MM/YYYY')
                }
                return (
                    data
                )
            },
        },
        // {
        //     title: 'Criado',
        //     dataIndex: 'created_at',
        //     ...getColumnSearchPropsData('created_at'),
        //     width: '2%',
        //     sorter: (a: any, b: any) => {

        //         let aMonMen: any = null
        //         let bMonMen: any = null
        //         if (a.created_at) {
        //             aMonMen = moment(a.created_at).unix()
        //         } else {
        //             aMonMen = 0
        //         }
        //         if (b.created_at) {
        //             bMonMen = moment(b.created_at).unix()
        //         } else {
        //             bMonMen = 0
        //         }

        //         return aMonMen - bMonMen
        //     },
        //     showSorterTooltip: false,
        //     // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
        //     render: (criado: any) => (
        //         moment(criado).format('DD/MM/YYYY HH:mm')
        //     ),
        // }
    ];

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    // background: propsState.theme === "light" ? '#fff' : '#141414',
                    //
                    // width: '80%',
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24,
                }}
            >
                <Row style={{ marginBottom: 16 }}>
                    <Col
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Button type="primary" className="botaoAdicionarContrato" disabled={!hasSelectedAdd} onClick={clickBotaoAddContrato} >
                            Adicionar
                        </Button>
                        {/* <Button className="botaoViewContrato" type="primary" onClick={start} disabled={!hasSelectedView} >
                        Visualizar
                    </Button> */}
                        <Button type="primary" className="botaoEditarContrato" onClick={clickBotaoEditarContrato} disabled={!hasSelectedEdit} >
                            Editar
                        </Button>
                    </Col>
                    <Col
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Select
                            showSearch
                            placeholder="Selecione..."
                            optionFilterProp="children"
                            className="botaoListSelectContrato"
                            onChange={onChangeSelectFiltroBuscaTabela}
                            disabled={!hasSelectedAdd}
                            defaultValue='Contratos'
                            filterOption={(input: any, option: any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value="Contratos">Ativos</Select.Option>
                            <Select.Option value="Inativos">Inativos</Select.Option>
                        </Select>

                        {valorSelecione == 'Inativos' ?
                            <Button className="botaoAtivarContrato" type="primary" onClick={onClickAtivarContrato}
                                // disabled={!hasSelectedDel}
                                disabled={!hasSelectedEdit}
                            >
                                Ativar
                            </Button>
                            : ""}
                        {valorSelecione != 'Inativos' ?
                            <Button
                                className="botaoDeleteContrato"
                                type="primary"
                                danger
                                onClick={onClickInativarContrato}
                                // disabled={!hasSelectedDel}
                                disabled={!hasSelectedEdit}
                            >
                                Inativar
                            </Button>
                            : ""}
                        {
                            validacaoPermissaoPorCargo(['Desenvolvedor(a)']) ?
                                <>
                                    {!hasSelectedEdit ?
                                        <Button
                                            type="primary"
                                            onClick={onClickDesvincularUser}
                                            key='fixoDesvincularsuario'
                                            disabled={!hasSelectedEdit}
                                            className="botaoDesvincularUsuario"
                                        >
                                            <IconDesvincularUser style={{ fontSize: 20 }} />
                                        </Button>
                                        :
                                        <Tooltip title="Desvincular Usuários" color="blue" key="bluedesvincular">
                                            <Button
                                                type="primary"
                                                key='tootiplDesvincularsuario'
                                                onClick={onClickDesvincularUser}
                                                disabled={!hasSelectedEdit}
                                                className="botaoDesvincularUsuario"
                                            >
                                                <IconDesvincularUser style={{ fontSize: 20 }} />
                                            </Button>
                                        </Tooltip>
                                    }
                                </>
                                :
                                <></>
                        }

                        {!hasSelectedEdit ?
                            <Button
                                type="primary"
                                // onClick={onClickDesvincularUser}
                                disabled={!hasSelectedEdit}
                                className="botaoListarUsuarioContrato"
                                key='fixoListarUsuario'
                                style={{
                                    marginRight: 10
                                }}
                            >
                                <IconUsuarios style={{ fontSize: 20 }} />
                            </Button>
                            :
                            <Tooltip title="Listar Usuários" color="blue" key="blueListarUsuarios">
                                <Button
                                    type="primary"
                                    style={{
                                        marginRight: 10
                                    }}
                                    key='tooltipListarUsuario'
                                    onClick={onClickListarUsuariosDoContrato}
                                    disabled={!hasSelectedEdit}
                                    className="botaoListarUsuarioContrato"
                                >
                                    <IconUsuarios style={{ fontSize: 22 }} />
                                </Button>
                            </Tooltip>
                        }
                        {!hasSelectedEdit ?
                            <Button
                                type="primary"
                                className="botaoVerde_Contrato_dxwsjhvdcqwiytfc"
                                key='fixoCadastrarModulo'
                                // onClick={onClickDesvincularUser}
                                disabled={!hasSelectedEdit}
                            // className="botaoListarUsuarioContrato"
                            >
                                Entidade
                                {/* <IconBiLayerPlus style={{ fontSize: 20 }} /> */}
                            </Button>
                            :
                            <Button
                                type="primary"
                                key='TolltipCadastrarModulo'
                                onClick={onClickPgModuloDoContrato}
                                disabled={!hasSelectedEdit}
                                className="botaoVerde_Contrato_dxwsjhvdcqwiytfc"
                            >
                                Entidade
                            </Button>
                        }
                    </Col>
                </Row>
            </div>
            <Table
                style={{
                    marginTop: 50
                }}
                className='marginTopTables'
                rowKey={(record) => record.uuid}
                loading={loadingTable}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onclickTable(record, rowIndex) }, // click row
                    };
                }}
                rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 1100 }}
                size="small"
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                title={(registros) => {
                    return (
                        <div
                            style={{
                                backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
            <Modal
                title="Desvincular Usuários"
                visible={modalDesvincularUserVisible}
                className="modalAddAssociacao"
                onOk={clickSubmitModalAddConfig}
                onCancel={clickCancelModalAddConfig}
                footer={[
                    <Button key="back" disabled={loadingTableModal}
                        onClick={clickCancelModalAddConfig}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingTableModal}
                        onClick={clickSubmitModalAddConfig}>
                        Salvar
                    </Button>,
                ]}
            >
                <Table
                    rowKey={(record) => record.id.toString()}
                    loading={loadingTableModal}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { onclickTableModal(record, rowIndex) }, // click row
                        };
                    }}
                    rowSelection={rowSelectionModal}
                    columns={columnsModal} dataSource={dadosTabelaUsuariosModal}
                    scroll={{ y: 200 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltroTabelaModal.current = extra.currentDataSource
                        }
                    }
                    title={(registrosTabela) => {

                        return (
                            <div
                                style={{
                                    backgroundColor: propsState.theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registrosTabela.length} de ${refContagemDoFiltroTabelaModal.current.length === 0 ?
                                        dadosTabelaUsuariosModal.length :
                                        refContagemDoFiltroTabelaModal.current.length} num total de ${dadosTabelaUsuariosModal.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    size="small"
                    pagination={{ locale: { items_per_page: '' } }}
                    locale={locale.Table}
                />
            </Modal>
        </>
    );
}

export default ListagemContrato;
