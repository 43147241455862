import { useCallback, useEffect, useRef, useState } from "react";
import { addMonths, isAfter, isBefore, isEqual, subMonths, differenceInDays } from "date-fns";
import { Button, notification, Row, Tooltip, Typography } from "antd";

import { useHistory, useLocation } from "react-router-dom";
import { useGetColumnSearchProps } from "../../../../../../../hooks/FilterTable/stringCLearFiltros";
import { useGetColumnSearchPropsData } from "../../../../../../../hooks/FilterTable/date";
import moment from "moment";
import axios from "axios";
import { getToken } from "../../../../../../../services/token";
import { urlAcessosUsuario, urlDadosAcessosContratoUsuarioCidade, urlUsersStatusTodos } from "../../../../../../../services/request/urls";
import Icon from "@ant-design/icons";
import { FaPlus } from "react-icons/fa";
import { convertDataBrParaUS } from "../../../../../../../services/Conversores";

const IconMais = (props: any) => { return <Icon {...props} component={FaPlus} ></Icon> }

const { Text } = Typography

interface IReqListUsuarios {
    id: number,
    uuid: string,
    sistema: string,
    ativo: boolean,
    nome: string,
    celular: string,
    email: string,
    testador: boolean,
    dias_de_teste: string | number | null,
    vendedor: boolean,
    avatar: string | null,
    created_at: string,
    updated_at: string,
    cargo: { id: number, nome: string }
}

interface IData {
    "usuario": string,
    "data": string,
    "telefone": string | null,
    "cargo": string | null,
    "cargoId": number | null,
    "contrato": string | null,
    "contratoId": number | null,
    "marca": string | null,
    "modelo": string | null,
    "so": string | null,
    "versao": string | null,
    "acessos_modulos": Array<IAcessoModulos>,
    "totalAcessos": number | null,
}

interface IAcessoModulos {
    "cidadeNome": string | null,
    "uf"?: string | null,
    "idIbge": number | null,
    "dataEHora": string,
    "modulo": string | null,
    "subModulo": string | null,
}


interface IProps {
    setLoadingScreen: (val: boolean) => void
    activeKeyTabs: string
    refFiltersGlobal: any
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const useFiltrosAndTable = (
    {
        refFiltersGlobal,
        activeKeyTabs,
        setLoadingScreen
    }: IProps
) => {

    const history = useHistory();
    const queryParans = useQuery();

    const refFuncaoLimparFiltrosString = useRef<any>(null);

    const signal = axios.CancelToken.source();

    const [loadingTableModulos, setLoadingTableModulos] = useState(false);
    const [visibleModalModulosPorUsuario, setVisibleModalModulosPorUsuario] = useState(false);
    const [dadosTabelaModalModulosPorUsuario, setDadosTabelaModalModulosPorUsuario] = useState<IData>();

    const refFilterDataInicio = useRef<any>();
    const refFilterDataFim = useRef<any>();
    const [filterDataInicio, setFilterDataInicio] = useState<any>();
    const [filterDataFim, setFilterDataFim] = useState<any>();

    const refUsuarioId = useRef<number>();
    const [valueUsuarioId, setValueUsuarioId] = useState<number>();
    const [dataListUsuarios, setDataListUsuarios] = useState<Array<IReqListUsuarios>>([]);
    const refAllDadosUsuarios = useRef<Array<IReqListUsuarios>>([]);
    const [loadingSelectedUsuarios, setLoadingSelectedUsuarios] = useState(false);


    const refContagemDoFiltro = useRef<Array<any>>([]);
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<IData>>([]);

    useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
    }, []);

    //setando dados das cidades no selected
    const requestFiltroUltAcessTable = useCallback(() => {

        const requestAsync = async () => {
            try {

                if (refUsuarioId.current) {

                    setLoadingScreen(true);

                    let { data } = await axios.get(urlAcessosUsuario(refUsuarioId.current),
                        {
                            headers: { 'Authorization': 'Bearer ' + getToken() },
                            cancelToken: signal.token,
                            params: {
                                inicio: refFilterDataInicio.current ? convertDataBrParaUS(refFilterDataInicio.current) : undefined,
                                fim: refFilterDataFim.current ? convertDataBrParaUS(refFilterDataFim.current) : undefined,
                            }
                        }
                    );

                    setDadosTabelaState(data);

                    setLoadingScreen(false);
                }

            } catch (error) {
                setLoadingScreen(false);
                let msgErro: any = (error as Error);

                setLoadingScreen(false);
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });

            }
        }

        requestAsync();

    }, []);

    const buscaDadosUsuariosAndSetSelected = useCallback((): void => {

        const buscaDeDadosUsuarios = async () => {
            try {

                setLoadingSelectedUsuarios(true)
                let result = await axios.get(urlUsersStatusTodos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                refAllDadosUsuarios.current = result.data

                setDataListUsuarios(result.data);
                setLoadingSelectedUsuarios(false)


            } catch (error) {

                setLoadingSelectedUsuarios(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosUsuarios();

    }, []);


    useEffect(() => {


        const idUsuario = queryParans.get("idUsuario");

        //set filtros ao iniciar

        const dataInicio = subMonths(new Date(), 2)

        setFilterDataInicio(moment(dataInicio))
        refFilterDataInicio.current = dataInicio

        if (!refFilterDataFim.current) {
            // let dtInicio = moment(dataInicio).toDate();

            const dataFim = moment()

            setFilterDataFim(dataFim)
            refFilterDataFim.current = dataFim.toDate()

        }


        if (typeof idUsuario === 'string') {
            setValueUsuarioId(Number(idUsuario))
            refUsuarioId.current = Number(idUsuario)


            //with filtros e iniciar request
            requestFiltroUltAcessTable();
        }

        buscaDadosUsuariosAndSetSelected();


    }, []);


    const onChangeSelectDataInicio = useCallback((value: any): void => {

        if (value) {
            // validar se a data inicial não poder maior que a data final
            const resultado = isAfter(value!, refFilterDataFim.current!)
            const diferencaDias = differenceInDays(value!, refFilterDataFim.current!)

            if (resultado && diferencaDias !== 0) {
                notification.error({
                    message: 'Data inválida.',
                    description:
                        'A data inicial não pode ser posterior à data final.',
                });
                return
            }

            setFilterDataInicio(value)
            refFilterDataInicio.current = value

            if (!refFilterDataFim.current) {
                let dataInicio = moment(value).toDate();

                const dataFim = addMonths(dataInicio, 1)

                setFilterDataFim(moment(dataFim))
                refFilterDataFim.current = dataFim

            }

            requestFiltroUltAcessTable()


            return
        }

        setFilterDataFim(undefined)
        setFilterDataInicio(undefined)
        refFilterDataFim.current = undefined
        refFilterDataInicio.current = undefined

        requestFiltroUltAcessTable()

        return

    }, []);


    const onChangeSelectDataFim = useCallback((value: any): void => {

        if (value) {
            // validar se a data inicial não poder maior que a data final
            const resultado = isBefore(value!, refFilterDataInicio.current!)
            const diferencaDias = differenceInDays(value!, refFilterDataInicio.current!)

            if (resultado && diferencaDias !== 0) {
                notification.error({
                    message: 'Data inválida.',
                    description:
                        'A data final não pode ser anterior à data inicial.',
                });
                return
            }

            setFilterDataFim(value)
            refFilterDataFim.current = value

            if (!refFilterDataInicio.current) {
                let dataFim = moment(value).toDate();

                const dataInicio = subMonths(dataFim, 1)

                setFilterDataInicio(moment(dataInicio))
                refFilterDataInicio.current = dataInicio

            }

            requestFiltroUltAcessTable()

            return
        }

        setFilterDataFim(undefined)
        setFilterDataInicio(undefined)
        refFilterDataFim.current = undefined
        refFilterDataInicio.current = undefined

        requestFiltroUltAcessTable()


        return

    }, []);

    const handleCancelModalModulosPorUsuario = useCallback((): void => {
        setLoadingTableModulos(false)
        setVisibleModalModulosPorUsuario(false)
        setDadosTabelaModalModulosPorUsuario(undefined)
    }, []);

    const handleBtnModulosPorUsuario = useCallback((record: IData): void => {

        const requestAsync = async () => {
            try {

                setLoadingTableModulos(true)
                setVisibleModalModulosPorUsuario(true)


                setDadosTabelaModalModulosPorUsuario(record);
                setLoadingTableModulos(false)

                // refAllDadosContrato.current = result.data

            } catch (error) {

                handleCancelModalModulosPorUsuario()

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                    });
                }
            }
        }

        requestAsync();

    }, []);

    const onChangeSelectUsuarios = useCallback(async (value, obj) => {

        setValueUsuarioId(value)
        refUsuarioId.current = value

        requestFiltroUltAcessTable();

    }, []);

    const onRefreshPage = useCallback((): void => {
        requestFiltroUltAcessTable();
    }, []);


    const columns = [
        // {
        //     title: 'Município',
        //     dataIndex: 'cidadeNome',
        //     ...useGetColumnSearchProps('cidadeNome', refFuncaoLimparFiltrosString),
        //     width: '10%',
        //     sorter: (a: any, b: any) => {
        //         return a?.cidadeNome?.localeCompare(b?.cidadeNome)
        //     },
        //     showSorterTooltip: false,
        //     render: (nome: any, record: IData) => {

        //         return (
        //             <div
        //                 style={{
        //                     display: 'flex',
        //                     justifyContent: 'space-between',
        //                     alignItems: 'center'
        //                 }}
        //             >
        //                 {
        //                     record?.uf ?
        //                         nome + ' - ' + record?.uf
        //                         :
        //                         nome
        //                 }
        //             </div>
        //         )
        //     }
        // },
        {
            title: 'Usuário',
            dataIndex: 'usuario',
            ...useGetColumnSearchProps('usuario', refFuncaoLimparFiltrosString),
            width: '8%',
            sorter: (a: any, b: any) => {
                return a?.usuario?.localeCompare(b?.usuario)
            },
            showSorterTooltip: false,
            render: (usuario: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            usuario
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            ...useGetColumnSearchProps('cargo', refFuncaoLimparFiltrosString),
            width: '8%',
            sorter: (a: any, b: any) => {
                return a?.cargo?.localeCompare(b?.cargo)
            },
            showSorterTooltip: false,
            render: (cargo: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            cargo
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Data',
            dataIndex: 'data',
            ...useGetColumnSearchPropsData('data'),
            width: '3%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.data) {
                    aMonMen = moment(a.data).unix()
                } else {
                    aMonMen = 0
                }
                if (b.data) {
                    bMonMen = moment(b.data).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.data?.localeCompare(b.data),
            render: (data: any) => {

                let stringData: string = ''
                if (data) {
                    stringData = moment(data).format('DD/MM/YYYY').toString()
                }
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                stringData
                            }
                        </Text>
                    </div>
                )
            },
        },
        {
            title: 'Ações',
            dataIndex: '_id',
            // ...getColumnSearchProps('created_at'),
            width: '1%',
            className: 'rowAcoes_UltimoAcesso_lcbqweuiwegcv',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (id: any, record: IData) => (
                <>
                    <Row>
                        <Tooltip title="Módulos" color="blue">
                            <Button
                                type="primary"
                                onClick={
                                    () => handleBtnModulosPorUsuario(record)
                                }
                                className="btnIconMoreModal_hjebwgfvouab"
                            >
                                <IconMais />
                            </Button>
                        </Tooltip>
                    </Row>
                </>
            ),
        },
    ];

    const columnsModalModulosPorUsuario: any = [
        {
            title: 'Município',
            dataIndex: 'cidadeNome',
            ...useGetColumnSearchProps('cidadeNome', refFuncaoLimparFiltrosString),
            width: '10%',
            sorter: (a: any, b: any) => {
                return a.cidadeNome?.localeCompare(b.cidadeNome)
            },
            showSorterTooltip: false,
            render: (nome: any, record: IAcessoModulos) => {

                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            record?.uf ?
                                nome + ' - ' + record?.uf
                                :
                                nome
                        }
                    </div>
                )
            }
        },
        {
            title: 'Módulo',
            dataIndex: 'modulo',
            ...useGetColumnSearchProps('modulo', refFuncaoLimparFiltrosString),
            width: '8%',
            sorter: (a: any, b: any) => {
                return a.modulo?.localeCompare(b.modulo)
            },
            showSorterTooltip: false,
            render: (modulo: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            modulo
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Sub Módulo',
            dataIndex: 'subModulo',
            ...useGetColumnSearchProps('subModulo', refFuncaoLimparFiltrosString),
            width: '8%',
            sorter: (a: any, b: any) => {
                return a.subModulo?.localeCompare(b.subModulo)
            },
            showSorterTooltip: false,
            render: (subModulo: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            subModulo
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Data',
            dataIndex: 'dataEHora',
            ...useGetColumnSearchPropsData('dataEHora'),
            width: '6%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.dataEHora) {
                    aMonMen = moment(a.dataEHora).unix()
                } else {
                    aMonMen = 0
                }
                if (b.dataEHora) {
                    bMonMen = moment(b.dataEHora).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.dataEHora?.localeCompare(b.dataEHora),
            render: (data: any) => {

                let stringData: string = ''
                if (data) {
                    stringData = moment(data).format('DD/MM/YYYY HH:mm').toString()
                }
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                stringData
                            }
                        </Text>
                    </div>
                )
            },
        }
    ];

    return {
        onChangeSelectDataInicio,
        onChangeSelectDataFim,
        filterDataInicio,
        filterDataFim,
        dadosTabelaState,
        columns,
        refContagemDoFiltro,

        valueUsuarioId,
        dataListUsuarios,
        onChangeSelectUsuarios,

        visibleModalModulosPorUsuario,
        handleCancelModalModulosPorUsuario,
        loadingTableModulos,
        columnsModalModulosPorUsuario,
        dadosTabelaModalModulosPorUsuario,
        onRefreshPage,
        loadingSelectedUsuarios,
    }
}
